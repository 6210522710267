<template>
  <div class="page flex">
    <!-- 个人中心——财富特权 -->
    <div class="left_box">
      <div class="flex-aling left_boxTop">
        <div class="font-16 flex-content" @click="setWealthThatShow()">
          我的财富特权<img
            src="../../static/41.png"
            alt=""
            style="margin: 0 4px"
          />
        </div>
        <!-- <div
          class="font-12 PrivilegeSet_btn flex-content"
          @click="setPrivilegeShow()"
        >
          财富特权设置
        </div> -->
      </div>
      <!-- 特权徽章 -->
      <div class="flex-content badge_box">
        <img
          src="../../static/88.png"
          alt=""
          style="transform: rotate(180deg); margin-right: 67px"
          @click.stop="cutGradeChange(1)"
        />
        <div v-for="(item, index) in privilegeList" :key="index">
          <img
            :src="item.logo"
            alt=""
            class="badge_img"
            v-if="gradeIndex == index"
          />
        </div>

        <img
          src="../../static/88.png"
          alt=""
          style="margin-left: 67px"
          @click.stop="cutGradeChange(2)"
        />
      </div>
    </div>
    <div class="right_box">
      <div class="privilege_top flex-content" style="margin-top:35px">
        <img
          src="../../static/85.png"
          alt=""
          class="privilege_topIcon"
          style="width: 19px; height: 16px"
        />
        <p class="font-20 padding_r6" >
          {{ `财富特权  ${this.unLockNum}/${this.permissionList.length}` }}
        </p>
        <img
          src="../../static/86.png"
          alt=""
          class="privilege_topIcon"
          style="width: 19px; height: 16px"
        />
      </div>
      <!-- 特权内容 -->
      <div
        class="privilege_content flex flex_wrap"
        style="justify-content: space-between"
      >
        <div
          class="privilege_item font-14 flex-dir"
          v-for="(item, index) in permissionList"
          :key="index"
        >
          <img
            :src="item.logo"
            v-if="item.isUnLock == 1"
            alt=""
            style="width: 49px; height: 49px"
          />
          <img v-else src="../../static/89.png" alt="" />
          <p class="font_bold padding_4" style="color: #b8b8b8">
            {{ item.name }}
          </p>
          <p style="color: #8e8a98">{{ item.description }}</p>
        </div>
        <!-- 升级进度 -->
        <div style="position: absolute; bottom: 10%; right: 8%">
          <div
            class="flex-dir"
            v-if="myPrivilege.levelId == privilegeList.currLevelId"
          >
            <div class="font-12 flex">仅需消费1元宝，即可开启财富等级特权</div>
            <div class="flex-aling upgrade_box">
              <div class="flex-dir" v-if="privilegeList[0]">
                <img :src="privilegeList[0].logo" alt="" class="logo_icon" />
                <div>{{ privilegeList[0].name }}</div>
              </div>
              <el-progress
                :percentage="progressNum"
                status="warning"
                class="upgrade_progress"
                color="#f68229"
              ></el-progress>
              <div class="flex-dir" v-if="privilegeList[1]">
                <img :src="privilegeList[1].logo" alt="" class="logo_icon" />
                <div>{{ privilegeList[1].name }}</div>
              </div>
            </div>
          </div>
          <!-- 财富等级最大 -->
          <div class="flex-dir" v-else-if="gradeProgress.isLastLevel == 1">
            <div class="font-12 flex">
              财富值需：
              <p style="color: #77d769">10000w</p>
              身份永久有效
            </div>
            <div class="font-10 flex">
              快速升级：在房间内送礼物，发弹幕等消费
            </div>
          </div>
          <div class="flex-dir" v-else>
            <div class="font-12 flex">
              <p style="color: #77d769">
                {{ userInfo.limit }}/{{ userInfo.nextLevelLimit }}
              </p>
              , 还需要消费{{
                userInfo.nextLevelLimit - userInfo.limit
              }}元宝即可升级
            </div>
            <!-- 存在多个子等级 -->
            <div
              class="flex-aling upgrade_box"
              style="position: relative"
              v-if="gradeProgress.ishaveChildLevel == 1"
            >
              <div
                class="flex-dir"
                style="position: relative; top: 8px; left: 12px"
              >
                <img :src="gradeProgress.logo" alt="" class="logo_icon" />
                <div>{{ gradeProgress.name }}</div>
                <!-- <div>{{gradeProgress.limit}}</div> -->
              </div>
              <el-progress
                :percentage="progressNum"
                status="warning"
                class="upgrade_progress"
                color="#f68229"
              ></el-progress>
              <div class="list_Box">
                <div
                  class="flex-dir"
                  v-for="(voitem, key1) in gradeProgress.vos"
                  :key="key1"
                >
                  <img :src="voitem.logo" alt="" class="logo_icon" />
                  <div>{{ voitem.name }}</div>
                  <!-- <div>{{voitem.limit}}</div> -->
                </div>
              </div>
            </div>
            <div class="flex-aling upgrade_box" v-else>
              <div class="flex-dir">
                <img :src="gradeProgress.logo" alt="" class="logo_icon" />
                <div>{{ gradeProgress.name }}</div>
                <div>{{ gradeProgress.limit }}</div>
              </div>
              <el-progress
                :percentage="progressNum"
                status="warning"
                class="upgrade_progress"
                color="#f68229"
              ></el-progress>
              <div class="flex-dir">
                <img
                  :src="gradeProgress.nextLevelLogo"
                  alt=""
                  class="logo_icon"
                />
                <div>{{ gradeProgress.nextLevelName }}</div>
                <div>{{ gradeProgress.nextLevelLimit }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 权限设置弹窗 -->
    <div class="popup_box" v-if="privilegeSetShow">
      <div class="privilegeSetPopup">
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">财富特权</div>
          <img src="../../static/quit.png" alt="" @click="setPrivilegeShow()" />
        </div>
        <!-- 设置内容 -->
        <div class="set_box flex_wrap">
          <div class="set_item flex">
            <div>
              <p class="font-14" style="padding-bottom: 4px">进房间隐身</p>
              <p class="font-12" style="color: #8e92b1">进房不张扬</p>
            </div>
            <div class="font-12 set_jurisdiction" style="color: #77d769">
              国王及以上
            </div>
            <el-switch
              v-model="userInfo.enterRoomStealth"
              active-color="#9167fb"
              inactive-color="#cccccc"
              :active-value="1"
              :inactive-value="0"
              @change="pushSwitchChange"
            >
            </el-switch>
          </div>
          <div class="set_item flex">
            <div>
              <p class="font-14" style="padding-bottom: 4px">魅力隐藏</p>
              <p class="font-12" style="color: #8e92b1"> 我的魅力只想自我陶醉 </p>
            </div>
            <div class="font-12 set_jurisdiction" style="color: #77d769">
              伯爵及以上
            </div>
            <el-switch
              v-model="userInfo.charismaHide"
              active-color="#9167fb"
              inactive-color="#cccccc"
              :active-value="1"
              :inactive-value="0"
              @change="pushSwitchChange"
            >
            </el-switch>
          </div>
          <div class="set_item flex">
            <div>
              <div>
                <div class="font-14" style="padding-bottom: 4px">财富隐藏</div>
                <div class="font-12" style="color: #8e92b1">
                  做低调的土豪
                </div>
              </div>
              <div
                class="font-12 set_jurisdiction"
                style="color: #77d769; width: 200px"
              >
              国王及以上
              </div>
            </div>

            <div>
              <el-switch
                v-model="userInfo.wealthHide"
                active-color="#9167fb"
                inactive-color="#cccccc"
                :active-value="1"
                :inactive-value="0"
                @change="pushSwitchChange"
              >
              </el-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
    <wealthPopover
      v-if="wealthThatShow"
      @setWealthShow="wealthThatShow = false"
    ></wealthPopover>
  </div>
</template>
<script>
import wealthPopover from "./wealthPopover.vue"; //财富说明
export default {
  name: "",
  components: { wealthPopover },
  data() {
    return {
      privilegeSetShow: false,
      wealthThatShow: false,
      permissionList: [], //权限列表
      privilegeList: [], //财富等级
      myPrivilege: {}, //我的等级
      numPercent: 0, //百分比
      gradeIndex: 0, //默认第一个等级
      gradeProgress: {}, //财富等级进度
      progressNum: 0, //进度条数值
      unLockNum: 0, //已解锁特权数量
      userInfo: {
        allStealth:0,
        charismaHide:0,
        wealthHide:0,
        enterRoomStealth:0
      }, //用户信息
    };
  },
  mounted() {
    this.getWealthLevelList();
    this.getWealthLevelInfo();
    
  },
  methods: {
    // 个人信息
    getInfo() {
      this.$api.queryUser({ otherUserId: this.userId }).then((res) => {
        if (res.code == 1) {
          this.userInfo = res.data;
          
          if (this.gradeProgress.ishaveChildLevel == 1) {
            console.log(this.userInfo,'this.progressNumthis.progressNumthis.progressNum')
            this.progressNum =
              (this.userInfo.limit /
                this.gradeProgress.vos[this.gradeProgress.vos.length - 1]
                  .limit) *
              100;
          //     console.log(this.progressNum,'this.progressNumthis.progressNumthis.progressNum')
          }
        }
      });
    },
    pushSwitchChange(){
      // allStealth
      // charismaHide
      // wealthHide
      // enterRoomStealth
      let data={
        allStealth:0,
        charismaHide:0,
        wealthHide:0,
        enterRoomStealth:0
      }
      this.$api.setWealthPrivilege(data).then(res=>{
        console.log(res,'aaaaa')
      })
    },
    // 等级切换 type 1上一页 2下一页
    cutGradeChange(type) {
      if (type == 1 && this.gradeIndex != 0) {
        this.gradeIndex--;
      } else if (type == 2 && this.gradeIndex < this.privilegeList.length - 1) {
        this.gradeIndex++;
      }
      this.getWealthPrivilege(this.privilegeList[this.gradeIndex].id);
    },
    // 财富等级进度
    getWealthLevelProgress(id) {
      this.$api.wealthLevelProgress({ wealthLevelId: id }).then((res) => {
        if (res.code == 1) {
          this.gradeProgress = res.data;
        }
      });
    },
    // 获取财富等级说明
    getWealthPrivilege(id) {
      //wealthLevelId 财富等级id
      this.$api.wealthPrivilege({ wealthLevelId: id }).then((res) => {
        // console.log('财富等级说明',res);
        if (res.code == 1) {
          this.permissionList = res.data.privilegeVoList;
          this.unLockNum = res.data.unLockNum;
        }
      });
    },
    // 获取财富特权等级 列表
    getWealthLevelList() {
      this.$api.wealthLevelList({ pageNum: 1, pageSize: 999 }).then((res) => {
        // console.log('财富特权等级',res);
        if (res.code == 1) {
          this.privilegeList = res.data.rows;
          console.log(res.data.rows,'rowsrowsrowsrows')
          
          this.getWealthLevelProgress(this.myPrivilege.levelId);
          this.getInfo();
        }
      });
    },
    // 获取财富等级信息
    getWealthLevelInfo() {
      this.$api.getWealthLevelInfo().then((res) => {
        if (res.code == 1) {
          this.myPrivilege = res.data;
          this.numPercent = this.toPercent(
            this.myPrivilege.totalDaimond,
            this.myPrivilege.nextLevelLimit
          );
          this.getWealthPrivilege(this.myPrivilege.levelId);
          this.privilegeList.forEach((item, index) => {
            if (this.myPrivilege.levelId == item.id) {
              this.gradeIndex = index;
            }
          });
        }
      });
    },
    setPrivilegeShow() {
      this.privilegeSetShow = !this.privilegeSetShow;
    },
    setWealthThatShow() {
      this.wealthThatShow = !this.wealthThatShow;
    },
    // 计算百分比
    toPercent(num, total) {
      return Math.round((num / total) * 10000) / 100.0; // 小数点后两位百分比
    },
  },
};
</script>
<style scoped lang='scss'>
.list_Box {
  position: absolute;
  left: 24%;
  top: 8px;
  display: flex;
  justify-content: space-between;
  width: 235px;
}
.page {
  width: 100%;
  height: 100%;
  padding: 20px;
  background: url(../../static/82.png) no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  margin-top:0px ;
  .left_box {
    width: 55%;
    height: 100%;
    position: relative;
    background: none;
    .left_boxTop {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .PrivilegeSet_btn {
      width: 104px;
      height: 28px;
      background: rgba($color: #fff, $alpha: 0.4);
      border-radius: 14px;
    }
    .badge_box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .badge_img {
      width: 236px;
      height: 236px;
    }
  }
  .right_box {
    width: 45%;
    height: 100%;
    .privilege_content {
      padding: 40px;
      .privilege_item {
        width: 33%;
        margin-bottom: 30px;
      }
    }
  }
  .upgrade_box {
    width: 100%;
    font-size: 12px;
    .upgrade_progress {
      width: 248px;
      margin: 0 16px 0 8px;
    }
    .logo_icon {
      width: 28px;
      height: 28px;
    }
  }
  .upgrade_progress /deep/.el-progress-bar {
    padding-right: 0px;
  }
  .upgrade_progress /deep/.el-icon-warning {
    display: none;
  }
  .upgrade_progress /deep/.el-progress-bar__outer {
    background: #6c6b6f;
  }
  //权限设置弹窗
  .popup_box {
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    top: 0;
    left: 0;
    .privilegeSetPopup {
      width: 756px;
      height: 524px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fff;
      border-radius: 4px 4px 4px 4px;
    }
    .set_box {
      padding: 23px 0 0 30px;
      .set_item {
        width: 344px;
        height: 61px;
        padding: 10px;
        align-items: center;
        margin: 0 10px 10px 0;
        background: #f5f5f5;
        border-radius: 4px;
        box-sizing: border-box;
        position: relative;
        color: #000000;
        justify-content: space-between;
      }
      .set_jurisdiction {
        position: absolute;
        top: 14px;
        left: 90px;
      }
      .set_icon {
        width: 36px;
        height: 20px;
        position: absolute;
        right: 10px;
      }
    }
  }
  //特权说明弹窗
}
//表格
.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background: #262946;
}
</style>