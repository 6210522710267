<template>
  <div>
    <!-- 个人中心--我的礼物 -->
    <div class="top_box">
      <!-- <div class="top_left"> -->
      <div class="top_item">
        <div class="flex-aling">
          财富值<img src="../../static/61.png" alt="" />
        </div>
        <div class="font-28 padding_6">{{ userInfo.totalDiamond }}</div>
      </div>
      <div class="top_item">
        <div class="flex-aling">
          魅力值<img src="../../static/93.png" alt="" />
        </div>
        <div class="font-28 padding_6">{{ userInfo.totalCharisma }}</div>
      </div>
      <div class="top_item">
        <div class="flex-aling">
          累计获得礼物数<img src="../../static/94.png" alt="" />
        </div>
        <div class="font-28 padding_6" v-if="giftNumData">
          {{ giftNumData.receiveNum }}
        </div>
      </div>
      <div class="top_item">
        <div class="flex-aling">
          已兑换<img src="../../static/95.png" alt="" />
        </div>
        <div class="font-28 padding_6" v-if="giftNumData">
          {{ giftNumData.exchangeNum }}
        </div>
      </div>
      <div class="top_item">
        <div class="flex-aling">累计获得礼物价值<img src="../../static/61.png" alt="" /> </div>
        <div class="font-28 padding_6" v-if="giftNumData">
          {{ giftNumData.totalDiamonds
          }}
        </div>
      </div>
    </div>
    <!-- 钱包列表 -->
    <div class="bottom_box">
      <div class="flex-aling" style="justify-content: space-between">
        <div class="flex">
          <div
            class="tab_item"
            :class="tabType == 1 ? 'tab_pitch' : ''"
            @click="tabChange(1)"
          >
            收到的礼物
          </div>
          <div
            class="tab_item"
            :class="tabType == 2 ? 'tab_pitch' : ''"
            @click="tabChange(2)"
          >
            送出的礼物
          </div>
        </div>
        <div class="flex-content">
          <div class="screen_btn flex-content" @click="setConversionShow()">
            礼物兑换
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <div class="tables" v-if="tabType == 1">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="createTime" label="时间" width="180">
          </el-table-column>
          <el-table-column prop="orderNumber" label="收到礼物" width="180">
            <template slot-scope="scope">
              <div class="flex">
                <img
                  :src="scope.row.giftLogo"
                  alt=""
                  style="width: 20px; height: 20px"
                />
                <p>{{ scope.row.giftName }}</p>
                <p style="color: #f6832b">x{{ scope.row.giftNum }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="送礼人">
            <template slot-scope="scope">
              <div class="flex">
                <img
                  :src="scope.row.fromUserAvatar"
                  alt=""
                  class="presentImg"
                />
                <p>{{ scope.row.fromUserNickName }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="diamondVal" label="元宝价值">
          </el-table-column>
          <el-table-column prop="charmVal" label="魅力值"> </el-table-column>
        </el-table>
      </div>
      <div class="tables" v-else>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="createTime" label="时间" width="180">
          </el-table-column>
          <el-table-column prop="orderNumber" label="收到礼物" width="180">
            <template slot-scope="scope">
              <div class="flex">
                <img
                  :src="scope.row.giftLogo"
                  alt=""
                  style="width: 20px; height: 20px"
                />
                <p>{{ scope.row.giftName }}</p>
                <p style="color: #f6832b">x{{ scope.row.giftNum }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="收礼人">
            <template slot-scope="scope">
              <div class="flex">
                <img :src="scope.row.toUserAvatar" alt="" class="presentImg" />
                <p>{{ scope.row.toUserNickName }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="diamondVal" label="元宝价值">
          </el-table-column>
          <el-table-column prop="charmVal" label="魅力值" v-if="tabType == 1"> </el-table-column>
          <el-table-column prop="charmVal" label="财富值" v-else> </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="this.listTotal"
        class="paging"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    <!-- 兑换元宝弹窗 -->
    <div class="popup_box" v-if="conversionShow">
      <div class="conversion">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">兑换龙币</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="setConversionShow()"
          />
        </div>
        <!-- 头部end -->
        <div class="conversion_cont">
          <div class="font-14" style="color:#333">
            可兑换礼物总礼物值：{{ giftConversion.totalGiftValue }}
          </div>
          <div class="font-12 padding_10" style="color: #8e92b1">
            {{ `可兑换龙币=可兑换礼物的总魅力值${giftConversion.discount}%` }}
          </div>
          <div class="font-14 padding_10" style="padding-top: 14px;color:#333">
            可兑换龙币
          </div>
          <div class="gold_box flex-content">
            <div class="gold_text">
              {{ giftConversion.goldCoins
              }}<img src="../../static/81.png" alt="" />
            </div>
          </div>
          <div class="conversion_btn flex-content" @click="setConversionGold()">
            兑换龙币
          </div>
        </div>
      </div>
    </div>
    <div class="popup_box" v-if="remindShow">
      <div class="remind_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">提醒</div>
          <img src="../../static/quit.png" alt="" @click="setRemindShow()" />
        </div>
        <!-- 头部end -->
        <div class="remind_cont flex-dir">
          <div class="font-14">是否确认兑换龙币</div>
          <div class="padding_10 font-12" style="color: #8e92b1">
            一经兑换 操作不可逆
          </div>
          <div class="gold_text">
            4145 <img src="../../static/61.png" alt="" />
          </div>
          <div class="flex" style="margin-top: 30px">
            <div class="cancel flex-content">我再想想</div>
            <div class="affirm flex-content">立即兑换</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "",
  components: {},
  data() {
    return {
      conversionShow: false,
      remindShow: false,
      value1: "",
      tabType: 1,
      tableData: [], //列表
      pageObj: {
        pageNum: 1,
        pageSize: 8,
        date: "", //时间
        type: 1, //	类型 1 收到礼物 2 送出礼物
      },
      listTotal: "", //列表总数
      giftNumData: {}, //收到、兑换礼物数
      giftConversion: {
        allCharisma: 0, //总魅力值
        discount: 0, //兑换比例
        goldCoins: 0, //可兑换龙币
      }, //魅力值兑换礼物
      userInfo: {},
    };
  },
  mounted() {
    this.giftInfo();
    this.getUserInfo();
    this.giftGiveList();
  },
  methods: {
    // 个人信息
    getUserInfo() {
      this.$api.queryUser({ otherUserId: this.userId }).then((res) => {
        if (res.code == 1) {
          this.userInfo = res.data;
          // console.log("个人信息", this.userInfo);
        }
      });
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageObj.pageNum = val;
      this.giftGiveList();
    },
    // 收到、兑换礼物数
    giftInfo() {
      this.$api.giftInfo().then((res) => {
        if (res.code == 1) {
          this.giftNumData = res.data;
        }
      });
    },
    // tab切换
    tabChange(type) {
      this.tabType = type;
      this.pageObj.type = type;
      this.pageObj.pageNum = 1;
      this.giftGiveList();
    },
    // 礼物列表
    giftGiveList() {
      this.$api.giftGiveList(this.pageObj).then((res) => {
        if (res.code == 1) {
          this.tableData = res.data.rows;
          this.listTotal = res.data.total;
        }
      });
    },
    // 兑换龙币
    setConversionGold() {
      if(this.giftConversion.goldCoins == 0) return this.$message.info('可兑换魅力值不足')
      this.$api
        .immediatelyChange({ golds: this.giftConversion.goldCoins })
        .then((res) => {
          if (res.code == 1) {
            this.conversionShow = false;
            this.setConversionShow(); //刷新魅力值
            this.$message.success(res.msg);
          } else {
            this.$message.info(res.msg);
          }
        });
    },
    // 显示礼物兑换元宝接口
    setConversionShow() {
      this.conversionShow = !this.conversionShow;
      this.$api.exchangeGoldCoins().then((res) => {
        if (res.code == 1) {
          this.giftConversion = res.data;
        }
      });
    },
    // 提醒弹窗
    setRemindShow() {
      this.remindShow = !this.remindShow;
    },
  },
};
</script>
  <style  lang='scss' scoped>
.presentImg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 4px;
}
.top_box {
  display: flex;
  justify-content: space-between;
  color: #363659;
  font-size: 14px;

  .top_item {
    width: 182px;
    height: 96px;
    padding: 16px;
    background: #ffffff;
    border-radius: 8px;
    box-sizing: border-box;
  }
}
.bottom_box {
  width: 950px;
  height: 550px;
  padding: 10px 16px;
  margin-top: 10px;
  color: #000;
  font-size: 14px;
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  .screen_btn {
    width: 80px;
    height: 28px;
    color: #333;
    background: #ffdd00;
    border-radius: 4px;
  }
  .paging {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }
  .screen_btn1 {
    margin-left: 20px;
  }
}
.tab_item {
  color: #000000;
  font-size: 14px;
  margin-right: 40px;
  padding-bottom: 13px;
}
.tab_pitch {
  color: #0052c7;
  border-bottom: 2px solid #0052c7;
}
.tables {
  padding-top: 16px;
  border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
}
//兑换弹窗样式
.conversion {
  width: 440px;
  height: 430px;
  background: #fff;
  color: #000;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.conversion_cont {
  color: #d3d4dc;
  padding: 20px 60px;
}
.popup_box {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}
.gold_box {
  width: 320px;
  height: 69px;
  background: rgba(255, 167, 0, 0.10);
  border-radius: 8px;
}
.gold_text {
  font-size: 24px;
  font-weight: bold;
  color: #ffdba3;
  display: flex;
  align-items: flex-end;
  > img {
    width: 16px;
    height: 16px;
    margin: 4px;
  }
}
.conversion_btn {
  width: 320px;
  height: 40px;
  position: absolute;
  bottom: 30px;
  background: rgba(255, 221, 0, 1);
  border-radius: 4px;
  color:#333
}
//提醒弹窗样式
.remind_box {
  width: 492px;
  height: 293px;
  background: #1d223b;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .remind_cont {
    padding: 40px 60px 0 60px;
  }
}
.cancel {
  width: 176px;
  height: 40px;
  background: #363659;
  border-radius: 4px;
  margin-right: 20px;
}
.affirm {
  width: 176px;
  height: 40px;
  background: linear-gradient(135deg, #7c4ff0 0%, #976eff 100%);
  border-radius: 4px;
}
</style>