<template>
  <!-- 财富说明弹窗 -->
  <div class="popup_box">
    <div class="wealthThat">
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14" >财富特权说明</div>
        <img src="../../static/quit.png" alt="" @click="setWealthThatShow()" />
      </div>
      <!-- 特权内容 -->
      <div class="privilege_explain">
        <div class="flex-content font-16" style="color:#333"> 财富等级说明</div>
        <div class="privilege_title" style="color:#333">一、什么是财富等级？</div>
        <div class="font-14" style="color: #8f8f8e">
          财富等级是用户在平台中财富的象征，不同等级对应不同称号；等级越高，获得的专属特权越多！
        </div>
        <div class="privilege_title" style="color:#333">二、财富等级如何升级？</div>
        <div class="font-14" style="color: #8f8f8e">
          在聊天房中送礼物（包括背包礼物）、发喇叭、发弹幕均可获得财富值（消费1元宝=1财富值）。累计财富值达到下一等级对应的财富值要求，即可升级，财富等级身份获得后永久有效！
        </div>
        <div class="privilege_title" style="color:#333">三、财富等级表</div>
        <!-- 表格 -->
        <div class="tableBox">
          <div class="table_hd">
            <div class="table_item" style="width: 300px">等级标识</div>
            <div class="table_item">等级名称</div>
            <div class="table_item" style="width: 265px">积累财富值</div>
          </div>
          <div class="table_tr" v-for="(item,index) in wealList" :key="index">
            <div class="table_tb" style="width: 290px">
              <img :src="item.logoPath" alt="" style="width:35px;height: 35px;" />
            </div>
            <div class="flex_wrap" style="width:300px;">
              <div class="flex-dir" style="width:470px;"  v-for="(opn,key0) in item.leveList" :key="key0">
                <div class="flex" style="height: 40px;" >
                  <div class="table_tb" style="width:193px">{{opn.name}}</div>
                  <div class="table_tb" style="width: 256px">{{opn.limit}}</div>
                </div>
              </div>
            </div>
          </div>  
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      wealList:[],//财富特权列表
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getWealDataList()
  },
  methods: {
    setWealthThatShow() {
      this.$emit("setWealthShow");
    },
    // 财富等级列表
    getWealDataList(){
      this.$api.getWealData().then(res=>{
        if(res.code == 1){
          this.wealList = res.data
        }
      })
    },
  },
};
</script> 

<style scoped lang="scss">
.tableBox {
  color: #333;
  font-size: 14px;
  border-top: 1px solid rgba($color: #fff, $alpha: 0.1);
  border-left: 1px solid rgba($color: #fff, $alpha: 0.1);
  .table_hd {
    display: flex;
    background-color: rgba(255, 221, 0, 1);
    .table_item {
      width: 200px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba($color: #fff, $alpha: 0.1);
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
    }
  }
  .table_tr {
    display: flex;
    .table_tb {
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid rgba($color: #fff, $alpha: 0.1);
      border-bottom: 1px solid rgba($color: #fff, $alpha: 0.1);
    }
  }
}
.privilege_explain {
  height: 550px;
  padding: 20px 28px 0 25px;
  overflow-y: scroll;
  .privilege_title {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px 0;
  }
}
.wealthThat {
  width: 800px;
  height: 650px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px 4px 4px 4px;
}
.popup_box {
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.8);
  position: fixed;
  top: 0;
  left: 0;
  .privilegeSetPopup {
    width: 756px;
    height: 524px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #1d223b;
    border-radius: 4px 4px 4px 4px;
  }
  .set_box {
    padding: 23px 0 0 30px;
    .set_item {
      width: 344px;
      height: 61px;
      padding: 10px;
      align-items: center;
      margin: 0 10px 10px 0;
      background: #363659;
      border-radius: 4px;
      box-sizing: border-box;
      position: relative;
    }
    .set_jurisdiction {
      position: absolute;
      top: 14px;
      left: 90px;
    }
    .set_icon {
      width: 36px;
      height: 20px;
      position: absolute;
      right: 10px;
    }
  }
}
</style>