<template>
  <div id="page"  >
    <!-- 头部 -->
    <div class="title">
      <div
        class="logo flex-aling flex"
        style="padding-left: 26px; =box-sizing: border-box"
      >
        <img
        
          src="../../static/logo1.png"
          alt=""
          style="border-radius: 3px;width:50px;hegith:50px"
        />
        <div style="font-size:16px;margin-left:4px;font-weight:bold">狗书语音</div>
        <!-- <img
          src="../../static/logoText.png"
          alt=""
          style="width: 86px; height: 20px"
        /> -->
      </div>
      <div class="tab">
        <div
          @click.stop="pageSwitching(1)"
          :class="tabIndex == 1 ? 'pitchOn' : ''"
          class="home_tabItem"
        >
          首页
        </div>
        <div
          @click.stop="pageSwitching(2)"
          :class="tabIndex == 2 ? 'pitchOn' : ''"
          class="home_tabItem"
        >
          折扣专区
        </div>
        <div
          @click.stop="pageSwitching(3)"
          :class="tabIndex == 3 ? 'pitchOn' : ''"
          class="home_tabItem"
        >
          聊天室
        </div>
        <div
          @click.stop="pageSwitching(4)"
          :class="tabIndex == 4 ? 'pitchOn' : ''"
          class="home_tabItem"
        >
          广场
        </div>
      </div>
      <!-- 搜索款 -->
      <!-- <input type="text"  value="昵称/ID/房间"  name="input" id="" class="search"> -->
      <el-input
        v-model="keyword"
        placeholder="昵称/ID/房间"
        class="search"
        @change="searchChange()"
        prefix-icon="el-icon-search"
      >
      </el-input>
      <!-- <div class="search_btn" @click.stop="searchChange()">搜索</div> -->
      <!-- 广告 -->
      <div class="advertising" @click.stop="showChange('first')" v-if="userData.isCharge==0">
        <img src="../../static/1.png" alt="" />
      </div>
      <!-- 用户信息 -->
      <div
        class="user flex-aling"
        v-if="!userData.id"
        style="margin-right: 50px"
        @click.stop="loginPage()"
      >
        <img src="../../static/174.png" alt="" class="headImg" />
        <div class="font-14" v-if="loading">未登录</div>
      </div>
      <div
        class="user flex-content "
        @click.stop="
          showChange('personal');
          moreType = false;
        "
        v-else
      >
        <img
          :src="userData.avatar"
          alt=""
          class="headImg"
          v-if="userData.avatar"
        />
        <img src="../../static/174.png" alt="" class="headImg" v-else />
        <div>
          <div class="flex" style="align-items: center">
            <div class="name">{{ userData.nickname }}</div>
            <div class="flex">
              <img
                :src="userData.logo"
                v-if="userData.logo"
                alt=""
                style="width: 28px; height: 28px"
              />
            </div>
          </div>
          <div
            class="flex"
            style="justify-content: flex-start; align-items: center"
          >
            <img
              src="../../static/user3.png"
              alt=""
              style="width: 14px; height: 14px"
            />
            <div>{{ userData.diamondCost }}</div>
            <!-- diamondCost  myq -->
            <div class="tuichu" @click.stop="quitLoginShow = true">退出</div>
          </div>
        </div>
      </div>
      <!-- 帮助 myq-->
      <div class="help flex" @click.stop="showChange('helpindex')">
        <img src="../../static/2.png" alt="" class="iconTit" />
        <span>帮助中心</span>
      </div>
      <!-- 下载 myq-->
      <div
        class="download flex"
        @click="downloadAppShow = !downloadAppShow"
      >
        <img src="../../static/3.png" alt="" class="iconTit" />
        下载APP
      </div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <!-- 更多弹窗 -->
      <div class="morePopup" v-if="moreType" @mouseleave="moreType = false">
        <!-- 热门 -->
        <div class="mpreTitle">热门</div>
        <div class="mpre_box">
          <div
            class="more_item"
            v-for="item in allLabelLists.hotLabels"
            :key="item.id"
            @click.stop="getInfo(item.id)"
          >
            <img :src="item.logo" alt="" class="more_img" />
            <div>{{ item.name }}</div>
          </div>
        </div>
        <!-- 游戏 -->
        <div class="mpreTitle">游戏</div>
        <div class="mpre_box">
          <div
            class="more_item"
            v-for="item in allLabelLists.gameLabels"
            :key="item.id"
            @click.stop="getInfo(item.id)"
          >
            <img :src="item.logo" alt="" class="more_img" />
            <div>{{ item.name }}</div>
          </div>
        </div>
        <!-- 娱乐 -->
        <div class="mpreTitle">娱乐</div>
        <div class="mpre_box">
          <div
            class="more_item"
            v-for="item in allLabelLists.amusementLabels"
            :key="item.id"
            @click.stop="getInfo(item.id)"
          >
            <img :src="item.logo" alt="" class="more_img" />
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
      <!-- 广场 -->
      <div v-if="homeType == 4"></div>
      <!-- 房间标签 -->
      <div class="left" v-if="homeType == 3">
        <div v-if="0">
          <img
            style="width: 20px; height: 20px"
            src="../../static/10.png"
            alt=""
          />
          <div>收藏</div>
        </div>
        <div
          class="left-item"
          @click.stop="getRoomList('hot', 0)"
          :class="leftPitch == 0 ? 'left-item-pitch' : ''"
        >
          <img src="../../static/4.png" alt="" class="leftImg" />
          <div>热门聊天室</div>
        </div>
        <div
          v-for="(item, index) in hotLabelLists"
          :key="item.id"
          @click.stop="getRoomList(item.id, item.id,item.name)"
          :class="leftPitch == item.id ? 'left-item-pitch' : ''"
        >
          <div class="left-item" v-if="index < 7">
            <img :src="item.images" alt="" class="leftImg" />
            <div v-if="item.name">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="left1" v-if="homeType == 7">
        <div
          class="left-item"
          @click.stop="getRoomList('hot', 7)"
          :class="leftPitch == 7 ? 'left-item-pitch' : ''"
        >
          <img style="margin:0 12px" src="../../static/other13.png" alt="" class="leftImg" />
          <div style="text-align: center;">全部</div>
        </div>
        <div class="left-item" style="margin:0 auto" :class="leftPitch == 8 ? 'left-item-pitch' : ''"  @click.stop="getRoomList('', 8)">
          <img style="margin:0 12px" src="../../static/other14.png" alt="" class="leftImg" />
            <div style="text-align: center;"  >聊天室</div>
        </div>
        <div  class="left-item" :class="leftPitch == 9 ? 'left-item-pitch' : ''"  @click.stop="getRoomList('', 9)">
          <img style="margin:0 12px" src="../../static/other15.png" alt="" class="leftImg" />
            <div style="text-align: center;">用户</div>
        </div>
        <!-- <div
          v-for="(item, index) in hotLabelLists"
          :key="item.id"
          @click.stop="getRoomList(item.id, item.id)"
          :class="leftPitch == item.id ? 'left-item-pitch' : ''"
        >
          <div class="left-item" v-if="index < 7">
            <img :src="item.logo" alt="" class="leftImg" />
            <div v-if="item.name">{{ item.name }}</div>
          </div>
        </div> -->
      </div>
      <!-- 大神标签 -->
      <div class="left" v-if="homeType == 1">
        <div
          class="left-item"
          @click.stop="leftPitchChange(0)"
          style="margin-top: 10px"
          :class="leftPitch == 0 ? 'left-item-pitch' : ''"
        >
          <img src="../../static/other12.png" alt="" class="leftImg" />
          <div>推荐大神</div>
        </div>
        <div
          v-for="(item, index) in hotLabelLists"
          :key="item.id"
          @click.stop="leftPitchChange(item.id)"
          :class="leftPitch == item.id ? 'left-item-pitch' : ''"
        >
          <div class="left-item" v-if="index < 6">
            <img :src="item.logo" alt="" class="leftImg" />
            <div v-if="item.name">{{ item.name }}</div>
          </div>
        </div>

        <div
          class="left-item"
          @click.stop="moreTopupShow()"
          :class="leftPitch == 7 ? 'left-item-pitch' : ''"
        >
          <img src="../../static/other13.png" alt="" class="leftImg" />
          <div>更多</div>
        </div>
      </div>
      <div class="left2" v-if="homeType == 2">
        <div
          v-for="(item, index) in DiscountConfigList"
          :key="index"
          @click.stop="leftPitchChange(index)"
          :class="leftPitch ==index ? 'left-item-pitch' : ''"
        >
          <div class="left-item" v-if="index < 6">
            <img v-if="item.configName=='onePrice'" :src="DiscountConfigList[2].configValue" alt="" class="leftImg" />
            <img v-if="item.configName=='buyGive'" src="../../static/227.png" alt="" class="leftImg" />
          </div>
        </div>
      </div>
      <div class="center_box">
        <div class="conter_title" v-if="homeType == 1">
          推荐大神
          <p></p>
        </div>
        <div class="conter_title" v-if="homeType == 7">
          搜索结果
          <p></p>
        </div>
        <!-- <div
          v-if="homeType == 0"
          class="searchReturn"
          @click.stop="pageSwitching(1)"
        >
          <img src="../../image/my/27.png" alt="" style="width: 28px" />
          <div style="color:#333">返回首页</div>
        </div> -->
        <div class="conter_title" v-if="homeType == 3">
          {{nameText }}
          <p></p>
        </div>
        <div class="conter_title" v-if="homeType == 4">
          <guangchang v-if="userData" :userInfo="userData"></guangchang>
        </div>
        <div class="conter_title" v-if="homeType == 1">
          <!-- 智能筛选 -->
          <div class="flex" v-if="leftPitch != 0">
            <el-select
              v-model="smartValue"
              placeholder="智能筛选"
              class="filter_select"
              @change="smartChange()"
            >
              <el-option
                v-for="item in smartOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- 性别 -->
            <el-select
              v-model="sexValue"
              placeholder="性别"
              class="filter_select"
              @change="sexChange()"
            >
              <el-option
                v-for="item in sexOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>

            <el-select
              v-model="isSameCityValue"
              placeholder="同城"
              class="filter_select"
              @change="isSameCityChange()"
            >
              <el-option
                v-for="item in isSameCityOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>

        <!-- 首页内容start -->
        <div
          class="recommend_box flex"
          style="margin-top: 20px;height:550px;"
          @scroll="userHandleScroll($event)"
          v-if="homeType == 1"
        >
          <!-- 推荐内容 -->
          <template>
            <div
              v-if="manitoList.length == 0"
              class="flex-content"
              style="width: 100%; flex-direction: column"
            >
              <img src="../../image/room/wu.png" alt="" style="width: 180px" />
              <p style="color: #fff; font-size: 18px; margin-top: 12px">
                暂无数据
              </p>
            </div>
            <div
              v-else
              class="recommend_item"
              v-for="(item, index) in manitoList"
              :key="index + 'a'"
              :style="{ marginRight: item % 5 == 0 ? '0' : '14px' }"
              @click.stop="showChange('others', item.id)"
            >
              <div class="item_topBox" @click.stop="userRommSkip(item)">
                <img :src="item.avatar" alt="" class="item_top" />
              </div>
              <!-- 左上 标签start -->
              <div class="topLabel flex-content" v-if="item == 1">买3送2</div>
              <img
                src="../../static/24.png"
                alt=""
                class="topLabel2"
                v-if="item == 6 || item == 4"
              />
              <!-- 左上 标签end -->
              <!-- 是否聊天start -->
              <!-- 0-离线 1-在线 2-房间中 -->

              <div class="onLine flex-content" v-if="item.onlineStatus == 0">
                <div
                  class="onLineIcon"
                  style="background: red; margin-right: 4px"
                ></div>
                离线
              </div>
              <div class="onLine flex-content" v-if="item.onlineStatus == 1">
                <div class="onLineIcon" style="margin-right: 4px"></div>
                在线
              </div>
              <div class="chat flex-content" v-if="item.onlineStatus == 2">
                <img src="../../static/23.png" alt="" class="chatImg" />
                聊天中
              </div>
              <!-- 是否聊天end -->
              <!-- 聊天时间 -->
              <div
                class="chatOfTime"
                v-if="item.voiceFile"
                @click.stop="playAudio(item.voiceFile)"
              >
                <img src="../../static/22.png" alt="" class="chatOfTimeImg" />
                <p>{{ item.voiceLen ? item.voiceLen : 0 }}“</p>
              </div>
              <img
                src="../../image/room.gif"
                class="roomGif"
                alt=""
                v-if="item.onlineStatus == 2"
              />
              <!-- 聊天时间 -->
              <div class="item_text">
                <div class="item_name">{{ item.nickname }}</div>
                <div class="padding_4">
                  {{ item.skillName }} <span v-if="item.voiceName"> | </span>
                  {{ item.voiceName }}
                </div>
                <div class="flex-aling bottm_style1">
                  <div>
                    <b class="moneyType">{{ item.price }}</b
                    >{{ item.unit }}
                  </div>
                  <div class="flex-aling">
                    <img
                      src="../../static/21.png"
                      alt=""
                      class="address_icon"
                    />
                    {{ item.city }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- 首页内容end -->
        <!-- 折扣大神列表 --> 
        <div class="recommend_box" style="height: 650px;" v-if="homeType == 2">
          <div
            class="conter_title"
            v-if="homeType == 2 && leftPitch==0"
          >
            800元宝专区
            <img
              src="@/static/41.png"
              alt=""
              @click.stop="activityPopUp_box = true"
            />
            <p></p>
          </div>

          <!-- @scroll="userHandleScroll($event)" -->
          <!-- 800 推荐内容 -->
          <template v-if="homeType == 2 && leftPitch==0">
          
            <div style="display:flex" v-if="discontList.length>0">

            
            <div
              class="recommend_item"
              style="margin-top: 24px; margin-left: 12px"
              v-for="(item, index) in discontList"
              :key="index + 'a'"
              :style="{ marginRight: item % 5 == 0 ? '0' : '14px' }"
              @click.stop="showChange('others', item.id)"
            >
              <div class="item_topBox" @click.stop="userRommSkip(item)">
                <img :src="item.avatar" alt="" class="item_top" />
              </div>
              <!-- 左上 标签start -->
              <img
                src="../../static/24.png"
                alt=""
                class="topLabel2"
                v-if="item.type == 2"
              />
              <!-- 左上 标签end -->
              <!-- 是否聊天start -->
              <!-- 0-离线 1-在线 2-房间中 -->

              <div class="onLine flex-content" v-if="item.onlineStatus == 0">
                <div
                  class="onLineIcon"
                  style="background: red; margin-right: 4px"
                ></div>
                离线
              </div>
              <div class="onLine flex-content" v-if="item.onlineStatus == 1">
                <div class="onLineIcon" style="margin-right: 4px"></div>
                在线
              </div>
              <div class="chat flex-content" v-if="item.onlineStatus == 2">
                <img src="../../static/23.png" alt="" class="chatImg" />
                聊天中
              </div>
              <!-- 是否聊天end -->
              <!-- 聊天时间 -->
              <div
                class="chatOfTime"
                v-if="item.voiceFile"
                @click.stop="playAudio(item.voiceFile)"
              >
                <img src="../../static/22.png" alt="" class="chatOfTimeImg" />
                <p>{{ item.voiceLen ? item.voiceLen : 0 }}“</p>
              </div>
              <!-- 聊天时间 -->
              <div class="item_text">
                <div class="item_name">{{ item.nickname }}</div>
                <div class="padding_4">
                  {{ item.skillName }} <span v-if="item.voiceName"> | </span>
                  {{ item.voiceName }}
                </div>
                <div class="flex-aling bottm_style1">
                  <div>
                    <b class="moneyType">{{ item.price }}</b
                    >{{ item.unit }}
                  </div>
                  <div class="flex-aling">
                    <img
                      src="../../static/21.png"
                      alt=""
                      class="address_icon"
                    />
                    {{ item.city }}
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <el-empty v-else></el-empty>
          </template>
          <div class="center_box">
            <div
              class="conter_title"
              v-if="homeType == 2 && leftPitch ==1"
            >
              {{ `买${systemSet.buyXy[0]}赠${systemSet.buyXy[1]}` }}专区
              <p></p>
            </div>
          </div>
          <!-- @scroll="userHandleScroll($event)" -->
          <!-- 买赠活动 推荐内容 -->
          <template  v-if="homeType == 2 && leftPitch ==1">

            <div  style="display:flex" v-if="giveList.length>0">
              <div 
              class="recommend_item"
              v-for="(item, index) in giveList"
              :key="index + 'a'"
              :style="{ marginRight: item % 5 == 0 ? '0' : '14px' }"
              @click.stop="showChange('others', item.id)"
            >
              <div class="item_topBox" @click.stop="userRommSkip(item)">
                <img :src="item.avatar" alt="" class="item_top" />
              </div>
              <!-- 左上 标签start -->
              <div class="topLabel flex-content" v-if="item.type == 1">
                {{ `买${systemSet.buyXy[0]}赠${systemSet.buyXy[1]}` }}
              </div>

              <!-- 左上 标签end -->
              <!-- 是否聊天start -->
              <!-- 0-离线 1-在线 2-房间中 -->

              <div class="onLine flex-content" v-if="item.onlineStatus == 0">
                <div
                  class="onLineIcon"
                  style="background: red; margin-right: 4px"
                ></div>
                离线
              </div>
              <div class="onLine flex-content" v-if="item.onlineStatus == 1">
                <div class="onLineIcon" style="margin-right: 4px"></div>
                在线
              </div>
              <div class="chat flex-content" v-if="item.onlineStatus == 2">
                <img src="../../static/23.png" alt="" class="chatImg" />
                聊天中
              </div>
              <!-- 是否聊天end -->
              <!-- 聊天时间 -->
              <div
                class="chatOfTime"
                v-if="item.voiceFile"
                @click.stop="playAudio(item.voiceFile)"
              >
                <img src="../../static/22.png" alt="" class="chatOfTimeImg" />
                <p>{{ item.voiceLen ? item.voiceLen : 0 }}“</p>
              </div>
              <!-- 聊天时间 -->
              <div class="item_text">
                <div class="item_name">{{ item.nickname }}</div>
                <div class="padding_4">
                  {{ item.skillName }} <span v-if="item.voiceName"> | </span>
                  {{ item.voiceName }}
                </div>
                <div class="flex-aling bottm_style1">
                  <div>
                    <b class="moneyType">{{ item.price }}</b
                    >{{ item.unit }}
                  </div>
                  <div class="flex-aling">
                    <img
                      src="../../static/21.png"
                      alt=""
                      class="address_icon"
                    />
                    {{ item.city }}
                  </div>
                </div>
              </div>
            </div>
            </div>
            
            <el-empty v-else></el-empty>
          </template>
        </div>
        <!-- 聊天室列表 -->
        <div
          class="serachChatRoom_box flex_wrap"
          @scroll="roomHandleScroll($event)"
          v-if="homeType == 3"
          style="height: 660px"
        >
          <div
            class="serachChatRoom_item"
            v-for="item in roomList"
            :key="item.id"
            @click.stop="rommSkip(item)"
          >
            <img
              src="../../image/room/room1.png"
              alt=""
              v-if="
                item.labelName == '游戏派单厅' || item.labelName == '声优派单厅'
              "
              class="topLeftIcon"
            />

            <img
              src="../../image/room/room2.png"
              alt=""
              class="topLeftIcon"
              v-else-if="item.labelName == '点唱'"
            />
            <div class="topLeftIconPei" v-else-if="item.labelName == '游戏陪玩'">
              <img
              src="../../image/room/room7.png"
              alt=""
              style="width:18px;height:18px"
            />
            <span class="topicNameColor">{{item.topicName}}</span>
            </div>
            
            <img
              src="../../image/room/room5.png"
              alt=""
              class="topLeftIcon"
              v-else-if="item.labelName == '扩列交友'"
            />
            <img
              src="../../image/room/room5.png"
              alt=""
              class="topLeftIcon"
              v-else-if="item.labelName == '交友'"
            />
            <img
              src="../../image/room/room6.png"
              alt=""
              class="topLeftIcon"
              v-else-if="item.labelName == '男声'"
            />
            <img
              src="../../image/room/room3.png"
              alt=""
              class="topLeftIcon"
              v-else
            />
            <img :src="item.logo" alt="" class="serachChatRoom_img" />
            <div
              class="text-cut serachChatRoom_text1"
              style="width: 90%; text-align: center"
            >
              {{ item.name }}
            </div>
            <div class="serachChatRoom_text2"><span style="color:rgba(255, 42, 62, 1)">{{ item.roomNum }}人</span>正在语聊中</div>
            <div class="serachChatRoom_text3 flex-content">
              <img src="../../static/60.png" alt="" /> <span style="color:#333">{{ item.hotNum }}</span>
            </div>
          </div>
        </div>
        <!-- 聊天室列表end -->
        <!-- 搜索结果start -->
        <div class="" v-if="homeType == 7">
          <!-- 搜索历史 -->
          <!-- <div class="serach_box flex">
            <div
              class="serachChatRoom_title serachChatRoom_Btn" style="color:#333"
              v-for="(opn, key2) in appSearchHistory"
              :key="key2"
            >
              {{ opn.name }}
              <img
                src="../../static/59.png"
                alt=""
                class="serach_img"
                @click.stop="getdelHistory(opn.name)"
              />
            </div>
          </div> -->
          <!-- 搜索聊天室 -->
          <div class="search_boxroll" style="margin-top:25px">
            <div class="serachChatRoom_title" style="display:flex">
              <div class="serachChatRoom_Btn" style="margin-right:12px" v-for="(opn, key2) in appSearchHistory" :key="key2">
                <div style="width: 100%; margin-left: 15px">{{ opn.name }}</div>
                <div style="margin-left: 5px" @click="keywordBtn(key2,opn.name)">×</div>
              </div>
            </div>
            <div class="serachChatRoom_title" v-if="leftPitch==7 || leftPitch==8">
              聊天室 · {{ searchRoomData.length }}
            </div>
            <div class="serachChatRoom_box flex_wrap" v-if="leftPitch==7 || leftPitch==8">
              <div
                class="serachChatRoom_item"
                v-for="item in searchRoomData"
                :key="item.id"
                @click.stop="rommSkip(item)"
              >
                <!-- @click.stop="rommSkip(item)" -->
                <img
                src="../../image/room/room1.png"
                alt=""
                v-if="
                  item.labelName == '游戏派单厅' || item.labelName == '声优派单厅'
                "
                class="topLeftIcon"
              />
  
              <img
                src="../../image/room/room2.png"
                alt=""
                class="topLeftIcon"
                v-else-if="item.type == '点唱'"
              />
              <div class="topLeftIconPei" v-else-if="item.labelName == '游戏陪玩'">
                <img
                src="../../image/room/room7.png"
                alt=""
               style="width:18px;height:18px"
              />
              <span class="topicNameColor">{{item.topicName}}</span>
              </div>
              <img
                src="../../image/room/room5.png"
                alt=""
                class="topLeftIcon"
                v-else-if="item.type == '扩列交友'"
              />
              <img
                src="../../image/room/room5.png"
                alt=""
                class="topLeftIcon"
                v-else-if="item.type == '交友'"
              />
              <img
                src="../../image/room/room6.png"
                alt=""
                class="topLeftIcon"
                v-else-if="item.type == '男声'"
              />
              <img
                src="../../image/room/room3.png"
                alt=""
                class="topLeftIcon"
                v-else
              />
                <img :src="item.logo" alt="" class="serachChatRoom_img" />
                <div class="text-cut serachChatRoom_text1">
                  {{ item.name }}{{ item.name }}{{ item.name }}
                </div>
                <div class="serachChatRoom_text2">ID:{{ item.roomNo }}</div>
                <div class="serachChatRoom_text3 flex-content" style="color:#333">
                  <img src="../../static/60.png" alt="" />  {{ item.hot }}
                </div>
              </div>
              <!-- 查看更多 -->
              <div
                class="serachChatRoom_item"
                v-if="searchRoomData.length == 4"
                @click.stop="moreRoom()"
              >
                <img src="../../static/182.png" alt="" />
                <div class="serachChatRoom_text2" style="color: #fff">
                  查看更多
                </div>
              </div>
            </div>
            <!-- 搜索用户 -->
            <div class="serachChatRoom_title" v-if="leftPitch==7||leftPitch==9">
              用户 · {{ searchPlayerData.length }}
            </div>
            <div class="serachChatRoom_box flex_wrap" v-if="leftPitch==7||leftPitch==9">
              <div
                class="serachChatRoom_item"
                style="height: 242px"
                v-for="item in searchPlayerData"
                :key="item.id"
                @click.stop="showChange('others', item.id)"
              >
                <!-- <img :src="item.avatar" alt="" class="userData_head" /> -->
                <div class="item_topBox" @click.stop="userRommSkip(item)">
                  <img :src="item.avatar" alt="" class="item_top" />
                </div>
                <div
                  class="onLine flex-content font-12"
                  v-if="item.onlineStatus == 0"
                >
                  <div
                    class="onLineIcon"
                    style="background: red; margin-right: 4px"
                  ></div>
                  离线
                </div>
                <div
                  class="onLine flex-content font-12"
                  v-if="item.onlineStatus == 1"
                >
                  <div class="onLineIcon" style="margin-right: 4px"></div>
                  在线
                </div>
                <div
                  class="chat flex-content font-12"
                  v-if="item.onlineStatus == 2"
                >
                  <img src="../../static/23.png" alt="" class="chatImg" />
                  聊天中
                </div>
                <div class="padding_10" style=" width: 160px">
                  <div class="flex">
                    <div class="font-14">{{ item.nickname }}</div>
                    <div class="age_sexIcon font-10 flex-aling">
                      <img
                        src="../../static/134.png"  
                        style="width:15px;height:15px"
                        alt=""
                        class="seximg_icon"
                        v-if="item.sex == 1"
                      />
                      <img
                        src="../../static/25.png"
                        style="width:15px;height:15px"
                        alt=""
                        class="seximg_icon"
                        v-else
                      />
                      {{ item.age }}
                    </div>
                  </div>
                  <div class="flex-spacebetween" style="margin-top: 4px">
                    <div style="color: #8e92b1; font-size: 12px">
                      ID:{{ item.userNo }}
                    </div>
                    <div class="flex-aling font-12" style="color: #8e92b1">
                      <img
                        src="../../static/21.png"
                        alt=""
                        class="address_icon"
                      />{{ item.city }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 搜索结果end -->
      </div>
      <div class="right">
        <!-- 轮播图 -->
        <!-- <img
          src="../../static/10.png"
          alt=""
          class="rightTopImg"
          @click="enterShow = true"
        /> -->
        <el-carousel height="74px" style="margin: 20px 10px">
          <el-carousel-item v-for="(item, index) in bannerImgList" :key="index">
            <img
              :src="item.image"
              alt=""
              class="rightTopImg"
            />
          </el-carousel-item>
        </el-carousel>
        <!-- tab栏 -->
        <div class="flex">
          <div class="rankingTab flex">
            <div
              :class="rankingTyep == 1 ? 'rankingPitch' : ''"
              @click.stop="chatRoomSwitch(1)"
            >
              热门聊天室
            </div>
            <div
              :class="rankingTyep == 3 ? 'rankingPitch' : ''"
              @click.stop="chatRoomSwitch(3)"
              style="margin: 0 12px"
            >
              我的好友
            </div>
            <div
              :class="rankingTyep == 2 ? 'rankingPitch' : ''"
              @click.stop="chatRoomSwitch(2)"
            >
              我的收藏
            </div>
          </div>
          <img
            src="../../static/200.png"
            alt=""
            class="rankingImage"
            @click.stop="
              showChange('ranking');
              moreType = false;
            "
          />
          <!-- <div
            class="ranking flex-content"
            @click.stop="
              showChange('ranking');
              moreType = false;
            "
          >
            <img src="../../static/12.png" alt="" />
            排行榜
          </div> -->
        </div>
        <!-- -------------- 聊天室列表 --------------- -->
        <div
          class="chatRoomList"
          @scroll="rightRoomHandleScroll($event)"
          v-if="rankingTyep == 1"
        >
          <div
            class="chatRoomItem flex"
            v-for="item in rightRoomList"
            :key="item.id"
            :class="'chatRoomItem' + item.secondType"
            @click.stop="rommSkip(item)"
          >
            <!-- 左上角icon -->
            <img
              src="../../image/room/room1.png"
              alt=""
              v-if="
                item.labelName == '游戏派单厅' || item.labelName == '声优派单厅'
              "
              class="topLeftIcon"
            />

            <img
              src="../../image/room/room2.png"
              alt=""
              class="topLeftIcon"
              v-else-if="item.labelName == '点唱'"
            />
            <div class="topLeftIconPei" v-else-if="item.labelName == '游戏陪玩'">
              <img
              src="../../image/room/room7.png"
              alt=""
            style="width:18px;height:18px"
            />
            <span class="topicNameColor">{{item.topicName}}</span>
            </div>
            <img
              src="../../image/room/room5.png"
              alt=""
              class="topLeftIcon" style="width:70px"
              v-else-if="item.labelName == '扩列交友'"
            />
            <img
              src="../../image/room/room5.png"
              alt=""
              class="topLeftIcon" style="width:70px"
              v-else-if="item.labelName == '交友'"
            />
            <img
              src="../../image/room/room6.png"
              alt=""
              class="topLeftIcon"
              v-else-if="item.labelName == '男声'"
            />
            <img
              src="../../image/room/room3.png"
              alt=""
              class="topLeftIcon"
              v-else
            />
            <!-- <div
              class="roomType_icon icon_bg1"
              v-else-if="item.secondType == 2"
            >
              开黑
            </div> -->
            <!-- 左上角icon -->

            <img :src="item.logo" alt="" class="itemImg" v-if="item.logo" />
            <img src="@/assets/DefaultImg.png" alt="" class="itemImg" v-else />
            <div style="width: 69%">
              <div style="width: 180px; font-weight: bold" class="text-cut">
                {{ item.name }}
              </div>
              <div class="flex-aling" style="padding: 4px 0">
                <img
                  :src="item.hostAvatar"
                  alt=""
                  class="listImg1"
                  v-if="item.hostAvatar"
                />
                <img
                  :src="item.userAvatarList[0]"
                  alt=""
                  class="listImg2"
                  v-if="item.userAvatarList[0]"
                />
                <img
                  :src="item.userAvatarList[1]"
                  alt=""
                  class="listImg3"
                  v-if="item.userAvatarList[1]"
                />
                <img
                  :src="item.userAvatarList[2]"
                  alt=""
                  class="listImg4"
                  v-if="item.userAvatarList[2]"
                />
                <img
                  :src="item.userAvatarList[3]"
                  alt=""
                  class="listImg5"
                  v-if="item.userAvatarList[3]"
                />
                <div>{{ item.roomNum }}人</div>
              </div>
              <div class="chatRoomItem_text flex-aling">
                <div class="flex-aling" v-if="item.roomNum != '0'">
                  <img
                    src="../../static/14.png"
                    alt=""
                    style="width: 14px; height: 14px; margin-right: 4px"
                  />
                  聊天中
                </div>
                <div else></div>
                <div class="flex-aling">
                  <img
                    src="../../static/13.png"
                    alt=""
                    style="width: 16px; height: 16px"
                  />
                  {{ item.hotNum }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="chatRoomList"
          @scroll="rightRoomHandleScroll($event)"
          v-if="rankingTyep == 2"
        >
        <div v-if="rightRoomList.length==0">暂无数据</div>
        <div
        class="chatRoomItem flex"
        v-for="item in rightRoomList"
        :key="item.id"
        :class="'chatRoomItem' + item.secondType"
        @click.stop="rommSkip(item)"
      >
        <!-- 左上角icon -->
        <img
          src="../../image/room/room1.png"
          alt=""
          v-if="
            item.labelName == '游戏派单厅' || item.labelName == '声优派单厅'
          "
          class="topLeftIcon"
        />

        <img
          src="../../image/room/room2.png"
          alt=""
          class="topLeftIcon"
          v-else-if="item.labelName == '点唱'"
        />
        <div  class="topLeftIconPei" v-else-if="item.labelName == '游戏陪玩'">
          <img
          src="../../image/room/room7.png"
          alt=""
           style="width:18px;height:18px"
        />
        <span class="topicNameColor">{{item.topicName}}</span>
        </div>
        <img
          src="../../image/room/room5.png"
          alt=""
          class="topLeftIcon"
          v-else-if="item.labelName == '扩列交友'"
        />
        <img
        src="../../image/room/room5.png"
        alt=""
        class="topLeftIcon"
        v-else-if="item.labelName == '交友'"
      />
        <img
          src="../../image/room/room6.png"
          alt=""
          class="topLeftIcon"
          v-else-if="item.labelName == '男声'"
        />
        <img
          src="../../image/room/room3.png"
          alt=""
          class="topLeftIcon"
          v-else
        />
        <!-- <div
          class="roomType_icon icon_bg1"
          v-else-if="item.secondType == 2"
        >
          开黑
        </div> -->
        <!-- 左上角icon -->

        <img :src="item.logo" alt="" class="itemImg" v-if="item.logo" />
        <img src="@/assets/DefaultImg.png" alt="" class="itemImg" v-else />
        <div style="width: 69%">
          <div style="width: 180px; font-weight: bold" class="text-cut">
            {{ item.name }}
          </div>
          <div class="flex-aling" style="padding: 4px 0">
            <img
              :src="item.hostAvatar"
              alt=""
              class="listImg1"
              v-if="item.hostAvatar"
            />
            <img
              :src="item.userAvatarList[0]"
              alt=""
              class="listImg2"
              v-if="item.userAvatarList[0]"
            />
            <img
              :src="item.userAvatarList[1]"
              alt=""
              class="listImg3"
              v-if="item.userAvatarList[1]"
            />
            <img
              :src="item.userAvatarList[2]"
              alt=""
              class="listImg4"
              v-if="item.userAvatarList[2]"
            />
            <img
              :src="item.userAvatarList[3]"
              alt=""
              class="listImg5"
              v-if="item.userAvatarList[3]"
            />
            <div>{{ item.roomNum }}人</div>
          </div>
          <div class="chatRoomItem_text flex-aling">
            <div class="flex-aling" v-if="item.roomNum != '0'">
              <img
                src="../../static/14.png"
                alt=""
                style="width: 14px; height: 14px; margin-right: 4px"
              />
              聊天中
            </div>
            <div else></div>
            <div class="flex-aling">
              <img
                src="../../static/13.png"
                alt=""
                style="width: 16px; height: 16px"
              />
              {{ item.hotNum }}
            </div>
          </div>
        </div>
      </div>
        </div>
        <div
          class="chatRoomList"
          @scroll="rightRoomHandleScroll($event)"
          v-if="rankingTyep == 3"
        >
          <div style="width:230px">
            <el-tree
              :data="myFriendsData"
              :props="defaultProps"
              @node-click="handleNodeClick"
            >
            <template v-slot="{data}">
              <div>
                <div style="display:flex; 
                align-items: center;" @click="goBtnMessage(data)">
                  <img style="width:32px;height:32px;border-radius: 50%;" v-if="data.avatar" :src="data.avatar"></img>
                  <div style="margin-left:4px">
                    <div :style="data.avatar?'line-height:16px':''">{{data.groupingName}}</div> 
                    <div class="textOv" :style="data.avatar?'line-height:16px':''" v-if="data.avatar">{{data.title}}</div>
                  </div>
                </div>
              </div>
            </template>
          </el-tree>
          </div>
        </div>
        <!-- 底部tab -->
        <div class="baseTab flex">
          <!-- 首页隐藏盲盒抽奖 2023/3/1 ljt-->
          <!-- <div class="baseTab_item" @click.stop="showChange('blindBox');moreType = false">
            <img src="../../static/17.png" alt="" class="baseTabIcon1" />
            盲盒抽奖
          </div> -->
          <divy
            class="baseTab_item"
            @click.stop="
              randomSendChange();
              moreType = false;
            "
          >
            <img src="../../static/18.png" alt="" class="baseTabIcon2" />
            人工派单
          </divy>
          <div
            class="baseTab_item"
            @click.stop="
              showChange('messageTopup');
              moreType = false;
              getCount();
            "
          >
            <img src="../../static/19.png" alt="" class="baseTabIcon2" />
            <div v-if="unreadNum > 0" class="unreadNumStyle" style="color:#fff">
              {{ unreadNum }}
            </div>
            消息
          </div>
          <div
            class="baseTab_item"
            @click.stop="
              manitoChange();
              moreType = false;
            "
            v-if="userData.isPlayer == 0"
          >
            <img src="../../static/20.png" alt="" class="baseTabIcon2" />
            成为大神
          </div>
        </div>
      </div>
    </div>
    <!-- 下载弹窗 -->
    <div class=" downloadApp" v-if="downloadAppShow">
      <div class="flex">
        <div class="flex-dir">
          <div style="color: #333">Android下载</div>
          <img
            :src="download.appdown"
            v-if="download.appdown"
            alt=""
            class="downloadApp_img"
          />
        </div>
        <div class="flex-dir">
          <div style="color: #333">IOS下载</div>
          <img
           :src="download.iosdown"
           v-if="download.iosdown"
            alt=""
            class="downloadApp_img"
          />
        </div>
        
    </div>
    <div style="color:#000;cursor: pointer;display:flex;align-items: center;" @click="downGoShu(urlLink)">
      <img src="../../static/user10.png" style="width:20px;height:20px" alt="" ></img>
      <div style="margin-left:12px"  v-loading="loadings">狗书语音桌面端下载</div>
    </div>
  </div>
    <!-- 退出登录弹窗 -->
    <div class="quitPopUp_box" v-if="quitLoginShow">
      <div class="quit_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">提示</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click.stop="quitLoginShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="flex-content quit_text">你确认要退出系统登录吗？</div>
        <div class="flex-content">
          <div
            class="flex-content quit_btn"
            @click.stop="quitLoginShow = false"
          >
            取消
          </div>
          <div
            class="flex-content quit_btn"
            style="background: #fbda01; margin-left: 20px"
            @click.stop="quitLogin()"
          >
            确认
          </div>
        </div>
      </div>
    </div>
    <!-- 活动说明弹窗 -->
    <div class="quitPopUp_box" v-if="activityPopUp_box">
      <div
        class="quit_box"
        style="width: 700px; height: 400px; overflow-y: scroll"
      >
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">活动说明</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click.stop="activityPopUp_box = false"
          />
        </div>
        <!-- 头部end -->
        <div
          class="font-12"
          style="padding: 20px; color: #616683"
          v-html="activityText"
        ></div>
      </div>
    </div>

    <!-- 房间上锁弹窗 -->
    <div class="quitPopUp_box" v-if="roomLockShow">
      <div class="wheat_remind" style="height: 310px">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">房间上锁</div>
          <img src="../../static/quit.png" alt="" @click.stop="emptyInput()" />
        </div>
        <!-- 头部end -->
        <div class="flex-dir">
          <div
            class="flex-content"
            style="color: #b9bbc5; width: 100%; margin-top: 30px"
          >
            请输入四位数字密码
          </div>
          <!-- 输入密码 -->
          <template>
            <div class="salary-container">
              <div class="enter_box">
                <input
                  class="int"
                  v-model="passwordName"
                  maxlength="4"
                  type="number"
                  autofocus
                />
                <div
                  class="enter"
                  v-for="(item, index) in password"
                  :key="index"
                >
                  <!-- 跳跃的指示符 -->
                  <div :class="{ tips: place == index }"></div>
                  {{ item }}
                </div>
              </div>
            </div>
          </template>
          <div class="flex">
            <div
              class="wheatRemind_apply flex-content"
              @click.stop="emptyInput()"
              style="background: rgba(245, 245, 245, 1); margin-right: 20px"
            >
              取消
            </div>
            <div
              class="wheatRemind_apply flex-content"
              @click.stop="confirmPassword()"
            >
              确认
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 他人主页 -->
    <othersHome
      @closeOthers="showChange('others')"
      :userId="manitoId"
      v-if="othersShow"
    ></othersHome>
    <!-- 排行榜 -->
    <rankingList
      v-if="ranking"
      @closeRanking="showChange('ranking')"
    ></rankingList>
    <!-- 个人中心 -->
    <personalCenter
      v-if="personalShow"
      :userId="userData.id"
      :tabType="typeValue"
      @closePersonal="
        showChange('personal');
        getUserInfo();
      "
    ></personalCenter>
    <!-- 帮助中心 -->
    <helpindex
      v-if="helpindexShow"
      @closeHelpindex="showChange('helpindex')"
    ></helpindex>
    <!-- 盲盒抽奖 -->
    <blindBox
      v-if="blindBoxShow"
      @closeBlindBox="showChange('blindBox')"
    ></blindBox>
    <!-- 首充弹窗 -->
    <firstCharge
      v-if="firstShow"
      @firstQuit="showChange('first')"
    ></firstCharge>
    <!-- 公会入驻 -->
    <enter v-if="enterShow" @setenterClose="showChange('enter')"></enter>
    <!-- 我的消息 -->
    <myMessage
      v-if="myMessageShow"
      ref='myMessage'
      @closeMessage="showChange('message')"
      @placeSkip="placeSkipChange($event)"
    ></myMessage>
    <walletTopup
      v-if="walletTopupShow"
      :entrance="1"
      @walletQuitChange="walletTopupShow = false"
    ></walletTopup>
  </div>
</template>

<script>
import othersHome from "./othersHome.vue";
import rankingList from "./rankingList.vue";
import personalCenter from "./personalCenter.vue";
import helpindex from "./helpCenter.vue";
import blindBox from "../blindBox/index.vue";
import firstCharge from "./firstCharge.vue";
import enter from "../association/enter.vue";
import myMessage from "./messagePage.vue";
import walletTopup from "../personalCenterAll/walletTopup.vue"; //充值弹窗
import guangchang from "../login/guangchang.vue"; //广场
export default {
  components: {
    othersHome, //他人主页
    rankingList, //排行榜
    personalCenter, //个人中心
    helpindex, //帮助中心
    blindBox, //盲盒抽奖
    firstCharge, //首充弹窗
    enter, //公会入驻
    myMessage, //我的消息
    walletTopup, //充值弹窗
    guangchang,
  },
  data() {
    return {
      activityPopUp_box: false, //活动说明弹窗
      activityText: "",
      walletTopupShow: false, //充值弹窗
      quitLoginShow: false, //退出登录弹窗
      userData: {}, //用户信息
      downloadAppShow: false, //下载弹窗
      keyword: "", //搜索内容
      fits: ["fill", "contain", "cover", "none", "scale-down"],
      tabIndex: 1, //头部tab
      leftPitch: 0, //左侧tab
      manitoId: "", //大神id
      othersShow: false, //他人主页
      ranking: false, //排行榜
      personalShow: false, //个人中心
      helpindexShow: false, //帮助中心
      blindBoxShow: false, //盲盒抽奖
      firstShow: false, //首充
      enterShow: false, //公会入驻
      homeType: 1, //首页展示 0搜索 1首页 2列表
      myMessageShow: false, //我的消息
      manitoShow: false, //成为大神
      manitoList: [], //大神列表
      manitoTotal: 0, //大神总数
      homePages: {
        labelId: "", //	分类标签Id
        pageNum: "1",
        pageSize: "15",
      }, //首页分页
      hotLabelLists: [], //热门标签
      allLabelLists: [], //全部标签
      rankingTyep: 1, // 1热门聊天室 2我的收藏
      roomList: [], //热门聊天室
      roomListTotal: 0, //聊天室总数
      sexValue: "", //筛选性别
      sexOptions: [
        {
          value: "",
          label: "不限",
        },
        {
          value: "0",
          label: "男",
        },
        {
          value: "1",
          label: "女",
        },
      ],
      smartValue: "", //1在线 0离线
      smartOptions: [
        {
          value: "",
          label: "不限",
        },
        {
          value: "1",
          label: "在线",
        },
      ],
      isSameCityValue: "", //是否同城
      isSameCityOptions: [
        {
          value: "",
          label: "不限",
        },
        {
          value: "1",
          label: "同城",
        },
      ],
      value: "",
      searchPlayerData: [], //搜索大神数据
      searchRoomData: [], //搜索大神数据
      popUpShow: "", //页面跳转
      userId: "",
      roomLockShow: false, //房间上锁弹窗
      passwordName: "",
      place: 0,
      password: ["", "", "", ""], //密码
      pitchRoom: {}, //选中的聊天室
      roomPage: { pageNum: 1, pageSize: 15, type: "hot" }, //聊天室分页
      rightRoomPage: { pageNum: 1, pageSize: 15, categoryId: "hot" }, //右侧房间分页
      rightRoomList: [], //获取右侧列表
      rightRoomListTotal: 0,
      audio: null, //音频播放
      loading: true,
      loadings: false,
      bannerImgList: [], //轮播图
      moreType: false, //更多弹窗
      download: {}, //下载地址
      discontList: [], //888折扣
      giveList: [], //买增
      unreadNum: 0, //未读消息数
      typeValue: 0, //个人中心左侧tab状态
      systemSet: {}, //活动设置
      myFriendsData: [],
      bookData: [],
      nameText: "热门聊天室",
      DiscountConfigList: [],
      defaultProps: {
        children: "children",
        label: "nickname",
      },
      urlLink:'https://tjj-sever.oss-cn-beijing.aliyuncs.com/goushuyuyin.zip',
      data: [
        {
          groupingName: "一级 1",
        },
        {
          groupingName: "一级 2",
          children: [
            {
              groupingName: "二级 2-1",
              children: [
                {
                  groupingName: "三级 2-1-1",
                },
              ],
            },
            {
              groupingName: "二级 2-2",
              children: [
                {
                  groupingName: "三级 2-2-1",
                },
              ],
            },
          ],
        },
        {
          groupingName: "一级 3",
          children: [
            {
              groupingName: "二级 3-1",
              children: [
                {
                  groupingName: "三级 3-1-1",
                },
              ],
            },
            {
              groupingName: "二级 3-2",
              children: [
                {
                  groupingName: "三级 3-2-1",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  watch: {
    // 密码输入监听
    passwordName(newV) {
      let arr = newV.split(""); //转为数组
      if (arr.length > 6) {
        arr = arr.splice(0, 4); //只取六位
      }
      this.place = arr.length; //更新指示符的位置
      this.password = ["", "", "", ""];
      arr.map((item, index) => {
        this.password[index] = item;
      });
    },
  },
  created() {
    // this.chat.destroy();
    // this.$router.go(0)
    let that = this;
    if (localStorage.getItem("userTIMInfo")) {
      let promise = this.chat.login({
        userID: JSON.parse(localStorage.getItem("userTIMInfo")).id,
        userSig: JSON.parse(localStorage.getItem("userTIMInfo")).userSig,
      });
    }
    let onSdkReady = function (event) {
      if (event.name == "sdkStateReady") {
      }
    };
    this.chat.on(this.TencentCloudChat.EVENT.SDK_READY, onSdkReady);
    if (localStorage.getItem("user-token")) {
      this.getUserInfo(); //用户信息
      this.getMyFriendsData();
      this.getRoomCollection(); //收藏
      this.getDiscountInfo();
      this.getStatus();
      this.forumList(); //广场
      setTimeout(() => {
        that.getCount();
      }, 800);
    }
  },
  mounted() {
    let that = this;

    this.getBannerList(); //轮播图
    this.getHomeManito(); //主页大神列表
    this.getHotLabelList(); //热门分类
    this.getAllSecondLabel();
    this.hotRoomList(); //热门聊天室
    this.getRightRoomList();
    this.gitImg();
    // 需要判断登录
    this.popUpShow = this.$route.query.popUpShow;
    this.userId = this.$route.query.userId ? this.$route.query.userId : "";
    if (this.popUpShow) {
      this.showChange(this.popUpShow, this.userId);
    }
  },
  computed: {
    appSearchHistory() {
      // 搜索历史
      return this.$store.state.appSearchHistory;
    },
  },
  destroyed() {
    // 页面销毁
    const Events = this.$RongIMLib.Events;
    // 移除具体事件的所有监听器
    // this.$RongIMLib.removeEventListeners(Events.MESSAGES);
  },
  methods: {
    // 好友列表
    // FriendsDataList() {
    //   this.$api.FriendsData().then((res) => {
    //     console.log(res, "resres");
    //   });
    // },
    // 好友分组
    handleNodeClick(e) {
      if (e.userId) {
        e.userProfile = {
          userID: e.userId,
        };

        this.$nextTick(() => {
          console.log(this.$refs, "sssssss");
          this.$refs.myMessage.selectChat(e, "", e.userId);
        });
        //
        this.myMessageShow = true;
        this.$emit("closeOthers");
      }
    },
    goBtnMessage(data) {},
    //好友
    getMyFriendsData() {
      this.myFriendsData = [];

      this.$api.myFriends().then((res) => {
        if (res.code == 1) {
          // console.log(res,)

          res.data.forEach((item) => {
            item.nickname = item.groupingName;
          });
          this.$api.followAddressBookList().then((res1) => {
            this.bookData = res1.data;
            res.data.forEach((item) => {
              item.children = [];
              this.bookData.forEach((item1) => {
                if (item.id == item1.groupId) {
                  console.log(item1, "ttttttt");
                  item1.groupingName = item1.nickName;
                  //  item.groupingName=item1.nickname
                  //  groupingName
                  item.children.push(item1);
                  //  item1
                }
              });
              this.myFriendsData.push(item);
            });

            console.log(
              this.myFriendsData,
              "ssssssssddddddddd",
              this.data,
              "sssssssdddddddddd"
            );
          });

          // console.log(this.myFriendsData,'dadada  ')
        }
      });
    },

    // 获取活动设置和说明和消息设置
    getDiscountInfo() {
      this.$api.getDiscountInfo().then((res) => {
        // console.log(res,'resres')
        if (res.code == 1) {
          res.data.buyXy = res.data.buyXy.split(",");
          this.systemSet = res.data;
          this.activityText = res.data.discountExplain;
        }
      });
    },
    // 获取会话
    getStatus() {
      let that = this;
      let onTotalUnreadMessageCountUpdated = function (event) {
        that.getCount();
      };
      this.chat.on(
        this.TencentCloudChat.EVENT.TOTAL_UNREAD_MESSAGE_COUNT_UPDATED,
        onTotalUnreadMessageCountUpdated
      );
    },
    getCount() {
      console.log(
        this.chat.getTotalUnreadMessageCount(),
        "this.chat.getTotalUnreadMessageCount()"
      );
      this.unreadNum = this.chat.getTotalUnreadMessageCount();
      this.$api.unreadCount().then((res) => {
        if (res.code == 1) {
          res.data.forEach((item) => {
            this.unreadNum = this.unreadNum + item.unReadCount;
          });
        }
      });
    },
    getRoomList(id, index, name) {
      if (name) {
        this.nameText = name;
      }
      this.leftPitch = index;
      this.roomPage.pageNum = 1;
      this.roomPage.type = id;
      this.roomList = [];
      this.hotRoomList();
    },
    // 获取房间标签
    getReclassifyList() {
      this.$api.reclassifyList().then((res) => {
        // console.log('房间类型',res);
        if (res.code == 1) {
          this.hotLabelLists = res.data;
        }
      });
    },
    //获取下载二维码
    gitImg() {
      this.$api.getConfig({ configGroup: "appiosimage" }).then((res) => {
        if (res.code == 1) {
          this.download = res.data;
        }
      });
    },
    //人工派单
    randomSendChange() {
      if (!localStorage.getItem("user-token")) {
        this.$router.push({ path: "/login" });
        this.$message.info("请先登录哦~");
        return;
      }
      this.$api.getRandomRoomId({ secondType: 0 }).then((res) => {
        if (res.code == 1 && res.data) {
          this.$router.push({
            path: "/someSingingHall?roomId=" + res.data,
            // query: { roomId: res.data },
          });
          this.$message.info(res.msg);
        } else {
          this.$message.info('暂无房间进行派单');
        }
      });
    },
    // 轮播图
    getBannerList() {
      this.$api.bannerList({ type: 0 }).then((res) => {
        // console.log('轮播图',res);
        if (res.code == 1) {
          this.bannerImgList = res.data;
        }
      });
    },

    playAudio(url) {
      // if(!url){
      //   this.audio = null
      //   return this.$message.info('大神还未认证声音')
      // }

      if (this.audio != null) {
        this.audio.pause();
        this.audio = null;
      } ////myq

      this.audio = new Audio(url);
      this.audio.play();
    },
    // 右侧热门聊天室
    rightRoomHandleScroll(e) {
      let el = e.target;
      //判断是否到达div容器底部
      console.log(el.scrollTop + el.clientHeight >= el.scrollHeight);
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        if (this.rightRoomList.length < this.rightRoomListTotal) {
          this.rightRoomPage.pageNum++;
          this.getRightRoomList();
        }
      }
    },
    // 大神页面滚动
    userHandleScroll(e) {
      let el = e.target;
      //判断是否到达div容器底部
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        if (this.manitoList.length < this.manitoTotal) {
          this.homePages.pageNum++;
          this.getHomeManito();
        }
      }
    },
    // 折扣大神页面滚动
    discountUserScroll(e) {
      let el = e.target;
      //判断是否到达div容器底部
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        if (this.manitoList.length < this.manitoTotal) {
          this.roomPage.pageNum++;
          this.getDisCountPlayerList();
        }
      }
    },
    // 房间列表获取页面滚动距离
    roomHandleScroll(e) {
      let el = e.target;
      //判断是否到达div容器底部
      if (el.scrollTop + el.clientHeight >= el.scrollHeight) {
        if (this.roomList.length < this.roomListTotal) {
          this.roomPage.pageNum++;
          this.hotRoomList();
        }
      }
    },
    // 是否同城
    isSameCityChange() {
      this.homePages.pageNum = 1;
      this.homePages.isSameCity = this.isSameCityValue;
      this.getHomeManito();
    },
    // 智能筛选
    smartChange() {
      this.homePages.pageNum = 1;
      this.homePages.isOnline = this.smartValue;
      this.getHomeManito();
    },
    // 筛选性别
    sexChange() {
      this.homePages.pageNum = 1;
      this.homePages.sex = this.sexValue;
      this.manitoList = [];
      this.getHomeManito();
    },
    // 我的消息跳转他人主页
    placeSkipChange($event) {
      this.myMessageShow = false;
      this.manitoId = $event;
      this.othersShow = true;
    },
    //确认开黑房密码
    confirmPassword() {
      if (!localStorage.getItem("user-token")) {
        this.$router.push({ path: "/login" });
        this.$message.info("请先登录哦~");
        return;
      }
      if (this.pitchRoom.password == this.passwordName) {
        this.$router.push({
          path: "/someSingingHall",
          query: {
            roomId: this.pitchRoom.id,
          },
        });
        this.emptyInput();
      } else {
        this.passwordName = "";
        this.place = 0;
        this.password = ["", "", "", ""];
        this.$message.error("密码错误请重新输入");
      }
    },
    // 清空密码输入
    emptyInput() {
      this.roomLockShow = false;
      this.passwordName = "";
      this.place = 0;
      this.password = ["", "", "", ""];
    },
    // 聊天室切换
    chatRoomSwitch(type) {
      this.rankingTyep = type;
      if (type == 3) {
        this.getMyFriendsData();
      } else {
        if (type == 2) {
          this.rightRoomPage.pageNum = 1;
          this.rightRoomPage.categoryId = "collection";
        } else {
          this.rightRoomPage.pageNum = 1;
          this.rightRoomPage.categoryId = "hot";
        }
        this.rightRoomList = [];
        this.getRightRoomList();
      }
    },
    // 退出登录
    quitLogin() {
      localStorage.removeItem("user-token");
      localStorage.removeItem("rcToken");
      this.$router.push("/login");
    },
    // 认证大神
    manitoChange() {
      if (!localStorage.getItem("user-token")) {
        this.$router.push({ path: "/login" });
        this.$message.info("请先登录哦~");
        return;
      }
      this.$api.queryGuildByUserId({ userId: this.userData.id }).then((res) => {
        if (res.data && res.data.id) {
          this.$router.push("/manito");
        } else {
          this.$message.info("请先入驻公会");
        }
      });
    },
    // 热门聊天室列表
    hotRoomList() {
      this.$api.allRoomList(this.roomPage).then((res) => {
        if (res.code == 1) {
          res.data.records.forEach((item) => {
            this.roomList.push(item);
          });
          console.log(this.roomList, "sssssssss");
          this.roomListTotal = res.data.total;
        }
      });
    },

    // 右侧热门聊天室列表
    getRightRoomList() {
      this.$api.allRoomList(this.rightRoomPage).then((res) => {
        if (res.code == 1) {
          res.data.records.forEach((item) => {
            this.rightRoomList.push(item);
          });
          this.rightRoomListTotal = res.data.total;
        }
      });
    },
    // 主页大神列表
    getHomeManito() {
      this.$api.getHomeManito(this.homePages).then((res) => {
        if (res.code == 1) {
          if (this.homePages.pageNum == 1) {
            this.manitoList = [];
          }
          res.data.rows.forEach((item) => {
            this.manitoList.push(item);
          });
          this.manitoTotal = res.data.total;
        }
      });
    },
    // 重置首页
    getInfo(type, on) {
      if (on) {
        this.leftPitch = type;
      } else {
        this.leftPitch = 1;
      }
      if (type == 0) {
        this.homePages.labelId = "";
      } else {
        this.homePages.labelId = type;
      }
      this.moreType = false;
      this.getHomeManito();
    },
    // 热门分类
    getHotLabelList() {
      this.$api.hotLabelList().then((res) => {
        // console.log("热门分类", res);
        if (res.code == 1) {
          this.hotLabelLists = res.data;
        }
      });
    },
    // 全部分类
    getAllSecondLabel() {
      this.$api.getAllSecondLabel().then((res) => {
        // console.log("全部分类", res);
        if (res.code == 1) {
          this.allLabelLists = res.data;
        }
      });
    },
    //获取用户信息
    getUserInfo() {
      this.$api.queryUser().then((res) => {
        if (res.code == 1) {
          this.userData = res.data;
          this.loading = false;
          this.$store.commit("setUserInfo", this.userData);
        } else {
          this.loading = true;
        }
      });
    },
    // 去登录
    loginPage() {
      this.$router.push("/login");
    },

    // 搜索大神与聊天室
    searchList() {
      let data = { pageNum: 1, pageSize: 999, key: this.keyword };
      // 搜索聊天室
      this.$api
        .searchRoomList({ pageNum: 1, pageSize: 4, key: this.keyword })
        .then((res) => {
          if (res.code == 1) {
            this.searchRoomData = res.data.rows;
          }
        });
      // 搜索大神
      this.$api.searchPlayerList(data).then((res) => {
        if (res.code == 1) {
          this.searchPlayerData = res.data.rows;
        }
      });
    },
    // 查看更多聊天室
    moreRoom() {
      this.$api
        .searchRoomList({ pageNum: 1, pageSize: 999, key: this.keyword })
        .then((res) => {
          // console.log("搜索聊天室", res);
          if (res.code == 1) {
            this.searchRoomData = res.data.rows;
          }
        });
    },
    //添加搜索记录
    getaddHistory() {
      this.$store.commit("appSearchHistoryAdd", {
        name: this.keyword,
      });
      this.searchList();
    },
    //删除搜索记录
    getdelHistory(id) {
      this.$store.commit("appSearchHistoryDel", {
        name: id,
      });
    },
    // 清空搜索
    keywordBtn(key, open) {
      this.keyword = "";
      this.appSearchHistory.splice(key, 1);
      this.getdelHistory(open);
      this.homeType = 1;
    },
    //清空搜索记录
    getcloseHistory() {
      this.$store.commit("appSearchHistoryClean");
      this.popupShow1656324721913 = false;
    },
    // 搜索事件 —— 回车
    searchChange() {
      this.homeType = 7;
      this.leftPitch = 7;
      this.getaddHistory();
    },
    // 首页切换
    pageSwitching(type) {
      this.tabIndex = type;
      this.homeType = type;
      this.homePages.pageNum = 1;
      if (type == 3) {
        this.roomList = [];
        this.hotRoomList();
        this.getReclassifyList();
      } else if (type == 2) {
        this.getDiscountConfig();
        this.getDisCountPlayerList();
      } else if (type == 4) {
        this.homeType = 4;
      } else {
        this.leftPitchChange(0);
        this.roomList = [];
        this.hotRoomList();
        this.getHotLabelList();
      }
      this.roomPage = { pageNum: 1, pageSize: "15", type: "hot" };
      this.homePages = { labelId: "", pageNum: "1", pageSize: "15" };
    },
    // 广场
    forumList() {
      var data = {
        pageNum: 1,
        pageSize: 15,
      };
      this.$api.forumList(data).then((res) => {
        console.log(res, "resres");
      });
    },
   async downGoShu(url){
      this.loadings=true
      let response = await fetch(url)
      // 内容转变成blob地址
      let blob = await response.blob()
      // 创建隐藏的可下载链接
      let objectUrl = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.style.display = "none";
      //地址
      a.href = objectUrl
      //修改文件名
      a.download = name
      // 触发点击
      document.body.appendChild(a)
      a.click()
      //移除
      URL.revokeObjectURL(a.href);
      this.loadings=false
    },
    // 折扣配置
    getDiscountConfig() {
      this.$api.discountConfig().then((res) => {
        console.log(res, "resres");
        this.DiscountConfigList = res.data;
      });
    },
    // 收藏列表
    getRoomCollection() {
      this.$api.roomCollection({ pageNum: 1, pageSize: 10 }).then((res) => {
        console.log(res, "resres");
      });
    },
    //折扣大神列表
    getDisCountPlayerList() {
      this.$api
        .disCountPlayerList({ pageNum: 1, pageSize: 10, type: 1 })
        .then((res) => {
          if (res.code == 1) {
            this.giveList = res.data;
            console.log(res.data, "sssssss");
            this.giveList.map((item) => {
              item.type = 2;
            });
          }
        });
      this.$api
        .disCountPlayerList({ pageNum: 1, pageSize: 10, type: 0 })
        .then((res) => {
          if (res.code == 1) {
            this.discontList = res.data;
            console.log(this.giveList, "this.giveListthis.giveList");
            this.discontList.map((item) => {
              item.type = 1;
            });
          }
        });
    },
    // 切换分类
    leftPitchChange(type) {
      this.leftPitch = type;
      this.manitoList = [];
      this.getInfo(type, true);
    },
    // 更多分类弹窗显示
    moreTopupShow() {
      this.moreType = !this.moreType;
      this.getAllSecondLabel();
    },
    // 显示
    showChange(type, id) {
      if (!localStorage.getItem("user-token")) {
        this.$router.push({ path: "/login" });
        this.$message.info("请先登录哦~");
        return;
      }
      this.getUserInfo();
      switch (type) {
        case "messageTopup": // 他人主页显示
          this.myMessageShow = true;
          break;
        case "others": // 他人主页显示
          this.manitoId = id;
          this.othersShow = !this.othersShow;
          break;
        case "ranking": // 排行榜显示
          this.ranking = !this.ranking;
          break;
        case "personal": //个人中心
          this.personalShow = !this.personalShow;
          break;
        case "helpindex": // 帮助中心
          this.helpindexShow = !this.helpindexShow;
          break;
        case "blindBox": // 盲盒
          this.blindBoxShow = !this.blindBoxShow;
          break;
        case "first": //首充
          this.firstShow = !this.firstShow;
          // this.walletTopupShow = !this.walletTopupShow;
          break;
        case "enter": //公会入驻
          this.enterShow = !this.enterShow;
          break;
        case "message": //首充
          this.myMessageShow = !this.myMessageShow;
          break;
        case "manito": //成为大神
          this.manitoShow = !this.manitoShow;
          break;
        default:
          break;
      }
    },

    // 跟随用户进入聊天室
    userRommSkip(open) {
      if (!localStorage.getItem("user-token")) {
        this.$router.push({ path: "/login" });
        this.$message.info("请先登录哦~");
        return;
      }
      if (open.onlineStatus == 2) {
        // 判断聊天室是否存在
        this.$api.getIsLock({ roomId: open.roomId }).then((res) => {
          console.log("aaaaaa", res, "resress");
          if (res.code == 1) {
            this.$router.push({
              path: "/someSingingHall",
              query: {
                roomId: open.roomId,
              },
            });
          } else {
            this.$message.info(res.msg);
          }
        });
      } else {
        this.showChange("others", open.id);
      }
    },
    // 聊天室
    rommSkip(item) {
      if (!localStorage.getItem("user-token")) {
        this.$router.push({ path: "/login" });
        this.$message.info("请先登录哦~");
        return;
      }
      //  return console.log("聊天室", item);
      // 判断聊天室是否存在
      this.$api.getIsLock({ roomId: item.id }).then((res) => {
        if (res.code == 1) {
          if (item.isLock == 1) {
            //房间上锁
            this.pitchRoom = item;
            return (this.roomLockShow = true);
          }
          // this.$api.enterRoom({ roomId: item.id }).then((res) => {
          //   console.log(res,'resres')
          //   if(res.code==0){
          //     this.$message.info(res.msg);
          //     return
          //   }

          // })
          this.$router.push({
            path: "/someSingingHall?roomId=" + item.id,
          });
        } else {
          this.$message.info(res.msg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.unreadNumStyle {
  width: 18px;
  height: 18px;
  font-size: 12px;
  background-color: red;
  text-align: center;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  left: 14px;
}
.search_btn {
  font-size: 14px;
  position: relative;
  right: 46px;
}
.searchReturn {
  font-size: 12px;
  color: #fff;
  width: 60px;
  text-align: center;
  position: absolute;
  right: 2%;
}
.icon_bg1 {
  background-image: url(../../image/room/q5.png) !important ;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  padding-left: 18px !important;
}
.icon_bg2 {
  background-image: url(../../image/room/q2.png) !important ;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  padding-left: 18px !important;
}
.enter_box {
  position: relative;
  display: flex;
  background: #fff;
  .enter {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(234, 234, 234, 1);
    border-radius: 5px;
    margin: 0 5px 0 0;
    z-index: 100;
    display: flex;
    font-size: 30px;
    justify-content: center;
    align-items: center;
    background: rgba(234, 234, 234, 1);
    //跳跃的指示符
    .tips {
      height: 30px;
      width: 1px;
      background-color: #000;
      animation: fade 1000ms infinite;
    }
  }
  .int {
    border: none;
    display: inline-block;
    width: 100%;
    height: 50px;
    position: absolute;
    z-index: 999;
    opacity: 0;
  }
}
.wheat_remind {
  width: 522px;
  height: 285px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 4px;
  .wheatRemind_img {
    width: 80px;
    height: 80px;
    margin-top: 40px;
    border-radius: 50%;
  }
  .wheatRemind_apply {
    width: 176px;
    height: 40px;
    background: rgba(255, 221, 0, 1);
    margin-top: 40px;
    color: #333;
    border-radius: 4px;
  }
  .salary-container {
    padding: 30px;
  }
}

.tuichu {
  padding: 1px 2px;
  margin-left: 10px;
  cursor: pointer;
}
.tuichu:hover {
  border-radius: 8px;
  background-color: rgba($color: #fff, $alpha: 0.4);
}
.quitPopUp_box {
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 14px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  .quit_box {
    width: 500px;
    height: 280px;
    background: #fff;
    border-radius: 4px 4px 4px 4px;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    .quit_text {
      font-size: 16px;
      color: #666;
      margin: 60px 0;
    }
    .quit_btn {
      color: #333;
      font-weight: 600;
      padding: 15px 50px;
      border-radius: 8px;
      background: #fff6e5;
    }
  }
}
// 选择器
.filter_select {
  width: 106px;
  margin-right: 10px;
  color: #6a6a94 !important;
}
.filter_select /deep/.el-select,
.filter_select /deep/.el-input__inner {
  color: #6a6a94;
  background: transparent !important;
  border: 0.5px solid #323655 !important;
  border-radius: 4px;
}

.filter_select::placeholder {
  font-size: 14px;
  color: #6a6a94;
}
// 下载弹窗
.downloadApp {
  padding: 24px;
  color: #fff;
  font-size: 12px;
  justify-content: space-around;
  position: absolute;
  top: 10%;
  right: 14%;
  background: #fff;
  border-radius: 8px;
  z-index: 2;
  .downloadApp_img {
    width: 128px;
    height: 128px;
    margin-top: 8px;
  }
}
.downloadApp::after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  top: -5%;
  right: 12%;
  border-bottom: 10px solid #fff;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
//更多弹窗
.morePopup {
  width: 580px;
  height: 717px;
  position: absolute;
  top: 15%;
  left: 19%;
  padding: 0 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 3px 6px 1px rgba(108, 91, 91, 0.3);
  border-radius: 8px 8px 8px 8px;
  z-index: 9999;
  overflow-y: scroll;
  .mpreTitle {
    margin-top: 20px;
    font-size: 16px;
    color: #333;
    margin-bottom: 12px;
  }
  .mpre_box {
    display: flex;
    flex-wrap: wrap;
    .more_item {
      width: 76px;
      height: 90px;
      color: #333;
      font-size: 12px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .more_img {
        width: 40px;
        height: 40px;
        margin-bottom: 8px;
      }
    }
    .more_item:hover {
      color: #333;
      border-radius: 8px;
      background: rgba(255, 222, 50, 0.22);
    }
  }
}
.title {
  width: 1400px;
  height: 60px;
  font-size: 12px;
  color: #333;
  background: linear-gradient(60deg, #fee338 0%, #ffeb72 100%);
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  filter: blur(undefinedpx);

  .logo {
    width: 120px;
    height: 33px;
  }
  .pitchOn {
    color: #333333;
    font-size: 22px;
    font-weight: 600;
    position: relative;
  }
  .home_tabItem:hover {
    position: relative;
    animation: textColor 1000ms infinite;
  }
  @keyframes textColor {
    100% {
      color: #333333;
    }
  }
  .home_tabItem {
    color: #5c5736;
  }
  .home_tabItem:hover:after {
    content: "";
    width: 29px;
    height: 4px;
    display: inline-block;
    background: #333333;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -50%;
  }
  .pitchOn:after {
    content: "";
    width: 29px;
    height: 4px;
    display: inline-block;
    background: #333333;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -50%;
  }
  .tab {
    width: 300px;
    display: flex;
    justify-content: space-around;
    font-size: 18px;
    font-weight: 400;
    color: #d0d1d3;
    cursor: pointer;
  }
  .search {
    width: 240px;
    height: 40px;
    color: #333333;
    margin-left: 130px;
    background: rgba(185, 161, 0, 1) !important;
    border-radius: 20px 20px 20px 20px;
  }
  .search /deep/.el-input__inner {
    background-color: transparent;
    border: none;
  }
  .search .el-input__inner:focus {
    outline: #333333 !important;
  }
  .advertising {
    width: 106px;
    height: 72px;
    overflow: hidden;
    margin: 0 42px 0 28px;
    position: relative;
    top: -14px;
  }
  .user {
    cursor: pointer;
    .headImg {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 10px;
      overflow: hidden;
    }
    .name {
      margin-right: 7px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .help {
    align-items: center;
  }
  .help,
  .download {
    margin-right: 20px;
    align-items: center;
    cursor: pointer;
    > img {
      margin-right: 7px;
    }
  }
  .iconTit {
    width: 22px;
    height: 22px;
  }
}
.content {
  width: 1400px;
  height: 740px;
  background: #f5f5f5;
  border-radius: 0px 0px 16px 16px;
  opacity: 1;
  display: flex;
  .left2 {
    width: 88px;
    height: 720px;
    margin: 10px;
    padding: 0 10px;
    display: flex;
    text-align: center;
    flex-direction: column;

    font-size: 12px;
    font-weight: 400;
    color: #333;
    background: #faf8ec;
    border-radius: 8px 8px 8px 8px;
    .left-item {
      padding: 10px 0;
      object-fit: cover;
      cursor: pointer;
    }
    .left-item:hover {
      padding: 10px 0;
      color: #fff;
      background: #28293e;
      border-radius: 8px;
      cursor: pointer;
    }
    .left-item-pitch {
      color: #fff;
      background: #28293e;
      border-radius: 8px;
      cursor: pointer;
    }
    .leftImg {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
  .left1 {
    width: 88px;
    height: 720px;
    margin: 10px;
    padding: 0 10px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    background: #faf8ec;
    border-radius: 8px 8px 8px 8px;
    .left-item {
      padding: 10px 0;
    }
    .left-item:hover {
      padding: 10px 0;
      color: #fff;
      background: #28293e;
      border-radius: 8px;
    }
    .left-item-pitch {
      color: #fff;
      background: #28293e;
      border-radius: 8px;
    }
    .leftImg {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
  .left {
    width: 88px;
    height: 720px;
    margin: 10px;
    padding: 0 10px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: space-around;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    background: #faf8ec;
    border-radius: 8px 8px 8px 8px;
    .left-item {
      padding: 10px 0;
    }
    .left-item:hover {
      padding: 10px 0;
      color: #fff;
      background: #28293e;
      border-radius: 8px;
    }
    .left-item-pitch {
      color: #fff;
      background: #28293e;
      border-radius: 8px;
    }
    .leftImg {
      width: 40px;
      height: 40px;
      object-fit: cover;
    }
  }
  .center_box {
    width: 100%;
    margin-bottom: 10px;
    position: relative;
    .conter_title {
      margin: 20px 0 0 10px;
      color: #333;
      font-size: 18px;
      font-weight: bold;
      position: relative;
      z-index: 3;
      > p {
        display: inline-block;
        width: 59px;
        height: 11px;
        background: linear-gradient(
          282deg,
          rgba(255, 255, 255, 0.22) 0%,
          #fee338 100%
        );
        border-radius: 9px 9px 9px 9px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
    }
    .onLine {
      width: 44px;
      height: 22px;
      position: absolute;
      top: 10px;
      right: 7px;
      color: #fff;
      background: rgba($color: #000000, $alpha: 0.4);
      border-radius: 11px 11px 11px 11px;
    }
    .onLineIcon {
      width: 6px;
      height: 6px;
      background: #3ae51a;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
    }
    .chat {
      width: 65px;
      height: 22px;
      position: absolute;
      top: 10px;
      right: 0;
      color: #fff;
      border-radius: 11px 0px 0px 11px;

      background: linear-gradient(90deg, #5ac7ff 0%, #9374f4 100%);
    }
    .recommend_box {
      width: 100%;
      height: 650px;
      padding: 0px 0px 0 15px;
      flex-wrap: wrap;
      overflow-y: scroll;
      box-sizing: border-box;
      cursor: pointer;
      .recommend_item {
        width: 184px;
        height: 270px;
        font-size: 12px;
        margin-right: 10px;
        margin-bottom: 15px;
        position: relative;
        color: #878795;
        border-radius: 8px;
        background: #fff;
        overflow: hidden;

        .topLabel {
          width: 52px;
          height: 22px;
          position: absolute;
          top: 0;
          left: 0;
          color: #fff;
          background: linear-gradient(109deg, #e17616 0%, #ff9b41 100%);
          border-radius: 8px 2px 11px 2px;
        }
        .topLabel2 {
          width: 34.85px;
          height: 34.85px;
          position: absolute;
          top: 4px;
          left: 4px;
        }
        .chatOfTime {
          height: 24px;
          z-index: 1;
          background: linear-gradient(
            90deg,
            #3c3c3c 0%,
            rgba(255, 255, 255, 0.71) 100%
          );
          border-radius: 12px 4px 6px 12px;
          position: absolute;
          left: 110px;
          transform: translateY(-70%);
          display: flex;
          align-items: center;
          margin-right: 10px;
          > p {
            font-size: 16px;
            color: #212121;
            font-weight: bold;
          }

          .chatOfTimeImg {
            width: 16px;
            height: 16px;
            margin: 0 4px;
          }
        }

        .roomGif {
          position: absolute;
          top: 48%;
        }
        .item_text {
          padding: 10px;
          .item_name {
            font-size: 14px;
            color: #333;
          }
          .address_icon {
            width: 14px;
            height: 14px;
          }
          .bottm_style1 {
            align-items: center;
            justify-content: space-between;
          }
          .moneyType {
            color: #ff7268;
            font-size: 18px;
            margin-right: 4px;
          }
        }
      }
    }
    .item_topBox {
      width: 180px;
      height: 180px;
      overflow: hidden;
    }
    .item_top {
      width: 180px;
      height: 180px;
      border-radius: 8px;
      // transform: all 0.5s;
      object-fit: cover;
      transition-property: all 0.3s !important;
    }
    .item_top:hover {
      transition: all 0.5s;
      transform: scale(1.2);
    }
    //搜索样式
    .search_boxroll {
      width: 100%;
      height: 640px;
      overflow-y: scroll;
    }
    .serach_box {
      font-size: 14px;
      color: #fff;
      margin: 20px 0 0 10px;
      .serach_img {
        width: 14px;
        height: 14px;
        margin-left: 6px;
      }
      .serach_item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 8px;
        margin: 0 6px 0 0;
        border-radius: 4px;
        background: rgba(37, 43, 74, 1);
      }
    }
    //搜索聊天室
    .serachChatRoom_title {
      font-size: 16px;
      display: flex;
      margin: 0px 0 21px 10px;
      .serachChatRoom_Btn {
        background: #999999;
        border-radius: 4px 4px 4px 4px;
        padding: 8px 10px;
        text-align: center;
        display: flex;
      }
    }

    .serachChatRoom_box {
      width: 980px;
      margin: 20px 0 20px 10px;
      overflow-y: scroll;
      .serachChatRoom_item {
        width: 180px;
        height: 250px;
        color: #333333;
        font-size: 16px;
        margin: 0 15px 15px 0;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 8px;
        background: #ffffff;
        overflow: hidden;
        .roomType_icon {
          font-size: 8px;
          position: absolute;
          left: 0px;
          top: 0;
          padding: 2px 4px;
          border-radius: 4px 0 0 0;
        }

        .serachChatRoom_leftIcon {
          position: absolute;
          left: 0;
          top: 0;
        }
        .serachChatRoom_img {
          width: 114px;
          height: 114px;
          border-radius: 50%;
          object-fit: cover;
        }
        .serachChatRoom_text1 {
          width: 180px;
          margin: 10px 0;
        }
        .serachChatRoom_text2 {
          font-size: 12px;
          margin-bottom: 14px;
          color: #333333;
          padding: 3px 8px;
          border-radius: 14px;
          background: rgba(255, 231, 76, 1);
        }
        .serachChatRoom_text3 {
          font-size: 10px;
          color: #f68229;
          > img {
            width: 16px;
            height: 16px;
          }
        }
        .userData_head {
          width: 100%;
          height: 180px;
          position: relative;
          top: -10px;
        }
        .age_sexIcon {
          padding: 2px 4px;
          background: rgba($color: #fff, $alpha: 0.12);
          border-radius: 18px;
          margin-left: 6px;
          .seximg_icon {
            width: 9.6px;
            height: 9.6px;
            margin-right: 4px;
          }
        }
      }
    }
  }
  .topLeftIcon {
    width: 50px;
    height: 20px;
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
  }
  .topLeftIconPei {
    padding: 0px 7px;
    position: absolute;
    object-fit: cover;
    left: 0;
    top: 0;
    background: linear-gradient(90deg, #f5dbac 0%, #fff3bf 100%);
    border-radius: 8px 0px 0px 0px;
    display: flex;
    align-items: center;
  }
  .right {
    width: 300px;
    height: 740px;
    font-size: 14px;
    position: relative;
    background: #ffffff;
    opacity: 1;
    .rightTopImg {
      width: 100%;
      height: 74px;
      object-fit: cover;
    }
    .chatRoomList {
      width: 300px;
      height: 70%;
      overflow-y: scroll;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
    }

    .chatRoomItem_text {
      color: rgba($color: #333, $alpha: 0.8);
      font-size: 12px;
      justify-content: space-between;
    }
    .chatRoomItem {
      width: 280px;
      height: 90px;
      padding: 8px;
      border-radius: 8px;
      margin-bottom: 10px;
      color: #333;
      font-size: 14px;
      font-weight: 400;
      box-sizing: border-box;

      background: linear-gradient(
        92deg,
        rgba(255, 221, 0, 0.3) 0%,
        rgba(255, 162, 0, 0.2) 100%
      );
      position: relative;
      .roomType_icon {
        font-size: 8px;
        position: absolute;
        left: 0px;
        top: 0;
        padding: 2px 4px;
        border-radius: 4px 0 0 0;
      }
      .itemImg {
        width: 74px;
        height: 74px;
        margin-right: 8px;
        border-radius: 8px;
        object-fit: cover;
      }
      .listImg1 {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-right: 4px;
      }
      .listImg2,
      .listImg3,
      .listImg4,
      .listImg5 {
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }
      .listImg3 {
        position: relative;
        left: -6px;
      }
      .listImg4 {
        position: relative;
        left: -12px;
      }
      .listImg5 {
        position: relative;
        left: -18px;
      }
    }
    .chatRoomItem0:hover {
      background: linear-gradient(#7c35ee, #5f35d0);
    }
    .chatRoomItem1:hover {
      background: linear-gradient(#367aef, #48d6f8);
    }
    .chatRoomItem2:hover {
      background: linear-gradient(#f2428a, #9e13f1);
    }
    .rankingTab {
      width: 230px;
      color: #333333;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .rankingPitch {
      color: #333;
      font-size: 16px;
      position: relative;
      cursor: pointer;
    }
    .rankingPitch::after {
      content: "";
      width: 24px;
      height: 4px;
      border-radius: 4px;
      display: inline-block;
      position: absolute;
      bottom: -10px;
      left: 35%;
      background: linear-gradient(90deg, #ffdd00 0%, #ffa200 100%);
      cursor: pointer;
    }
    .rankingImage {
      width: 32px;
      height: 32px;
      margin-left: 12px;
    }
    .ranking {
      width: 85px;
      height: 32px;
      color: #fff;
      font-size: 12px;
      background: url(../../static/11.png) no-repeat;
      > img {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }
    .baseTab {
      width: 100%;
      height: 60px;
      color: #131635;
      background: #ffffff;
      position: absolute;
      bottom: 0;
      box-shadow: 0px -10px 12px 1px rgba(65, 16, 16, 0.16);
      border-radius: 15px 0px 0px 0px;
      opacity: 1;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    .baseTab_item {
      display: flex;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      cursor: pointer;
    }
    .baseTab_item:hover {
      color: #ff8b7d;
    }
    .baseTabIcon1 {
      width: 28px;
      height: 28px;
      display: inherit;
    }
    .baseTabIcon2 {
      width: 24px;
      height: 24px;
      display: inherit;
      
    }
  }
}
.broadcast_right {
  position: absolute;
  top: 50%;
  right: 15%;
  overflow: hidden;

  .broadcast_rightItem {
    width: 314.93px;
    height: 60px;
    background: url(../../image/popout/2.png) no-repeat;
    animation-name: rightDynamic;
    animation-iteration-count: 1;
    animation-duration: 2s;

    .fullBand_img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #fff;
      position: relative;
      left: 50px;
    }

    .fullBand_text {
      width: 120px;
      font-size: 8px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-content: center;
      margin-left: 56px;
    }

    .fullBand_ben {
      width: 44px;
      height: 21px;
      color: #f68229;
      font-size: 12px;
      background: #ffffff;
      text-align: center;
      line-height: 21px;
      border-radius: 28px;
    }
  }
}

.topicNameColor {
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 10px;
  color: #66381e;
}
.textOv {
  width: 150px;
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 使用省略号表示被隐藏的部分 */
}
/deep/.el-input__inner {
  border: 1px solid transparent;
  background-color: rgba(255, 255, 255, 0.2);
}
/deep/.el-input__inner::placeholder {
  color: #333;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  display: none;
}
/deep/.el-select-dropdown__wrap,
/deep/.el-scrollbar__wrap {
  margin-bottom: -18px !important;
}
/deep/ .el-tree-node__label {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
/deep/ .el-tree-node__content {
  height: 48px;
  line-height: 48px;
}
</style>
