<template>
  <!-- 倒计时组件 -->
  <div>您的剩余时间 <b class="time_style">{{day}}</b> 天 <b class="time_style">{{hr}}</b> 时 <b class="time_style">{{min}}</b> 分</div>
</template>
<script>
export default {
name: '',
props:{
  endTime:{
    required: true, //必传属性
    default: 0, //默认属性
  }
},
components: {},
data() {
    return {
      day: 0,
      hr: 0,
      min: 0,
      sec: 0,
      endTimeData:this.endTime,
      countDown:null
    }
},

mounted() {
    window.clearTimeout(this.countDown)
    this.countdown()
},
beforeUnmount(){
},
methods: {
    // 倒计时方法
    countdown() {
      // 目标日期时间戳
      // const end = Date.parse(new Date('2023-02-25 03:59:23'))
      // 当前时间戳
      const now = Date.parse(new Date())
      // 相差的毫秒数
      const msec = this.endTimeData - now
        // console.log('msec-----',msec)
      if (msec < 0) return

      // 计算时分秒数
      let day = parseInt(msec / 1000 / 60 / 60 / 24)
      let hr = parseInt((msec / 1000 / 60 / 60) % 24)
      let min = parseInt((msec / 1000 / 60) % 60)
      let sec = parseInt((msec / 1000) % 60)
      // 个位数前补零
      this.day = day > 9 ? day : '0' + day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
  console.log('------------',this.day,this.hr,this.min,this.sec);

      // 赋值
      const that = this
      if (msec > 0) {
        //倒计时结束关闭订单
        // if (min < 0 && sec < 0) {
        //   return
        // }
        // 一秒后递归
       this.countDown = setTimeout(function () {
          that.countdown()
        }, 1000)
      }
    },
},
};
</script>
<style lang='scss'>
.time_style{
  color: #000;
  display: inline-block;
  padding: 2px 2px;
  border-radius: 4px;
  background-color: rgba(255, 221, 0, 1);
}
</style>