<template>
  <!-- 访客列表 -->
  <div class="popUp_box">
    <div class="pop_up" style="width: 902px; height: 598px;">
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14">访客信息</div>
        <img src="../../../static/quit.png" alt="" @click="darkChange()" />
      </div>
      <!-- 头部end -->
      <div class="solutionRemind_box flex-dir">
        <div class="font-12" style="color:rgba(65, 119, 221, 1);margin:20px 0">
          仅展示本次开播访客信息
        </div>
        <div class="llacingCont_table">
          <!-- 头部表头 -->
          <div class="flex-aling llacingCont_tr">
            <div class="llacingCont_heder flex-content" style="width:80px">序号</div>
            <div class="llacingCont_heder flex-content" style="width:250px">用户信息</div>
            <div class="llacingCont_heder flex-content" >用户ID</div>
            <div class="llacingCont_heder flex-content">时间</div>
          </div>
          <!-- 下单内容 -->
          <div class="flex-aling llacingCont_tr" v-if="(visitorList.length == 0)" style="height:100px;height: 200px;justify-content: center;">
            暂无数据
          </div>
          <div class="flex-aling llacingCont_tr" v-for="(item,index) in visitorList" :key="index" style="color:#333">
            <div class="llacingCont_td flex-content" style="width:80px;color:#333">{{(index + 1)}}</div>
            <div class="llacingCont_td flex-content"  style="width:250px;color:#333">
              <img :src="item.avatar" alt="" class="acatarImg"> <span>{{item.nickname}}</span>
              <div v-if="item.sex==0" style="margin-left:14px">
                <img  src="../../../image/message/32.png" alt="" class="ageImg" />{{item.age}}
              </div>
              <div v-if="item.sex==1" style="margin-left:14px">
                <img  src="../../../image/message/24.png" alt="" class="ageImg" />{{item.age}}
              </div>
             
            </div>
            <div class="llacingCont_td flex-content" style="color:#333">{{item.userNo}}</div>
            <div class="llacingCont_td flex-content" style="color:#333">{{item.inTime}}</div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props:['liveStreamId'],
  data() {
    return {
      placingheader: ["序号", "用户信息", "用户ID",'时间'],
      visitorList:[],//访客列表
    };
  },
  mounted(){
    this.roomManagersInfo()
  },
  methods: {
    //获取管理列表
    roomManagersInfo(){
      this.$api.roomVisitorInfo({liveStreamId:this.liveStreamId}).then(res=>{
        if(res.code == 1){
          this.visitorList = res.data.rows
        }
      })
    },
    darkChange() {
      this.$emit("visitorListChange");
    },
  },
};
</script>
<style lang='scss'>
// 弹窗背景
.popUp_box {
  width: 1920px;
  height: 1080px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 1;
  .pop_up {
    background: #fff;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
  }
  .llacingCont_table{
    width: 633px;
    height: 430px;
    overflow-y:auto ;
    
    border: 1px solid #34394F;
  }
  .llacingCont_heder{
    color: #333;
  }
  .acatarImg{
    width: 28px;
    height: 28px;
    margin-right: 5px;
    border-radius: 50%;
  }
  .itemSex{
    color: #fff;
    background: #4e5169;
    padding: 0 2px;
    margin: 0 5px;
    border-radius: 8px;
  }
  .ageImg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
}
</style>