<template>
  <!-- 音乐播放组件 -->
<div>
  <div class="music_page">
    <!-- 音乐列表 -->
    <div class="list_box">
      <!-- 音乐列表 -->
      <div v-if="musicType == 1" style="width: 100%">
        <div class="list_top">
          <div>音乐列表</div>
          <div>
            <img
              src="../../../image/room/53.png"
              alt=""
              style="margin-right: 12px"
              @click.stop="musicType = 3"
            />
            <img
              src="../../../image/room/54.png"
              alt=""
              @click.stop="musicType = 2;mp3uploadClick()"
            />
          </div>
        </div>
        <div class="music_box">
          <div
            class="music_item flex-aling"
            v-for="(item, index) in musicList"
            :key="index"
            @click.stop="musicChange(item)"
          >
            <img
              src="../../../image/room/63.png"
              alt=""
              style="width: 8px; height: 10px;margin-right:6px"
              v-if="(pathType.id == item.id)"
            />
            <div class="musicName text-cut" :style="{color:pathType.id == item.id ? '#593aaa':''}">
              {{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <!-- 编辑音乐 -->
      <div v-if="musicType == 2" style="width: 100%">
        <div class="list_top">
          <div @click="musicType = 1">取消</div>
          <div>本地音乐</div>
          <div>
            <img
              src="../../../image/room/53.png"
              alt=""
              style="margin-right: 12px"
            />
            <img src="../../../image/room/54.png" alt="" @click.stop="mp3uploadClick()" />
          </div>
        </div>
        <div class="flex-content list_text">本地上传音乐如有侵权与平台无关</div>
        <el-input
          placeholder="搜索音乐名称"
          v-model="searchValue"
          class="search_input"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
      <!-- 删除音乐 -->
      <div v-if="musicType == 3" style="width: 100%">
        <div class="list_top">
          <div @click="musicType = 1">取消</div>
          <div>批量操作</div>
          <div style="color: #c6193f">删除</div>
        </div>
      </div>
    </div>
    <!-- 音乐控制 -->
    <div class="music flex-aling">
      <!-- <audio
        :src="musicUrl"
        controls
        autoplay
        @play="play"
        @pause="pause"
      ></audio> -->
      <img src="../../../image/room/64.png" alt="" class="music_btn" @click.stop="musicChenge(2)" v-if="(playBack == 1)" />
      <img src="../../../image/room/65.png" alt="" class="music_btn" @click.stop="musicChenge(1)" v-if="(playBack == 2)" />
      <div>
        <div class="font-12">{{pathType.name}}</div>
        <div class="flex">
          <!-- 音量控制 -->
          <div class="volume_slider flex-aling">
            <img src="../../../image/room/66.png" alt="">
            <el-slider v-model="volumeNum" class="musicElSlider" @change.stop="sliderChange()"></el-slider>
            <img src="../../../image/room/67.png" alt="" class="margin_r4" @click.stop="nextSong()" />
            <img src="../../../image/room/68.png" alt="" class="margin_r4" />
            <img src="../../../image/room/69.png" alt="" class="margin_r4" @click.stop="closebox()" />
          </div>
        </div>
      </div>
    </div>
    <el-upload
        action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
        :limit="1"
        :show-file-list="false"
        list-type="picture-card"
        :on-success="reportImgSuccess"
        :before-upload="beforeAvatarUpload"
        style="display: none;"
        accept=".mp3"
        ref="mp3upload"
      >
    </el-upload>
  </div>
</div>
</template>
<script>
export default {
name: '',
components: {},
data(){
return {
  audio:{
        src:'',//音乐
        volume:0,//音量
      },//js 音乐播放器   play播放 pause暂停
      musicType: 1,
      musicList: [], //音乐列表
      pathType:{},
      playBack:1,// 1播放 2暂停
      volumeNum:'',
      searchValue:'',
      musicData:{
        musicName:'',//	歌名	
        audioPath:'',//	音频地址
        audioLength:'',//	时长
        }
}
},
mounted(){
  this.getMyMusics()
},
methods: {
  // 图片上传
    reportImgSuccess(response, file, fileList) {
      if(response.code == 1){
        this.musicData.audioPath = response.data.url
        
        this.$api.addMyMusic( this.musicData).then(res=>{
          if(res.code == 1){
            this.getMyMusics()
            this.$message.success(res.msg)
          }else{
            this.$message.error(res.msg)
          }
        })
      }else{
        this.$message.info(res.msg)
      }
    },
    // 获取文件时长
    beforeAvatarUpload(file) {
      this.musicData.musicName = file.name
      let url = URL.createObjectURL(file);
      let audioElement = new Audio(url);
      audioElement.addEventListener("loadedmetadata", (_event) => {
        let playTime = audioElement.duration;
        this.musicData.audioLength = parseInt(playTime);
      });
    },
  mp3uploadClick(){
    // this.$refs.mp3upload.submit()
    this.$refs.mp3upload.$children[0].$refs.input.click();
  },
  // 关闭页面
  closebox(){
    this.$emit('closeMusic')
  },
  // 下一曲
  nextSong(){
    this.musicList.forEach((item,index)=>{
      if(this.pathType.id == item.id){
        return this.pathType = this.musicList[index]
      }
    })
  },
  // 切换音乐
  musicChange(i){
    this.pathType = i 
    this.playBack = 1
     //结束自定义音频流
    let over = this.$RCVoiceRoomLib.stopCreateTrack()
    over.then(res=>{
      this.$RCVoiceRoomLib.createTrack(i.audioPath).then(res=>{
      })
    })
      // setTimeout(()=>{
      //   this.$RCVoiceRoomLib.createTrack(i.audioPath).then(res=>{
      //   console.log('音乐播放',res);
      // })
      // },300)
    },
    // 音量控制
    sliderChange(){
      this.audio.volume = this.volumeNum
    },
    // 播放暂停
    musicChenge(type){
      this.$RCVoiceRoomLib.stopCreateTrack() //结束自定义音频流
      if(type == 1){
        // this.audio.play()
      }else{
        // this.audio.pause()
      //   this.$RCVoiceRoomLib.stopCreateTrack().then(res=>{
      //   console.log('暂停音乐',res);
      // })
      }
      this.playBack =type
      // this.audio.play()
    },
    // 获取音乐
    getMyMusics() {
      this.$api.getMyMusics().then((res) => {
        if (res.code == 1) {
          this.musicList = res.data;
        }
      });
    },
},
};
</script>
<style lang='scss' scoped>
.music_page{
  position: absolute;
  left: -230px;
  z-index:1;
  .music_box {
    .music_item {
      padding: 8px 10px;
    }
    .musicName {
      width: 100px;
      height: 18px;
      overflow: hidden;
    }
  }
  .music_page {
    position: absolute;
    left: -240px;
    color: #fff;
  }
  .music {
    width: 226px;
    height: 48px;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 24px;
    .music_btn {
      width: 32px;
      height: 32px;
      margin: 0 10px;
    }
    .volume_slider {
      width: 160px;
      height: 30px;
    }
  }
  .list_box {
    width: 226px;
    height: 335px;
    position: absolute;
    top: -345px;
    padding: 12px 10px;
    background: rgba(0, 0, 0, 0.8);
    box-shadow: 0px 0px 6px 1px rgba(196, 225, 255, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    .list_top {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .list_text {
      font-size: 10px;
      color: #8e92b1;
      margin: 12px 0;
    }
  }
  .musicElSlider {
    width: 100%;
    padding-left: 10px;
  }
  .musicElSlider /deep/.el-slider__button {
    border: 0;
    width: 12px;
    height: 12px;
    background-color: #9fa3ff;
  }
  .musicElSlider /deep/.el-slider__bar {
    background-color: #9fa3ff;
  }
  .search_input /deep/.el-input__inner {
    width: 203px;
    height: 24px;
    border: 0;
    color: #fff;
    background: #363659;
  }
  .search_input /deep/ .el-input__icon {
    width: 12px;
    height: 12px;
    line-height: 24px;
  }
  .search_input/deep/.el-input__inner::placeholder {
    color: #fff !important;
  }
}
</style>