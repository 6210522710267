
// import Login from "../components/Login.vue";
import Login from "../pages/login/login.vue"; //登录页
import Register from "../pages/login/register.vue" //注册页
import bindingPhone from "../pages/login/bindingPhone.vue" //手机号绑定
import resetPasswords from "../pages/login/resetPasswords.vue" //重置密码
import someSingingHall from "../pages/chatRoom/someSingingHall.vue" //点唱厅
import walletTopup from "../pages/personalCenterAll/walletTopup.vue" //充值
import Kongbai from "../pages/login/kongbai.vue" //点唱厅
// import Home from "../components/Home.vue";
import manito from "../pages/association/manito.vue"; //认证大神
// import newManito from "../pages/association/newManito.vue"; //认证大神
import VueRouter from 'vue-router'
import Home from "../pages/homePage/home.vue"
const router = new VueRouter({
  // mode: 'hash',
  // eslint-disable-next-line no-undef
  base: __dirname,
  routes: [{
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: Home
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/register',
      name: 'register',
      component: Register
    },
    {
      path: '/guangchang',
      name: 'guangchang',
      component: Register
    },
    {
      path: '/bindingPhone',
      name: 'bindingPhone',
      component: bindingPhone
    },
    {
      path: '/resetPasswords',
      name: 'resetPasswords',
      component: resetPasswords
    },
    {
      path: '/someSingingHall',
      name: 'someSingingHall',
      component: someSingingHall
    }, //点唱厅
    {
      path:'/kongbai',
      name: 'kongbai',
      component: Kongbai
    },
    {
      path: '/manito',
      name: 'manito',
      component: manito
    }, //认证·大神
    // {
    //   path: '/newManito',
    //   name: 'newManito',
    //   component: newManito
    // }, //认证·大神
    {
      path: '/walletTopup',
      name: 'walletTopup',
      component: walletTopup
    }, //钱包充值弹窗
    // { path: '/bar/:id', name: 'bar', component: HelloWorld }
  ]
})

export default router;