<template>
  <div class="roomPage">
    <!-- 个人中心---房间管理 -->
    <div class="title">语聊厅</div>
    <div class="roomList_box">
      <div
        class="room_item"
        v-for="(item, index) in chatRoom"
        :key="index"
        @click.stop="skipChatRoom(item)"
      >
        <div class="flex">
          <img :src="item.logo" alt="" class="roomItem_head" />
          <div>
            <div class="flex" style="color: #1d223b;width:350px">
              {{ item.name }}
              <div v-if="item.userRole == 0" class="room_role">房主</div>
            </div>
            <p class="font-12" style="color: #8e92b1">ID：{{ item.roomId }}</p>
            <div class="flex-aling" style="margin-top: 6px">
              <img :src="item.avatar" alt="" class="roomItem_userHead" />
              <p style="color: #8e92b1">{{ item.nickname }}</p>
            </div>
          </div>
        </div>
        <!-- <img src="../../static/114.png" alt="" class="roomItem_icon" /> -->
      </div>
    </div>
    <!-- 开黑房 -->
    <div class="title">开黑房</div>
    <div class="roomList_box">
      <div
        class="room_item"
        v-for="(item, index) in darkRoomList"
        :key="index"
        @click.stop="skipChatRoom(item)"
      >
        <div class="flex">
          <img :src="item.logo" alt="" class="roomItem_head" />
          <div>
            <div class="flex" style="color: #1d223b">
              {{ item.name }}
              <div v-if="item.roleName" class="room_role">
                {{ item.roleName }}
              </div>
            </div>
            <p class="font-12" style="color: #8e92b1">ID：{{ item.roomId }}</p>
            <div class="flex-aling" style="margin-top: 6px">
              <img :src="item.avatar" alt="" class="roomItem_userHead" />
              <p style="color: #8e92b1">{{ item.nickname }}</p>
            </div>
          </div>
        </div>
        <!-- <img src="../../static/114.png" alt="" class="roomItem_icon" /> -->
      </div>
    </div>
    <!-- 创建开黑房 -->
    <div
      class="establish_btn flex-content"
      @click="establishRoomShow = true"
      v-if="roomValue == 1"
    >
      创建开黑房
    </div>
    <!-- 创建房间 -->
    <div class="popup_box" v-if="establishRoomShow">
      <div class="establishRoom_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14  flex-aling">创建开黑房</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="establishRoomShow = false"
          />
        </div>
        <!-- 头部end -->
        <div class="establish_box">
          <div class="flex-aling">
            <div
              class="establishTab_item flex-content"
              :class="labelType == 1 ? 'establishTab_puth' : ''"
              @click="labelChange(1)"
            >
              游戏开黑
            </div>
            <div
              class="establishTab_item flex-content"
              :class="labelType == 2 ? 'establishTab_puth' : ''"
              @click="labelChange(2)"
            >
              娱乐
            </div>
          </div>
          <!-- 二级分类标签 -->
          <div class="skill_box">
            <div
              class="skill_item flex-dir"
              v-for="(item, index) in labelType == 1
                ? gameLabels
                : amusementLabels"
              :key="index"
              :class="twolabel.id == item.id ? 'skill_pitch' : ''"
              @click="secondLevelChange(item)"
            >
              <img :src="item.logo" alt="" class="label_img" />
              <div class="itemText">{{ item.name }}</div>
            </div>
          </div>
          <!-- 二级分类标签 -->

          <div
            class="establish_formBox"
            :style="{ height: labelType == 2 ? '200px' : '' }"
          >
            <el-form ref="form" :model="roomform" label-width="130px">
              <el-form-item label="房间封面:">
                <img :src="defaultImg" alt="" class="cover_img" />
                <div
                  class="font-12"
                  style="
                    color: #8586a9;
                    height: 15px;
                    transform: translateY(-15px);
                  "
                >
                  默认头像，不可更换
                </div>
              </el-form-item>
              <el-form-item label="房间名称:">
                <el-input
                  v-model="roomform.name"
                  maxlength="10"
                  placeholder="请输入房间名称 (10字内)"
                  style="width: 320px"
                ></el-input>
              </el-form-item>
              <template v-if="labelType == 1">
                <el-form-item label="游戏区服:">
                  <el-select
                    v-model="roomform.districtId"
                    placeholder="选择区服"
                  >
                    <el-option
                      v-for="item in gameDistricts"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="游戏段位:">
                  <el-select v-model="roomform.levelId" placeholder="选择段位">
                    <el-option
                      v-for="item in gameLevels"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="对局模式:">
                  <el-select v-model="roomform.modeId" placeholder="选择对局模式">
                    <el-option
                      v-for="item in gameMode"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开黑人数:">
                  <el-select v-model="roomform.numLimit" placeholder="选择人数">
                    <el-option
                      v-for="item in gamePersonNum"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-form>
          </div>
          <div
            class="establish_formbtn flex-content"
            @click="createOpenBlackRoom()"
          >
            立即创建
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      establishRoomShow: false, //创建房间
      roomform: {
        name: "",
        districtId: "", //游戏区服id
        levelId: "", //游戏段位Id
        modeId: "", //游戏模式id
        numLimit: "", //开黑人数限制
        theme: "", //聊天室主题
      },
      labelType: 1, //房间类型 1游戏 2娱乐
      twolabel: {}, //二级标签
      defaultImg: "", //房间默认封面
      gameLabels: [], //游戏标签
      amusementLabels: [], //娱乐标签
      gameDistricts: [], //游戏区服列表
      gameLevels: [], //游戏段位列表
      gameMode: [], //	游戏模式列表
      gamePersonNum: [], //		游戏人数列表
      chatRoom: [], //娱乐房列表
      darkRoomList: [], //开黑房列表
      userInfo: {},
      roomValue: 0, //是否有开黑房权限 1有 0没有
    };
  },
  mounted() {
    this.getAllSecondLabel();
    this.languageToChatRoom();
    this.playTogetherRoom();
    this.userInfo = this.$store.state.userInfo;
    this.getIsRoomwhitelist();
    // console.log("用户数据", this.userInfo);
  },
  methods: {
    // 是否开黑房权限
    getIsRoomwhitelist() {
      this.$api.isRoomwhitelist({ userId: this.userInfo.id }).then((res) => {
        if (res.code == 1) {
          this.roomValue = res.data.value;
        }
      });
    },
    // 跳转聊天室
    skipChatRoom(i) {
      // return console.log(i);
      this.$router.push({
        path: "/someSingingHall",
        query: { roomId: i.roomId, liveStreamId: i.liveStreamingId },
      });
    },
    // 语聊房列表
    languageToChatRoom() {
      this.$api
        .languageToChatRoom({ pageNum: 1, pageSize: 999 })
        .then((res) => {
          if (res.code == 1) {
            this.chatRoom = res.data.rows;
          }
        });
    },
    // 开黑厅列表
    playTogetherRoom() {
      this.$api.playTogetherRoom({ pageNum: 1, pageSize: 999 }).then((res) => {
        if (res.code == 1) {
          this.darkRoomList = res.data.rows;
        }
      });
    },
    // 创建开黑房
    createOpenBlackRoom() {
      this.roomform.theme = this.twolabel.id;
      if (!this.roomform.name) return this.$message.info("请输入房间名称");
       if(this.labelType == 1){

           if(!this.roomform.districtId) return this.$message.info('请选择游戏区服')
           if(!this.roomform.levelId) return this.$message.info('请选择游戏段位')
          if(!this.roomform.modeId) return this.$message.info('请选择游戏模式')
         if(!this.roomform.numLimit) return this.$message.info('请选择开黑人数')
       }
      this.$api.createOpenBlackRoom(this.roomform).then((res) => {
        if (res.code == 1) {
          // this.$message.info(res.msg)
          //配置房间信息
          const rcVoiceRoomInfo = {};
          // 设置房间名称
          rcVoiceRoomInfo.roomName = this.roomform.name;
          // 设置麦位数量
          rcVoiceRoomInfo.seatCount = 10;
          let data = res.data;
          this.$router.push({
                path: "/someSingingHall",
                query: { roomId: data.roomId, },
              });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 二级分类选择
    secondLevelChange(i) {
      this.twolabel = i;
      this.getCreateGameRoomOptions(this.twolabel.id);
    },
    // 类型选择
    labelChange(type) {
      this.labelType = type;
      if (type == 1) {
        this.twolabel = this.gameLabels[0];
        this.roomform = {
          name: "",
          districtId: "", //游戏区服id
          levelId: "", //游戏段位Id
          modeId: "", //游戏模式id
          numLimit: "", //开黑人数限制
          theme: "", //聊天室主题
        };
      } else {
        this.twolabel = this.amusementLabels[0];
        this.roomform = { name: "" };
      }
      this.getCreateGameRoomOptions(this.twolabel.id);
    },
    // 获取创建游戏聊天室选项列表
    getCreateGameRoomOptions(id) {
      this.$api.getCreateGameRoomOptions({ gameLabelId: id }).then((res) => {
        if (res.code == 1) {
          this.defaultImg = res.data.defaultImg;
          this.gameDistricts = res.data.gameDistricts; //游戏区服列表
          this.gameLevels = res.data.gameLevels; //游戏段位列表
          this.gameMode = res.data.gameMode; //	游戏模式列表
          this.gamePersonNum = res.data.gamePersonNum; //		游戏人数列表
        }
      });
    },
    // 热门-游戏-娱乐，三个分类下所有分类
    getAllSecondLabel() {
      this.$api.getAllSecondLabel().then((res) => {
        if (res.code == 1) {
          this.amusementLabels = res.data.amusementLabels;
          this.gameLabels = res.data.gameLabels;
          this.twolabel = this.gameLabels[0];
          this.getCreateGameRoomOptions(this.gameLabels[0].id);
        }
      });
    },
  },
};
</script>
<style scoped lang='scss'>
.room_role {
  color: #333;
  font-size: 10px;
  text-align: center;
  line-height: 19px;
  background: linear-gradient(90deg, #FFA700 0%, #FFDD00 100%);
  margin: 0 8px;
  border-radius: 12px;
  width: 70px;
  height: 25px;
  line-height: 25px;
}
.popup_box {
  width: 1920px;
  height: 1080px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0;
}
.establishRoom_box {
  width: 860px;
  height: 760px;
  color: #333;
  font-size: 14px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 35%;
  left: 35%;
  transform: translate(-50%, -50%);
  .establish_box {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  .establishTab_item {
    width: 102px;
    height: 32px;
    margin-right: 10px;
    background: #f5f5f5;
    border-radius: 4px 4px 0px 0px;
  }
  .establishTab_puth {
    background-color: rgba(255, 221, 0, 1);
  }
  .skill_box {
    width: 820px;
    height: 110px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    background: rgba(255, 221, 0, 1);
    border-radius: 0px 4px 4px 4px;
    box-sizing: border-box;
    overflow-x: scroll;
    .skill_item {
      width: 90px;
      height: 90px;
      color: #8586a9;
      text-align: center;
    }
    .itemText {
      width: 90px;
    }
    .skill_pitch {
      width: 90px;
      height: 90px;
      color: #fff;
      background: RGBA(255, 205, 0, 1);
      border-radius: 8px;
    }
  }
  .establish_formBox {
    width: 820px;
    height: 392px;
    margin-top: 20px;
    padding: 20px 0;
    background: rgba($color: #363659, $alpha: 0.2);
    border-radius: 0px 4px 4px 4px;
  }
  .establish_formbtn {
    width: 320px;
    height: 40px;
    color: #333;
    margin: auto;
    margin-top: 40px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
  }
  .label_img {
    width: 40px;
    height: 40px;
    margin-bottom: 6px;
    border-radius: 8px;
  }
  .cover_img {
    width: 64px;
    height: 64px;
    border-radius: 8px;
  }
}
.roomPage {
  color: #000;
  font-size: 14px;
}
.title {
  margin: 16px 0;
}
.title:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 10px;
  margin-right: 4px;
  background: linear-gradient(180deg, #FFA700 0%, #FFDD00 100%);
  border-radius: 0px 2px 2px 0px;
}
//房间列表
.roomList_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.room_item {
  width: 49.5%;
  height: 90px;
  padding: 8px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  .roomItem_head {
    width: 74px;
    height: 74px;
    margin-right: 15px;
    border-radius: 8px;
  }
  .roomItem_userHead {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .roomItem_icon {
    width: 78px;
    height: 20px;
  }
}
.establish_btn {
  width: 260px;
  height: 48px;
  color: #ffffff;
  background: #1d223b;
  border-radius: 26px;
  margin: auto;
  margin-top: 100px;
}

/deep/.el-upload--picture-card {
  width: 0;
  height: 90px;
  background-color: transparent;
  border: 0;
}
/deep/.el-form-item__label {
  color: #333;
}
/deep/.el-input__inner {
  color: #333;
  background-color: #f5f5f5 !important;
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #7e53f0;
  background: #7e53f0;
}
/deep/.el-textarea__inner {
  border: 0;
  background-color: #f5f5f5;
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #333;
}
/deep/.el-input__inner::placeholder {
  color: #6a6a94 !important;
}
/deep/ textarea::-webkit-input-placeholder {
  -webkit-text-fill-color: #6a6a94;
}
</style>