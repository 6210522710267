"use strict";
import axios from "axios";
import store from "@/store";
import {
	localGetItem
} from "@/config/local";
import {
	BASE_URL
} from "@/config/index";
import router from "@/router";

// 使用的cube-ui  滴滴框架
import {
	Message
} from "element-ui";
let isGoLOGIN = false;
let Timer = null;

const instance = axios.create({
	// 默认请求路径  算是公众的路径
	baseURL: BASE_URL + "tjyy-app/api/v1/",
	// 请求发送时间 超时
	timeout: 10000,
	traditional: true,
	// withCredentials: true
});

// let loading = '';

// 请求拦截器   （目前只用到了 loading 2020.6.9）
/**
 *  loading 是否需要 显示loading
 */
instance.interceptors.request.use(
	(config) => {
		// console.log('localStorage.getItem("token")', localStorage.getItem("user-token"))
		config.headers["TOKEN"] = localStorage.getItem("user-token");
		// console.log( "config",config);
		// loading = Toast.$create({
		//     time: 0,
		//     txt: '正在加载...',
		// });
		// if (config.loading) {
		//     loading.show();
		// }
		return config;
	},
	(err) => {
		// 什么时候运行这个error ???
		// loading.hide();
		return Promise.reject(err);
	}
);

// 响应拦截器
instance.interceptors.response.use(
	(response) => {
		// 将token存入本地
		if (response.data && response.data.data && response.data.data.token) {
			if (!response.data.data.code) {
				let tempToken = response.data.data.token;
				localStorage.setItem('user-token', tempToken);
			}
		}
		// if (response.data && response.data.data && response.data.data.userVo) {
		// 	let data = JSON.stringify(response.data.data.userVo)
		// 	localStorage.setItem('userData', data);

		// }
		let {
			data,
			status
		} = response;
		if (status != 200) {
			let errMessage = "";
			switch (status) {
				case 400:
					errMessage = "请求错误(400)";
					break;
				case 401:
					errMessage = "未授权，请重新登录(401)";
					Message("登录失效");
					store.commit("logout");
					break;
				case 403:
					errMessage = "拒绝访问(403)";
					break;
				case 404:
					errMessage = "请求出错(404)";
					break;
				case 408:
					errMessage = "请求超时(408)";
					break;
				case 500:
					errMessage = "服务器错误(500)";
					break;
				case 501:
					errMessage = "服务未实现(501)";
					break;
				case 502:
					errMessage = "网络错误(502)";
					break;
				case 503:
					errMessage = "服务不可用(503)";
					break;
				case 504:
					errMessage = "网络超时(504)";
					break;
				case 505:
					errMessage = "HTTP版本不受支持(505)";
					break;
				default:
					errMessage = "服务器错误!";
					break;
			}

			if (status != 401) {
				Message(errMessage);
				// uni.$emit("netWorkError", {
				//   msg: i18n["服务器太拥挤了~请您稍后重试"],
				// });
			}
			return Promise.reject({
				status,
				errMessage
			});
		} else {
			let _code = response.data.code;
			if (_code == "-201" || _code == "-202" || _code == "2") {
				if (!isGoLOGIN) {
					isGoLOGIN = true;
					Message("登录失效 请重新登录");
					router.replace({
						path: "/login",
					});
					// Timer = setTimeout(() => {
					// 	store.commit("logout");
					// 	// router.push({
					// 	// 	name: "login",
					// 	// });

					// 	clearTimeout(Timer);
					// 	// navigateToLogin();
					// }, 800);
				}
				return Promise.reject(response);
			} else {
				isGoLOGIN = false;
				return data;
			}
		}

		// // loading.hide();
		// return response;
	},
	(err) => {
		// loading.hide();
		return Promise.reject(err);
	}
);
/**
 *
 * @param {options} options 参数集合
 * @param {url}    url *: 地址
 * @param {method}    method : 请求类型  默认GET
 * @param {params}    params ： 参数
 * @param {loading}    loading ：是否显示loading  默认true
 * @param {header}    header ： 表头参数
 */
// export const get = (url, params) => {
// 	return instance.get(url, { params });
// }

// export const post = (url, data) => {
// 	return instance.post(url, data);
// }

// export const put = (url, data) => instance.put(url, data);

// export const del = (url, params) => instance.delete(url, { params });



const myHttp = function (options) {
	// console.log('options', options)
	let defaultOptions = {
		url: "",
		method: "POST",
		// loading: true,
	};

	defaultOptions = Object.assign(defaultOptions, options);

	// 当为POST请求的时候将 配置params  改为data
	// 详情见http://axios-js.com/zh-cn/docs/index.html#axios-get-url-config里的请求配置
	// if (defaultOptions.method.toLocaleLowerCase() === "post") {
	// 	let params = {
	// 		...defaultOptions.params,
	// 	};

	// 	delete defaultOptions["params"];

	// 	defaultOptions.data = {
	// 		...params,
	// 	};

	// }

	let {
		url,
		method,
		// loading,
		data,
		params,
	} = {
		...defaultOptions,
	};
	// console.log('ppppppppp', method);
	return instance(defaultOptions);
	// return instance({
	// 	url,
	// 	method,
	// 	// loading,
	// 	data,
	// 	params,
	// });
};

export default myHttp;