"use strict";
// const BASE_URL = "http://112.74.161.140/";
const BASE_URL = "https://ser.t9cp.com/"; //线上地址
// const BASE_URL = "http://192.168.7.94:8096/"; //黄磊s本地
// const BASE_URL = "http://192.168.10.113:8096/"; //郭荣昌本地
// const BASE_URL = "http://192.168.10.113:8096/"; //本地
const OSS_IMG_BASE_URL = "https://haohaobang.oss-cn-beijing.aliyuncs.com/"; // 图片在 oss 上的位置
const OSS_IMG_UPLOADING = "https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli" // 后台oss上传

/**
 * 页面回到顶部
 */
let srcollTopTimer = null;
let pageScrollTop = function () {
	// window.document.body.scrollTop = 0;
	// window.document.documentElement.scrollTop = 0;
	clearInterval(srcollTopTimer);
	srcollTopTimer = window.setInterval(function () {
		var top = document.body.scrollTop || document.documentElement.scrollTop;
		var speed = top / 4;
		if (document.body.scrollTop != 0) {
			document.body.scrollTop -= speed;
		} else {
			document.documentElement.scrollTop -= speed;
		}
		if (top <= 0) {
			window.clearInterval(srcollTopTimer);
		}
	}, 16.7);
};

export {
	BASE_URL,
	OSS_IMG_BASE_URL,
	pageScrollTop,
	OSS_IMG_UPLOADING
};