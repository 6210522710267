<template>
  <!-- 倒计时组件 -->
  <div class="time_style">{{`${hr}:${min}:${sec}`}}</div>
</template>
<script>
export default {
name: '',
props:{
  endTime:{
    // required: true, //必传属性
    default: 0, //默认属性
  }
},
components: {},
data() {
    return {
      day: 0,
      hr: 0,
      min: 0,
      sec: 0,
      calculator:''
    }
},

mounted() {
    this.countdown()
},
destroyed(){
  window.clearTimeout(this.calculator);
},
methods: {
    // 倒计时方法
    countdown() {
      // 目标日期时间戳
      const end = Date.parse(this.endTime)
      // 当前时间戳
      const now = Date.parse(new Date())
      // 相差的毫秒数
      const msec = end - now

      //   console.log(msec)
      if (msec < 0) return this.$emit('thEndStrike') //神豪倒计时结束

      // 计算时分秒数
      let day = parseInt(msec / 1000 / 60 / 60 / 24)
      let hr = parseInt((msec / 1000 / 60 / 60) % 24)
      let min = parseInt((msec / 1000 / 60) % 60)
      let sec = parseInt((msec / 1000) % 60)
      // 个位数前补零
      this.day = day > 9 ? day : '0' + day
      this.hr = hr > 9 ? hr : '0' + hr
      this.min = min > 9 ? min : '0' + min
      this.sec = sec > 9 ? sec : '0' + sec
      // 赋值
      const that = this
      // console.log('hr------',hr);
      // console.log('min------',min);
      // console.log('sec------',sec);
      if(hr != 0){
        //倒计时结束关闭订单
        if (hr == 0 && min == 0 && sec == 0) {
                  return window.clearTimeout(this.calculator);
        }
        // 一秒后递归
        this.calculator = window.setTimeout(function () {
          // console.log('倒计时-----------');
          that.countdown()
        }, 1000)
      }else if (min > 0 && sec > 0 ) {
        //倒计时结束关闭订单
        if (hr == 0 && min == 0 && sec == 0) {
          return window.clearTimeout(this.calculator);
        }
        // 一秒后递归
        
        
      }
      this.calculator = window.setTimeout(function () {
          that.countdown()
        }, 1000)
    },
},
};
</script>
<style lang='scss'>
.time_style{
    padding:2px 4px;
    margin-top: 2px;
    background: rgba($color: #fff, $alpha: 0.4);
    border-radius: 2px;
}
</style>