<template>
  <!--黑名单列表 -->
  <div class="popUp_box">
    <div class="pop_up" style="width: 902px;height: 598px;">
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14">厅黑名单</div>
        <img src="../../../static/quit.png" alt="" @click="darkChange()" />
      </div>
      <!-- 头部end -->
      <div class="solutionRemind_box flex-dir">
        <div class="font-12" style="color: rgba(65, 119, 221, 1);margin:20px 0">
          被拉黑的用户
        </div>
        <el-input
            placeholder="搜索昵称"
            v-model="petName"
            class="blacklist_input"
            prefix-icon="el-icon-search"
            @blur="searchKeyBtn"
          ></el-input>
        <div class="llacingCont_table" style="width:680px">
          <!-- 头部表头 -->
          <div class="flex-aling llacingCont_tr" >
            <div class="llacingCont_heder flex-content" style="width:80px">序号</div>
            <div class="llacingCont_heder flex-content" style="width:250px">用户信息</div>
            <div class="llacingCont_heder flex-content" >用户ID</div>
            <div class="llacingCont_heder flex-content" style="width:150px">操作</div>
          </div>
          <!-- 下单内容 -->
          <div class="flex-aling llacingCont_tr" v-if="(blackList.length == 0)" style="height:100px;height: 200px;justify-content: center;">
            暂无数据
          </div>
          <div class="flex-aling llacingCont_tr" v-for="(item,index) in blackList" :key="index" style="color:#333">
            <div class="llacingCont_td flex-content" style="width:80px;color:#333">{{(index + 1)}}</div>
            <div class="llacingCont_td flex-content" v-if="item.sex==0"  style="width:250px;color:#333">
              <img :src="item.avatar" alt="" class="acatarImg"> <span>{{item.nickname}}</span>
              <div class="itemSex"> <img src="../../../static/25.png" alt="" class="ageImg" />{{item.age}}</div>
            </div>

            <div class="llacingCont_td flex-content" v-else  style="width:250px;color:#333;display:flex;align-items: center;">
              <img :src="item.avatar" alt="" class="acatarImg" style="padding-top:5px"> <span>{{item.nickname}}</span>
              <div class="itemSex" style="background:rgba(255, 120, 120, 1)"> <img src="../../../static/134.png" alt="" class="ageImg" />{{item.age}}</div>
            </div>

            <div class="llacingCont_td flex-content" style="color:#333">{{item.userNo}}</div>
            <div class="llacingCont_td flex-content" style="width:150px;color:#333" @click="romveUser(item)">
              <div>移出</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props:['roomId'],
  data() {
    return {
      placingheader: ["序号", "用户信息", "用户ID",'时间', "操作"],
      petName:'',
      blackList:[]
    };
  },
  mounted(){
    this.roomManagersInfo()
  },
  methods: {
    roomManagersInfo(){
      this.$api.roomBlackList({roomId:this.roomId,searchKey:this.petName}).then(res=>{
        if(res.code == 1){
          this.blackList = res.data
        }
      })
    },
    darkChange() {
      this.$emit("blackListChange");
    },
    searchKeyBtn(){
      this.roomManagersInfo()
    },
    romveUser(item){
      let data = {
        blackStatus: 0, //	是否拉黑（0-取消拉黑 1-拉黑）
        roomId: this.roomId, //	房间id
        targetUserId: item.userId,
      };
      this.$api.blackUser(data).then((res) => {
        if(res.code==1){
          this.$message('移除成功')
          this.roomManagersInfo()
        }
      });
    },
  },
};
</script>
<style scoped lang='scss'>
// 弹窗背景
.popUp_box {
  width: 1920px;
  height: 1080px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 1;
  color:#333;
  .pop_up {
    background: #fff;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
  }
  .llacingCont_table {
    width: 783px;
    border: none !important;
  }
  .llacingCont_heder{
    color: #333;
  }
  .acatarImg{
    width: 28px;
    height: 28px;
    margin-right: 5px;
    border-radius: 50%;
  }
  .itemSex{
    color: #fff;
    background: rgba(133, 188, 254, 1);
    padding: 0 2px;
    margin: 0 5px;
    border-radius: 8px;
  }
  .ageImg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
  .llacingCont_heder{
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .llacingCont_td{
    border-right: 1px solid rgba(238, 238, 238, 1);
    border-bottom: 1px solid rgba(238, 238, 238, 1);
  }
}
.blacklist_input /deep/.el-input__inner{
  border:0;
}
</style>