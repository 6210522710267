<template>
  <!-- 进度条 -->
  <div class="m-progress" :style="`width:${width}px;`">
    <div class="progress_left">
      <div class="m-progress-inner">
        <div
          :class="['u-progress-bg', { 'u-success-bg': progress >= 100 }]"
          :style="`width: ${
            progress >= 100 ? 100 : progress
          }%; height: ${strokeWidth}px;position:relative`"
        >
        <div style="font-size:8px;position: absolute;left: 4px;top: 2px;">{{redTeamValue}}</div>
        <img src="../../../image/room/27.png" alt="" style="position: absolute;right: -10px;top: -10px;">  
        </div>
      </div>
      <div style="font-size:8px;position: absolute;right: 4px;top: 2px;">{{blueTeamValue}}</div>
    </div>
    <template v-if="showInfo"></template>
  </div>
</template>
<script>
export default {
  name: "",
  props: {
    width: {
      // 进度条总宽度
      type: Number,
      default: 600,
    },
    progress: {
      // 当前进度
      type: Number,
      default: 0,
    },
    strokeWidth: {
      // 进度条线的宽度
      type: Number,
      default: 16,
    },
    showInfo: {
      // 是否显示进度数值或状态图标
      type: Boolean,
      default: true,
    },
    blueTeamValue: {
      default: 0,
    },
    redTeamValue: {
      default: 0,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script> 

<style scoped lang="scss">
.m-progress {
  height: 24px;
  margin: 0 auto;
  display: flex;
  .progress_left {
    width: 100%;
    height: 16px;
    position: relative;
    .m-progress-inner {
      display: inline-block;
      width: 100%;
      background: #7d51f1;
      border-radius: 100px;
      .u-progress-bg {
        // background: #1890FF;
        background: #e95558;
        border-radius: 100px;
        transition: all 0.3s cubic-bezier(0.08, 0.82, 0.17, 1);
      }
      .u-success-bg {
        border-radius: 100px;
      }
    }
  }
  .u-success {
    width: 16px;
    height: 16px;
    fill: #52c41a;
    margin-left: 8px;
    margin-top: 4px;
  }
  .u-progress-text {
    font-size: 16px;
    line-height: 24px;
    margin-left: 8px;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>