import Vue from 'vue'
import App from './App.vue'
import Axios from './utils/request'
import router from './router'
import VueRouter from 'vue-router'
import store from './store/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import md5 from 'js-md5';
import TIMUploadPlugin from 'tim-upload-plugin';
import TRTC from 'trtc-sdk-v5'; 
import TencentCloudChat from '@tencentcloud/chat';
import TIMProfanityFilterPlugin from 'tim-profanity-filter-plugin';
import { TUICallEngine, TUICallEvent, TUICallType } from "tuicall-engine-webrtc";
// import RCLiveRoomLib from "@rongcloud/rcliveroomlib";
import RCVoiceRoomLib from "@rongcloud/rcvoiceroomlib-v1";
// import RCVoiceRoomLib from "../../../Gwork/rcvoiceroomlib-web/dist/main";
// import QC from 'qc' 
import videojs from "video.js";
import "video.js/dist/video-js.css";
Vue.prototype.$video = videojs;
import * as RongIMLib from "@rongcloud/imlib-next";
import * as RongRTCLib from "@rongcloud/plugin-rtc";
import * as Engine from "@rongcloud/engine";
import VueScroller from 'vue-scroller'
// imkit 为核心模块
import {
  defineCustomElements,
  imkit
} from '@rongcloud/imkit'
defineCustomElements()
//引入全局样式
import "./common/css/overarr.css"
import config from "./config"
let options = {
  SDKAppID: 1600008716 //替换为您的即时通信IM应用的 SDKAppID
};

Vue.prototype.$SDKAppID = options.SDKAppID;
let chat = TencentCloudChat.create(options); // SDK 实例通常用 tim 表示
chat.setLogLevel(0)
chat.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});
chat.registerPlugin({'tim-profanity-filter-plugin': TIMProfanityFilterPlugin});

// let $Tim=TIM.create(options);


let options1 = {
  SDKAppID: 1600008716, // 接入时需要将0替换为您的云通信应用的 SDKAppID
  // tim 参数适用于业务中已存在 TIM 实例，需要传入保证 TIM 实例唯一性
  tim: chat
};
// console.log(TUICallEngine.createInstance(options1),'TUICallEngineTUICallEngineTUICallEngineTUICallEngineTUICallEngineTUICallEngineTUICallEngineTUICallEngineTUICallEngineTUICallEngine')
let tuiCallEngine = TUICallEngine.createInstance(options1);
Vue.prototype.tuiCallEngine = tuiCallEngine;
Vue.prototype.chat = chat;
Vue.prototype.TUICallEvent=TUICallEvent
Vue.prototype.TencentCloudChat=TencentCloudChat
// Vue.prototype.$Tim=$Tim
// $Tim.registerPlugin({'tim-upload-plugin': TIMUploadPlugin});
// $Tim.registerPlugin({'tim-profanity-filter-plugin': TIMProfanityFilterPlugin});


const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



// 引入api
import API from "./api";
import confirmMy from './assets/js/confirmMy'
Vue.prototype.$confirmMy = confirmMy;
Vue.prototype.$api = API;
Vue.use(VueRouter)
Vue.use(router)
Vue.use(store)
Vue.use(ElementUI)
Vue.use(VueScroller)
Vue.config.productionTip = false
Vue.prototype.$axios = Axios
Vue.prototype.$md5 = md5;
Vue.prototype.$store = store;
Vue.prototype.$RCVoiceRoomLib = RCVoiceRoomLib;
// Vue.prototype.$RongIMLib = RongIMLib;
// Vue.prototype.$RongRTCLib = RongRTCLib;
Vue.prototype.$Engine = Engine;
Vue.prototype.$imkit = imkit;
// Vue.prototype.QC = QC;
// TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.DEBUG);
TRTC.setLogLevel(2);
let trtc = TRTC.create();
Vue.prototype.$trtc = trtc;
Vue.prototype.TRTC=TRTC
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')