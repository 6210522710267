<template>
  <div>
    <div class="popup_box">
      <div class="establishRoom_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14  flex-aling">派单</div>
          <img src="../../../static/quit.png" alt="" @click.stop="sendOrdersChange()" />
        </div>
        <!-- 头部end -->
        <div class="establish_box">
          <div class="flex-aling">
            <div
              class="establishTab_item flex-content"
              :class="labelType == 1 ? 'establishTab_puth' : ''"
              @click="labelChange(1)"
            >
              游戏开黑
            </div>
            <div
              class="establishTab_item flex-content"
              :class="labelType == 2 ? 'establishTab_puth' : ''"
              @click="labelChange(2)"
            >
              娱乐
            </div>
          </div>
          <!-- 二级分类标签 -->
          <div class="skill_box">
            <div
              class="skill_item flex-dir"
              v-for="(item, index) in labelType == 1
                ? gameLabels
                : amusementLabels"
              :key="index"
              :class="twolabel.id == item.id ? 'skill_pitch' : ''"
              @click="secondLevelChange(item)"
            >
              <img :src="item.logo" alt="" class="label_img" />
              <div class="itemText">{{ item.name }}</div>
            </div>
          </div>
          <!-- 二级分类标签 -->

          <div
            class="establish_formBox"
            :style="{ height: labelType == 2 ? '200px' : '' }"
          >
            <el-form ref="form" :model="roomform" label-width="130px">
              <el-form-item label="大神类型:">
                <el-select
                    v-model="roomform.type"
                    placeholder="请选择大神类型"
                    style="width:330px"
                  >
                    <el-option
                      v-for="item in manitoData"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
              </el-form-item>
              <el-form-item label="性别:">
                <el-select
                    v-model="roomform.sex"
                    placeholder="请选择性别"
                    style="width:330px"
                  >
                    <el-option
                      v-for="item in sexData"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
              </el-form-item>
              <template v-if="labelType == 1">
                <el-form-item label="游戏区服:">
                  <el-select
                    v-model="roomform.gameDistrictId"
                    placeholder="不限区服"
                    style="width:330px"
                  >
                    <el-option
                      v-for="item in gameDistricts"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="游戏段位:">
                  <el-select v-model="roomform.levelId" placeholder="不限段位"  style="width:330px">
                    <el-option
                      v-for="item in gameLevels"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="对局模式:">
                  <el-select v-model="roomform.gameModeId" placeholder="不限模式"  style="width:330px">
                    <el-option
                      v-for="item in gameMode"
                      :key="item.value"
                      :label="item.text"
                      :value="item.value"
                      :disabled="item.disabled"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="补充备注:" >
                  <el-input
                  type="textarea"
                  v-model="roomform.remark"
                  placeholder="请输入补充说明，如区服，需教学等其他要求"
                  resize="none"
                  maxlength="35"
                  class="user_textarea"
                  :rows="3"
                ></el-input>
                </el-form-item>
              </template>
            </el-form>
          </div>
          <div
            class="establish_formbtn flex-content"
            @click="orderDispatch()"
          >
            确认派单
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props:['roomId'],
  components: {},
  data() {
    return {
      typeName: "公会大神",
      roomform: {
      //	大神类型 0-公会大神 1-平台
        type:0,
        sex:0,
        gameDistrictId: "", //游戏区服id
        levelId: "", //游戏段位Id
        gameModeId: "", //游戏模式id
        remark:'',//补充说明
      },
      labelType: 1, //房间类型 1游戏 2娱乐
      twolabel: {}, //二级标签
      defaultImg: "", //房间默认封面
      gameLabels: [], //游戏标签
      amusementLabels: [], //娱乐标签
      gameDistricts: [], //游戏区服列表
      manitoData:[//0公会大神 1 平台大神
        {
          value:0,
          text:'公会大神'
        },
        {
          value:1,
          text:'平台大神'
        }
      ],
      sexData:[//0-男  1-女
        {
          value:0,
          text:'男'
        },
        {
          value:1,
          text:'女'
        },
        {
          value:9,
          text:'不限'
        }
      ],
      gameLevels: [], //游戏段位列表
      gameMode: [], //	游戏模式列表
      gamePersonNum: [], //		游戏人数列表
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getAllSecondLabel();
    this.languageToChatRoom();
    this.playTogetherRoom();
  },
  methods: {
    // 关闭派单弹窗
    sendOrdersChange(){
      this.$emit('sendShowChange')
    },
    // 语聊房列表
    languageToChatRoom() {
      this.$api
        .languageToChatRoom({ pageNum: 1, pageSize: 999 })
        .then((res) => {
          if (res.code == 1) {
            this.chatRoom = res.data.rows;
          }
        });
    },
    // 开黑厅列表
    playTogetherRoom() {
      this.$api.playTogetherRoom({ pageNum: 1, pageSize: 999 }).then((res) => {
      });
    },
    // 派单
    orderDispatch() {
    this.roomform.roomId = this.roomId
    this.roomform.labelId = this.twolabel.id
    this.$api.orderDispatch( this.roomform).then(res=>{
      if(res.code == 1){
        this.sendOrdersChange()
      }
      this.$message.info(res.msg)
    })
    },
    // 二级分类选择
    secondLevelChange(i) {
      this.twolabel = i;
      this.getCreateGameRoomOptions(this.twolabel.id);
      this.roomform = {
          name: "",
          type:0,
          sex:0,
          gameDistrictId: "", //游戏区服id
          levelId: "", //游戏段位Id
          gameModeId: "", //游戏模式id
          numLimit: "", //开黑人数限制
        };
    },
    // 类型选择
    labelChange(type) {
      this.labelType = type;
      if (type == 1) {
        this.twolabel = this.gameLabels[0];
        this.roomform = {
          name: "",
          type:0,
          sex:0,
          gameDistrictId: "", //游戏区服id
          levelId: "", //游戏段位Id
          gameModeId: "", //游戏模式id
          numLimit: "", //开黑人数限制
        };
      } else {
        this.twolabel = this.amusementLabels[0];
        this.roomform = { 
          name: "",
          type:0,
          sex:0, };
      }
      this.getCreateGameRoomOptions(this.twolabel.id);
    },
    // 获取创建游戏聊天室选项列表
    getCreateGameRoomOptions(id) {
      this.$api.getCreateGameRoomOptions({ gameLabelId: id }).then((res) => {
        console.log(res,'resresres')
        if (res.code == 1) {
          this.defaultImg = res.data.defaultImg;
          this.gameDistricts = res.data.gameDistricts; //游戏区服列表
          this.gameLevels = res.data.gameLevels; //游戏段位列表
          this.gameMode = res.data.gameMode; //	游戏模式列表
          this.gamePersonNum = res.data.gamePersonNum; //		游戏人数列表
        }
      });
    },
    // 热门-游戏-娱乐，三个分类下所有分类
    getAllSecondLabel() {
      this.$api.getAllSecondLabel().then((res) => {
        if (res.code == 1) {
          this.amusementLabels = res.data.amusementLabels;
          this.gameLabels = res.data.gameLabels;
          this.twolabel = this.gameLabels[0];
          this.getCreateGameRoomOptions(this.gameLabels[0].id);
        }
      });
    },
  },
};
</script> 

<style scoped lang="scss">
.user_textarea{
  width: 330px;
}
/deep/.el-textarea__inner{
  color: #333;
}
.popup_box {
  width: 1920px;
  height: 1080px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
.establishRoom_box {
  width: 860px; 
  color: #333;
  font-size: 14px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  .establish_box {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  .establishTab_item {
    width: 102px;
    height: 32px;
    margin-right: 10px;
    background: #F5F6F7;
    border-radius: 4px 4px 0px 0px;
  }
  .establishTab_puth {
    background-color: #FFDD00;
  }
  .skill_box {
    width: 820px;
    height: 110px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    background: #FFDD00;
    border-radius: 0px 4px 4px 4px;
    box-sizing: border-box;
    overflow-x: scroll;
    .skill_item {
      width: 90px;
      height: 90px;
      color: #8586a9;
      text-align: center;
    }
    .itemText{
      width: 90px;
    }
    .skill_pitch {
      width: 90px;
      height: 90px;
      color: #fff;
      background: linear-gradient(
        138deg,
        rgba($color: #635ea5, $alpha: 0.8) 0%,
        rgba($color: #454178, $alpha: 0.8) 100%
      );
      border-radius: 8px;
    }
  }

  .skill_box::-webkit-scrollbar{
    width: 0px;
    height: 8px;
  }
  .establish_formBox {
    width: 820px;
    height: 392px;
    margin-top: 20px;
    padding: 20px 0;
    background: rgba($color: #363659, $alpha: 0.2);
    border-radius: 0px 4px 4px 4px;
  }
  .establish_formbtn {
    cursor: pointer;
    width: 320px;
    height: 40px;
    color: #333;
    margin: auto;
    margin-top: 40px;
    background: #FFDD00;
    border-radius: 4px;
    z-index: 9999999;
  }
  .label_img {
    width: 40px;
    height: 40px;
    margin-bottom: 6px;
    border-radius: 8px;
  }
  .cover_img {
    width: 64px;
    height: 64px;
    border-radius: 8px;
  }
}
.roomPage {
  color: #000;
  font-size: 14px;
}
.title {
  margin: 16px 0;
}
.title:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 10px;
  margin-right: 4px;
  background: linear-gradient(90deg, #7c4ff0 0%, #976eff 100%);
  border-radius: 0px 2px 2px 0px;
}
//房间列表
.roomList_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.room_item {
  width: 49.5%;
  height: 90px;
  padding: 8px;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  .roomItem_head {
    width: 74px;
    height: 74px;
    margin-right: 15px;
    border-radius: 8px;
  }
  .roomItem_userHead {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 8px;
  }
  .roomItem_icon {
    width: 78px;
    height: 20px;
  }
}
.establish_btn {
  width: 260px;
  height: 48px;
  color: #ffffff;
  background: #1d223b;
  border-radius: 26px;
  margin: auto;
  margin-top: 100px;
}

/deep/.el-upload--picture-card {
  width: 0;
  height: 90px;
  background-color: transparent;
  border: 0;
}
/deep/.el-form-item__label {
  color: #d3d4dc;
}
/deep/.el-input__inner {
  color: #333;
  border: 0;
  background-color: #fff !important;
}
/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: #7e53f0;
  background: #7e53f0;
}
/deep/.el-textarea__inner {
  border: 0;
  background-color: #fff;
  color: #333;
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #fff;
}
/deep/.el-input__inner::placeholder {
  color: #6a6a94 !important;
}
/deep/.el-scrollbar .el-scrollbar__wrap{
  margin-bottom: 20px !important;
}
/deep/ textarea::-webkit-input-placeholder {
  -webkit-text-fill-color: #6a6a94;
}

</style>