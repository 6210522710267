<template>
  <!-- 公会房间管理员列表 -->
  <div>
    <!-- 管理员列表 -->
    <div class="popUp_box">
      <div class="pop_up" style="width: 760px;height: 698px;">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">管理员</div>
          <img src="../../../static/quit.png" alt="" @click="darkChange()" />
        </div>
        <!-- 头部end -->
        <div class="solutionRemind_box flex-dir">
          <div class="flex-aling">
            <span style="color:#333">角色:</span>
            <el-select v-model="roleNum" placeholder="请选择" class="roleSlect">
              <el-option
                v-for="item in roletOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span style="color:#333">用户ID:</span>
            <el-input
              type="text"
              placeholder="请输入内容"
              v-model="pages.userNo"
              class="userId_input"
              @change="inputChange()"
            ></el-input>
            <div class="btn_class" @click="roomManagersInfo()">查询</div>
            <div class="btn_class1"  @click="resetData()">重置</div>
            <div class="btn_class1" @click.stop="addAdminShow = true">新增管理</div>
          </div>
          <div class="llacingCont_table">
            <!-- 头部表头 -->
            <div class="flex-aling llacingCont_tr">
              <div class="llacingCont_heder flex-content" style="width:80px">序号</div>
              <div class="llacingCont_heder flex-content" style="width:75px">角色</div>
              <div class="llacingCont_heder flex-content" style="width:210px">用户信息</div>
              <div class="llacingCont_heder flex-content" style="width:120px" >用户ID</div>
              <div class="llacingCont_heder flex-content" style="width:105px">操作</div>
            </div>
            <!-- 下单内容 -->
            <div class="flex-aling llacingCont_tr" v-if="(adminList.length == 0)" style="height:100px;height: 200px;justify-content: center;">
              暂无数据
            </div>
            <div class="flex-aling llacingCont_tr" v-for="(item,index) in adminList" :key="index" style="color:#fff;height:40px" :style="{background:item % 2 ? '#262946':''}">
              <div class="llacingCont_td flex-content" style="width:80px">{{(index + 1)}}</div>
              <div class="llacingCont_td flex-content" style="width:75px">
                <img src="../../../image/room/72.png" alt="" v-if="item.userRole == 0" />
                <img src="../../../image/room/73.png" alt="" v-if="item.userRole == 1" />
                <img src="../../../image/room/74.png" alt="" v-if="item.userRole == 2" />
              </div>
              <div class="llacingCont_td flex-content"  style="width:210px">
                <img :src="item.avatar" alt="" class="acatarImg"> <span>{{item.nickname}}</span>
                <div class="itemSex"> <img src="../../../static/25.png" alt="" class="ageImg" />{{item.age}}</div>
              </div>
              <div class="llacingCont_td flex-content" style="width:120px">{{item.userNo}}</div>
              <div class="llacingCont_td flex-content" style="width:105px">
                <div style="color:#4177DD;" @click.stop="cancelSetManager(item)" >移除</div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="flex" style="width: 605px;justify-content: flex-end;margin-top:24px">
            <el-pagination
            background
            layout="prev, pager, next"
            @current-change="pagingtionChange"
            :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增管理员弹窗 -->
    <div class="popUp_box" v-if="addAdminShow">
      <div class="pop_up" style="width: 440px;height: 357px;">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">新增管理员</div>
          <img src="../../../static/quit.png" alt="" @click="addAdminShow = false,emptyData()" />
        </div>
        <!-- 头部end -->
        <div style="padding:40px 60px">
          <div style="colorL:#D3D4DC;margin-bottom:11px">用户ID</div>
          <div style="margin-bottom:20px;position: relative;">
            <img src="../../../image/room/77.png" alt="" style="position: absolute;z-index: 1;right: 10px;top: 10px;" @click.stop="selectUserChange()">
            <el-input
            type="text"
            
            class="textInput"
            placeholder="请输入用户ID"
            v-model="userIDInput"
            @input="getUserByUserNo()"
          ></el-input>
          </div>
          <div style="colorL:#D3D4DC;margin-bottom:11px">管理身份</div>
          <div>
            <el-select v-model="selectValue" placeholder="请选择" style="width:100%" class="textInput">
              <el-option
                v-for="item in roletOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="addAdmin_btn flex-content" @click="setManagerAdd()">确认新增</div>
        </div>
      </div>
    </div>
    <!-- 选择设置管理用户 -->
    <div class="popUp_box" v-if="selectUserShow">
      <div class="pop_up" style="width: 440px;height: 480px;">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">选择用户</div>
          <img src="../../../static/quit.png" alt="" @click="selectUserShow = false" />
        </div>
        <!-- 头部end -->
        <div style="padding:40px 60px">
          <div class="flex">
              <el-select v-model="roleType" placeholder="请选择" class="roleSlect" style="height:39px" @change="getFollowOrFansList()">
              <el-option
                v-for="item in listOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-input
              type="text"
              placeholder="请输入用户ID"
              v-model="userNoData"
              @input="getFollowOrFansList()"
              class="userIdinput_type"
            ></el-input>
          </div>
          <!-- 列表 -->
          <div style="margin-top:30px" class="user_box">
            <div class="flex-spacebetween user_item" v-for="(item,index) in userList" :key="index" @click="pitchUserChange(item)">
              <div class="flex">
                <img :src="item.avatar" alt="" class="head_style">
                <div class="">
                  <div class="flex-aling">
                    <div>{{item.nickname}}</div>
                    <div class="icon_style flex-content" v-if="roleType == 0">粉丝</div>
                  </div>
                  <div style="color:#6A6A94;font-size:10px">ID：{{item.userNo}}</div>
                  </div>
                </div>
              <div>选择</div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props:['roomId'],
  data() {
    return {
      addAdminShow:false,//新增管理弹窗
      selectUserShow:false,//选择用户弹窗
      placingheader: ["序号", "用户信息", "用户ID",'时间', "操作"],
      adminList:[],//管理员列表
      roletOptions:[
        {
          value:1,
          label:'超管'
        },{
          value:0,
          label:'管理'
        }
      ],
      roleNum:'',
      listOptions:[
        {
          value:1,
          label:'关注'
        },{
          value:0,
          label:'粉丝'
        }
      ],
      pages:{
        pageNum:1,
        pageSize:10,
        roomId:'',
        userNo:'',//用户id搜索
        userRole:'',//角色
      },
      selectValue:'',
      userIDInput:'',//用户id
      userNoData:'',//用户列表搜索
      total:0,//总数
      userList:[],//用户列表
      roleType:1,//列表类型 1关注 0粉丝
      searchData:{},//搜索出的用户
      pitchData:{},
      userIDInputStr:''
    };
  },
  mounted(){
    this.roomManagersInfo()
  },
  methods: {
    // 根据编号查询用户
    getUserByUserNo(){
      this.$api.getUserByUserNo({userNo:this.userIDInput}).then(res=>{
        if(res.code == 1){
          this.searchData = res.data
        }
      })
    },
    emptyData(){
      this.selectValue = ''
      this.userIDInput = ''
      this.searchData = {}
    },
    // 新增管理
    setManagerAdd(){
      if(!this.userIDInput) return this.$message.info('请输入用户ID')
      let selectValue = ''
      if(this.selectValue == 1){
        selectValue = this.selectValue
      }else{
        selectValue = ''
      }
      let data = {
        isManager:selectValue==1?'':1,
        roomId:this.roomId,
        superManager:selectValue,//	是否超级管理员（0-否 1-是）
        targetUserId:this.searchData ? this.searchData.id:'',//	用户id
      }
      this.$api.setManager(data).then(res=>{
        if(res.code == 1){
          this.roomManagersInfo()
          this.addAdminShow = false
            if(this.selectValue == 1){
              this.$emit('addAdmin',{type:1,userId:data.targetUserId})
            }else{
              this.$emit('addAdmin',{type:2,userId:data.targetUserId})
            }
          this.$message.success(res.msg)
          this.userIDInputStr=''
        }else{
          this.$message.error(res.msg)
          
        }
      })
    },
    // 选择用户
    pitchUserChange(i){
      this.pitchData  = i 
      this.userIDInput = i.userNo
      this.getUserByUserNo()
      this.selectUserShow = false
      this.userIDInputStr=i.nickname
    },
    // 选择用户弹窗
    selectUserChange(){
      this.getFollowOrFansList()
      this.selectUserShow = true
    },
    // 获取粉丝列表&&关注列表
    getFollowOrFansList(){
      let data = {
        pageNum:1,
        pageSize:'999',
        type:this.roleType,//	类型0-粉丝 1-关注
        userNo:this.userNoData,//	关键字
      }
      this.$api.followOrFansList(data).then(res=>{
        // console.log('列表',res);
        if(res.code == 1){
          this.userList = res.data.rows
        }
      })
    },
    // 重置
    resetData(){
      this.pages = {
        pageNum:1,
        pageSize:10,
        roomId:'',
        userNo:'',//用户id搜索}
      }
    this.roleNum = ''
    this.pages.userRole = ''
    this.roomManagersInfo()
    },
    // 分页切换
    pagingtionChange(res){
      this.pages.pageNum = res
      this.roomManagersInfo()
    },
    // 取消管理
    cancelSetManager(i){
      let data = {
        isManager: 0, //是否管理（0-否 1-是）
        roomId: this.roomId, //	房间id
        targetUserId:i.userId, 
      };
      this.$api.setManager(data).then((res) => {
        if(res.code == 1){
          this.roomManagersInfo()
          this.$emit('cancelAdmin',{userRole:i.userRole,userId:i.userId})
        }else{
          this.$message.error(res.msg)
        }
      });
    },
    //获取管理列表
    roomManagersInfo(){
      this.pages.roomId = this.roomId
      if(this.roleNum == '0'){
        this.pages.userRole = '2'
      }else{
        this.pages.userRole = this.roleNum
      }
      this.$api.roomManagersInfo(this.pages).then(res=>{
        if(res.code == 1){
          this.adminList = res.data.rows
          this.total = res.data.total
        }
      })
    },
    darkChange() {
      this.$emit("darkAdminListChange");
    },
  },
};
</script>
<style lang='scss' scoped>
.user_box{
  width: 320px;
  height: 300px;
  padding-right: 6px;
  overflow-y: scroll;
  box-sizing: border-box;
  color:#333;
  .user_item{
    align-items: center;
    height: 55px;
    border-bottom: 1px solid #2f3050;
  }
}
.icon_style{
  width: 32px;
  height: 16px;
  font-size: 8px;
  background: #FF3400;
  border-radius: 10px;
  margin-left: 5px;
}
.head_style{
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 50%;
}
.addAdmin_btn{
  width: 320px;
  height: 40px;
  color: #333;
  font-size: 14px;
  border-radius: 8px;
  margin-top: 35px;
  background-color: #ffdd00;
}
.userIdinput_type{
  width: 200px;
}
.roleSlect{
  width: 106px;
  border-radius: 4px;
  margin: 0 21px 0 8px;
}
.userId_input /deep/.el-input__inner,.roleSlect /deep/.el-input__inner{
  height: 40px;
  
}
.textInput /deep/ .el-input__count {
  color: #ffffff;
}
.textInput /deep/ .el-input__inner {
  background-color:#f5f5f5 !important;
  color: #333;
}
.userId_input{
  width: 103px;
  border-radius: 4px;
  margin: 0 21px 0 8px;
  border: 1px solid #363659;
}
// 弹窗背景
.popUp_box {
  width: 1920px;
  height: 1080px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0%;
  z-index: 1;
  .pop_up {
    background: #fff;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
  }
  .llacingCont_table{
    width: 593px;
    .llacingCont_td{
      height: 40px;
      color: #000000;
      border: none;
    }
  }
  .llacingCont_heder{
    color: #333;
  }
  .acatarImg{
    width: 28px;
    height: 28px;
    margin-right: 5px;
    border-radius: 50%;
    color:#8E92B1;
  }
  .itemSex{
    color: #fff;
    background: #4e5169;
    padding: 0 2px;
    margin: 0 5px;
    border-radius: 8px;
  }
  .ageImg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
  .btn_class{
    width: 76px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 ;
    margin-left: 8px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px ;
    cursor: pointer;
    color:#000000
  }
  .btn_class1{
    width: 76px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 ;
    margin-left: 8px;

    border-radius: 4px ;
    cursor: pointer;
    
color: #F68229;
    
border: 1px solid #F68229;
  }
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgb(255, 221, 0);
  color: #333;
}
/deep/ .el-pagination.is-background .btn-prev:disabled, /deep/.el-pagination.is-background .btn-next:disabled{
  background-color: #f5f5f5;
}
</style>