<template>
  <!-- 房间禁言列表 -->
  <div class="popUp_box">
    <div class="pop_up" style="width: 902px; height: 598px">
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14">厅禁言名单</div>
        <img src="../../../static/quit.png" alt="" @click="darkChange()" />
      </div>
      <!-- 头部end -->
      <div class="solutionRemind_box flex-dir">
        <div class="font-12" style="color: rgba(65, 119, 221, 1); margin: 20px 0">
          被禁言的用户无法发送弹幕
        </div>
        <el-input
          placeholder="搜索昵称"
          v-model="petName"
          class="blacklist_input"
          @blur="searchKey"
        ></el-input>
        <div class="llacingCont_table" style="width: 680px">
          <!-- 头部表头 -->
          <div class="flex-aling llacingCont_tr">
            <div class="llacingCont_heder flex-content" style="width: 80px">
              序号
            </div>
            <div class="llacingCont_heder flex-content" style="width: 250px">
              用户信息
            </div>
            <div class="llacingCont_heder flex-content">用户ID</div>
            <div class="llacingCont_heder flex-content">禁言结束时间</div>
            <div class="llacingCont_heder flex-content">禁言原因</div>
            <div class="llacingCont_heder flex-content" style="width: 150px">
              操作
            </div>
          </div>
          <!-- 下单内容 -->
          <div
            class="flex-aling llacingCont_tr"
            v-if="muteList.length == 0"
            style="height: 100px; height: 200px; justify-content: center"
          >
            暂无数据
          </div>
          <div
            class="flex-aling llacingCont_tr"
            v-for="(item, index) in muteList"
            :key="index"
            style="color: #333"
          >
            <div class="llacingCont_td flex-content" style="width: 107px;border-left:1px solid rgba(238, 238, 238, 1)">
              {{ index + 1 }}
            </div>
            <div class="llacingCont_td flex-content" style="width: 250px">
              <img :src="item.avatar" alt="" class="acatarImg" />
              <span>{{ item.nickname }}</span>
              <!-- <div class="itemSex" v-if="item.sex==0">
                <img src="../../../static/25.png" alt="" class="ageImg" />
                <span>{{ item.age }}</span> 
              </div> -->
              <!-- <div class="itemSex"  v-else style="background:rgba(255, 120, 120, 1)">
                <img  src="../../../static/134.png" alt="" style="ageImg" />
                <span>{{ item.age }}</span> 
              </div> -->
              <div>

              </div>
            </div>
            <div class="llacingCont_td flex-content">{{ item.userNo }}</div>
            <!-- 解除禁言时间 -->
            <div class="llacingCont_td flex-content" style="text-align: center;">{{ item.removeMuteTime }}1</div>
            <!-- 禁言原因 -->
            <div class="llacingCont_td flex-content" style="text-align: center;">{{ item.muteReason }}</div>
            <div class="llacingCont_td flex-content" style="width: 150px">
              <div @click="removeShutup(item)">移出</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  props: ["roomId"],
  data() {
    return {
      petName: "",
      placingheader: ["序号", "用户信息", "用户ID", "时间", "操作"],
      muteList: [],
    };
  },
  mounted() {
    this.roomManagersInfo();
  },
  methods: {
    // 移除禁言
    removeShutup(i) {
      let data = {
        muteStatus: 1, //	禁言状态（0-被禁言 1-正常）
        roomId: this.roomId, //	房间id
        targetUserId: i.userId,
      };
      this.$api.muteUser(data).then((res) => {
        this.roomManagersInfo();
      });
    },
    // 获取禁言名单
    roomManagersInfo() {
      this.$api.roomMuteList({ roomId: this.roomId,searchKey:this.petName }).then((res) => {
        if (res.code == 1) {
          this.muteList = res.data;
        }
      });
    },
    searchKey(){
      this.roomManagersInfo()
    },
    darkChange() {
      this.$emit("darkAdminListChange");
    },
  },
};
</script>
<style lang='scss' scoped>
// 弹窗背景
.popUp_box {
  width: 1920px;
  height: 1080px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0%;
  color:#fff;
  z-index: 1;
  .pop_up {
    background: #fff;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 4px;
  }
  .llacingCont_table {
    width: 783px;
    border: none !important;
  }
  .llacingCont_heder {
    color: #333;
    border: none;
  }
  .acatarImg {
    width: 28px;
    height: 28px;
    margin-right: 5px;
    border-radius: 50%;
  }
  .itemSex {
    color: #fff;
    background: rgba(133, 188, 254, 1);
    padding: 0 2px;
    margin: 0 5px;
    border-radius: 8px;
  }
  .ageImg {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
  .llacingCont_heder{
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .llacingCont_td{
    border-right: 1px solid rgba(238, 238, 238, 1);
    border-bottom: 1px solid rgba(238, 238, 238, 1);
  }
}
/deep/.el-input__inner{
  background-color: #f5f5f5;
}
</style>