<template>
  <div class="page">
    <!-- 重置密码页面 -->
    <!-- <img src="../../static/96.png" alt="" class="left_img" /> -->
    <div class="left_box">
      <div style="font-size:32px;margin-top:74px" class="font_bold">狗书语音</div>
      <div style="font-size:18px;margin-top:21px">甜蜜 久伴 你的心灵港湾</div>
      <img src="@/static/194.png" alt="" style="width:206.33px;height:210px;margin-top:74px">
    </div>
    <div class="login_box">
      <img
        src="../../static/59.png"
        alt=""
        class="quit_img"
        @click="skipHome()"
      />
      <div style="position: relative;">
        <div class="font-20 font_bold" style="color: #1d2030;z-index:1;position: absolute">重置密码</div>
        <img src="../../static/102.png" alt="" class="title_icon"/>
      </div>

      <!-- <el-input v-model="phone" placeholder="请输入手机号" class="login_input">
        <img slot="prefix" class="login_icon" src="../../static/97.png" alt="">
      </el-input>
      <el-input v-model="password" placeholder="请输入密码"></el-input> -->
      <div class="login_form" style="margin-top: 60px">
        <div class="position_r">
          <div class="login_icon"><img src="../../static/97.png" alt="" /></div>
          <input
            type="text"
            v-model="phone"
            placeholder="请输入手机号"
            class="login_input"
            oninput="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')"
            maxlength="11"
          />
        </div>
        <div class="position_r flex-aling">
          <input
            type="text"
            v-model="code"
            placeholder="验证码"
            class="login_code"
            oninput="value=value.replace(/\D/g, '').replace(/^0{1,}/g, '')"
            maxlength="6"
          />
          <!-- <div
            class="font-20"
            style="color: #7c4ff0; margin-left: 18px"
            @click="code = '369258'"
          >
            获取验证码
          </div> -->
          <!-- 获取验证码 -->
          <verificationCode :event="'forget'" :mobile="phone"></verificationCode>
          
        </div>
        <div class="position_r">
          <div class="login_icon"><img src="../../static/98.png" alt="" /></div>
          <input
            :type="passwordType"
            v-model="password"
            placeholder="请输入密码(6~12位字母+数字)"
            class="login_input"
          />
          <div class="password_show" @click="passwordChange()">
            <img
              src="../../static/99.png"
              alt=""
              v-if="passwordType == 'password'"
            />
            <img src="../../image/my/20.png" alt="" v-else />
          </div>
        </div>
      </div>

      <div class="enter flex-content" @click="setForgetPwd()">确定</div>
      <div class="quickLogin flex" @click="skipLogin()">我有账号？ <span style="color: rgb(65, 119, 221)">立即登录</span> </div>
    </div>
    <!-- 完善资料 -->
    <perfection v-if="perfectionShow"></perfection>
  </div>
</template>
<script>
import perfection from "./perfection.vue";
import verificationCode from "../../components/verificationCode/index.vue"

export default {
  name: "",
  components: {
    perfection, //完善资料
    verificationCode
  },
  data() {
    return {
      perfectionShow: false,
      phone: "",
      password: "",
      passwordType: "password",
      code: "", //验证码
    };
  },
  methods: {
    // 修改密码
    setForgetPwd() {
      
      let passwordText = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z]{6,12}$/;
      if (!this.phone) return this.$message.info("请输入手机号");
      if (!this.code) return this.$message.info("请输入验证码");
      
      if (!this.password) return this.$message.info("请输入密码");
      if (!passwordText.test(this.password))
        return this.$message.info("请输入密码(6~12位字母+数字)");
      let data = {
        captcha: this.code,
        event: "forget",
        mobile: this.phone,
        password: this.password,
      };
      this.$api.forgetPwd(data).then((res) => {
        if (res.code == 1) {
          this.$message.success(res.msg);
          this.$router.push("/login");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 显示隐藏密码
    passwordChange() {
      if (this.passwordType == "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    //注册
    submitRegister() {
      this.perfectionShow = true;
    },
    // 返回首页
    skipHome() {
      this.$router.push("/home");
    },
    // 返回登录
    skipLogin() {
      this.$router.push("/login");
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 827px;
  height: 596px;
  display: flex;
  font-size: 16px;
  background: #fff;
}
.title_icon{
  position: absolute;
  top: 15px;
}
.left_box{
  width: 360px;
  height: 596px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background:#ffdd00 ;
}
.titleBox{
  color: #999999;
  font-size: 20px;
  padding-bottom: 48px;
  justify-content: space-around;
}
.titlePitch{
  color: #333333;
  font-weight: 600;
  position: relative;
}
.titlePitch:after{
  content: '';
  width: 64px;
  height: 2px;
  display: inline-block;
  background: #FFDD00;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -50%;
}
.login_code {
  width: 267px;
  height: 56px;
  color: #86909b;
  border: 0;
  margin: 10px 0;
  font-size: 18px;
  padding-left: 20px;
  background: #f5f6f7;
  box-sizing: border-box;
}
.form_box {
  width: 500px;
  margin-top: 20px;
}
.form_btn {
  width: 320px;
  height: 40px;
  color: #ffffff;
  font-size: 14px;
  background: linear-gradient(135deg, #7c4ff0 0%, #976eff 100%);
  border-radius: 4px;
}
.left_img {
  width: 360px;
  height: 596px;
}
.login_box {
  padding: 40px 40px 0 40px;
  position: relative;
}
.quit_img {
  position: absolute;
  top: 20px;
  right: 20px;
}
.login_input {
  width: 387px;
  height: 56px;
  color: #86909b;
  border: 0;
  margin: 10px 0;
  font-size: 18px;
  padding-left: 80px;
  background: #f5f6f7;
  box-sizing: border-box;
}
.login_code {
  width: 267px;
  height: 56px;
  color: #86909b;
  border: 0;
  margin: 10px 0;
  font-size: 18px;
  padding-left: 20px;
  background: #f5f6f7;
  box-sizing: border-box;
}
.login_icon {
  height: 20px;
  position: absolute;
  top: 25px;
  left: 25px;
  padding-right: 20px;
  border-right: 1px solid #ccc;
  > img {
    width: 20px;
    height: 20px;
  }
}
.password_show {
  position: absolute;
  top: 30px;
  right: 25px;
}
.enter {
  width: 387px;
  height: 56px;
  color: #333;
  margin-top: 40px;
  font-size: 20px;
  background: #FFDD00;
  border-radius: 4px;
}
.quickLogin {
  width: 120px;
  margin: auto;
  color: #646c78;
  font-size: 13px;
  margin-top: 40px;
}
.loginType {
  margin-top: 20px;
  justify-content: space-between;
}
.else_login {
  width: 180px;
  height: 48px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #e6eaf1;
}
</style>
