<template>
  <div class="pages">
    <!-- 盲盒抽奖 -->
    <div class="blind_box" v-if="blindType == 0">
      <div class="titleIcon2"></div>
      <div class="title_content flex-aling">
        <div>盲盒购买</div>
        <img src="../../static/59.png" alt="" @click.stop="closePopup()" />
      </div>
      <!-- 礼物内容 -->
      <div class="gift_box flex">
        <template v-for="(item, key0) in this.prizeList">
          <div class="gift_item" :key="key0" v-if="item">
            <img :src="item.logo" alt="" class="img_style" v-if="item.logo" />
            <div v-if="item.name">{{ item.name }}</div>
          </div>
        </template>
        
        <div class="gift_item" @click="prizePoolDetailList()">
          <!-- <div class="evenMore"></div> -->
          <img src="../../static/64.png" alt="" />
          <div>查看奖池</div>
        </div>
      </div>
      <!-- 规则 -->
      <div class="flex ruleBox">
        <div @click="blindTypeChange(2)">购买规则</div>
        <div style="padding: 0 9px">|</div>
        <div @click="prizeRecord()">购买记录</div>
      </div>
      <!--  -->
      <div class="flex-content purchase_box">
        <img src="../../static/63.png" alt="" />
        <div class="purchase_hint">开盲盒抽好礼，欧皇就是你</div>
        <div class="flex-aling purchase_num">
          <div style="margin-right: 10px">购买数量</div>
          <el-input
            placeholder="请输入"
            v-model="lotteryNumber"
            maxlength="2"
            class="purchase_input"
            @keyup.enter.native="disableTextSubmit()"
          />
          <img
            src="../../static/user4.png"
            alt=""
            class="purchase_icon"
            @mouseenter="selectShow = true"
          />
          <div
            class="purchase_selectBox"
            v-show="selectShow"
            @mouseleave="selectShow = false"
          >
            <div
              class="select_item flex-content"
              v-for="item in lotteryNumberList"
              :key="item.value"
              :class="lotteryNumber == item.value ? 'select_pitch' : ''"
              @click="selectLottery(item.value)"
            >
              {{ item.text }}
            </div>
          </div>
        </div>
        <!-- 选择次数 -->
        <div class="flex" style="font-size: 10px; margin-top: 30px">
          <div class="flex-dir" style="margin-right: 27px">
            <div class="lotteryBtn1 flex-content" @click="doLottery(1)">
              抽1次 <img src="../../static/61.png" style="margin:0 7px" alt="" />{{ onePrice }}
            </div>
          </div>

          <div class="flex-dir">
            <div class="lotteryBtn2 flex-content" @click="doLottery(lotteryNumber)">
              买{{lotteryNumber}}次  <img src="../../static/61.png" alt="" />{{ onePrice * lotteryNumber }}
            </div>
          </div>
        </div>
        <!-- 选择次数end -->
      </div>
    </div>
    <!-- 盲盒奖池 -->
    <div class="blind_box box1" v-if="blindType == 1">
      <div class="titleIcon2"></div>
      <div class="title_content flex-aling">
        <div>盲盒奖池</div>
        <img
          src="../../static/59.png"
          alt=""
          @click.stop="blindTypeChange(0)"
        />
      </div>
      <!-- 奖池内容 -->
      <div class="blind_text">消耗元宝有几率获得礼物或道具</div>
      <div class="jackpot_box">
        <div
          class="jackpot_item flex-dir"
          v-for="(item, index) in jackpotList"
          :key="index"
        >
          <img :src="item.logo" alt="" class="img_style" />
          <div class="font-12" style="margin: 6px 0">{{ item.name }}</div>
          <div class="font-12">{{item.price}}元宝</div>
          <div class="font-10" style="color: #f68229">
            {{ item.numberOrLimit }}
          </div>
        </div>
      </div>
    </div>
    <!-- 抽奖规则 -->
    <div class="blind_box box2" v-if="blindType == 2">
      <div class="titleIcon2"></div>
      <div class="title_content flex-aling">
        <div>购买规则</div>
        <img src="../../static/59.png" alt="" @click.stop="blindType = 0" />
      </div>
      <!-- 规则内容 -->
      <div class="rule_text" v-html="ruleContent"></div>
    </div>
    <!-- 中奖记录 -->
    <div class="blind_box box3" v-if="blindType == 3">
      <div class="titleIcon2"></div>
      <div class="title_content flex-aling">
        <div>购买记录</div>
        <img
          src="../../static/59.png"
          alt=""
          @click.stop="blindTypeChange(0)"
        />
      </div>
      <!-- 规则内容 -->
      <div class="winning_record">
        <div class="form_head">
          <div class="item flex-aling" style="width: 171px">时间</div>
          <div class="item flex-aling" style="width: 115px">类型</div>
          <div class="item flex-aling" style="width: 153px">奖品内容</div>
        </div>
        <div
          class="form_tr"
          v-for="(item, index) in winningList"
          :key="index"
          :style="{ background: index % 2 != 0 ? '#f5f5f5' : '' }"
        >
          <div class="tr_item flex-aling" style="width: 171px">
            {{ item.createTime }}
          </div>
          <div class="tr_item flex-aling" style="width: 115px">
            <!-- {{ item.propsType | propsTypeNum }} -->
            {{ item.propsType == 1 ? '道具':'礼物'  }}
          </div>
          <div class="tr_item flex-aling" style="width: 153px">
            {{ `${item.giftName}x${item.prizeNum}` }}
            <span>{{ item.prizeTimeUnit | timeFilters }}</span>
          </div>
        </div>
        <div class="blind_paging">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="winningChange"
            :total="winningTotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 抽奖结果 -->
    <!-- 中奖记录 -->
    <div class="pages" v-if="resultShow">
      <div class="result_box">
        <!-- 单次抽奖 -->
        <div class="result_onebg flex-dir" v-if="resultList.length == 1">
          <div class="font-12" style="color: #363659">恭喜你,中奖啦!</div>
          <!-- <img src="../../static/181.png" alt="" class="one_img" /> -->
          <img :src="resultList[0].logo" alt="" class="one_img" />
          <div class="font-14" style="color: #7c4ff0; font-weight: bold">
            {{ resultList[0].name + "x" + resultList[0].numberOrLimit }}
          </div>
        </div>
        <!-- 多次抽奖 -->
        <div
          v-if="resultList.length > 1"
          class="font-12 flex-content"
          style="color: #B7643D; margin-top: 10px"
        >
        恭喜获得
        </div>
        <div class="result_repeatedly" v-if="resultList.length > 1">
          <div
            class="result_item flex-dir"
            v-for="(item, index) in resultList"
            :key="index"
          >
            <img :src="item.logo" alt="" style="width: 48px" />
            <div class="font-12" style="color: #5A4112; font-weight: 600">
              {{ item.name }}
            </div>
            <div class="font-10" style="color: #4177DD">
              {{ item.numberOrLimit }}
            </div>
          </div>
        </div>
        <div class="result_btn flex-dir" @click="doLottery(resultList.length)">
          <div style="color:#333"><img src="../../static/61.png" style="margin:0 4px 00" alt="" />{{ onePrice * resultList.length
          }} <span style="font-weight: 600">再买{{ resultList.length }}次</span> </div>
          <div class="flex-content font-12" style="color: #77d769">
            
          </div>
        </div>
        <div
          class="flex-content font-14 padding_8"
          style="color: #5A4112;font-weight: 600"
          @click="resultShow = false"
        >
          确定
        </div>
        <img
          src="../../static/180.png"
          alt=""
          class="result_quit"
          @click="resultShow = false"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      purchase: "",
      blindType: 0, //是否展示奖池 0抽奖 1奖池 2抽奖规则 3中奖记录
      jackpotList: [], //盲盒奖池
      ruleContent: "", //规则内容
      lotteryNumberList: [], //抽奖数量配置
      lotteryNumber: 10,
      selectShow: false,
      onePrice: 0, //一次抽奖价格
      resultShow: false, //中奖弹窗
      resultList: [],
      winningPage: {
        //中奖记录
        pageNum: 1,
        pageSize: 9,
      },
      winningList: [], //中奖数据
      winningTotal: 0, //中奖总数
      prizeList: [], //抽奖奖品列表
      doLotteryType:false,
    };
  },
  mounted() {
    this.lotteryRule();
    this.lotteryPrice();
    this.lotteryNumConfig();
    this.getPrizePoolList();
  },
  methods: {
    // 获取抽奖奖品列表
    getPrizePoolList() {
      this.$api.prizePoolList({ pageNum: 1, pageSize: 8 }).then((res) => {
        if (res.code == 1) {
          this.prizeList = res.data.rows;
        }
      });
    },
    // 切换页面
    blindTypeChange(type) {
      this.blindType = type;
      this.winningPage.pageNum = 1;
    },
    // 回车确认抽奖
    disableTextSubmit() {
      this.doLottery(this.lotteryNumber);
      this.lotteryNumber = "";
    },
    // 选择抽奖次数
    selectLottery(value) {
      this.lotteryNumber = value;
      this.selectShow = false;
    },
    //抽奖
    doLottery(num) {
      if(this.doLotteryType)return this.$message.info('抽奖中...')
      if(!num) return this.$message.info('请输入抽奖次数')
      this.doLotteryType = true
      this.$api.doLottery({ count: num }).then((res) => {
        if (res.code == 1) {
          this.resultShow = true;
          this.resultList = res.data;
          this.$emit('myMoney',1)
        } else {
          this.$message.info(res.msg);
        }
        this.doLotteryType = false
      });
    },
    //获取抽奖价格
    lotteryPrice() {
      this.$api.lotteryPrice({ count: 1 }).then((res) => {
        // console.log("抽奖价格", res);
        if (res.code == 1) {
          this.onePrice = res.data;
        }
      });
    },
    // 获取抽奖数量配置
    lotteryNumConfig() {
      this.$api.lotteryNumConfig().then((res) => {
        // console.log("抽奖数量配置", res);
        if (res.code == 1) {
          this.lotteryNumberList = res.data;
        }
      });
    },
    // 关闭弹窗
    closePopup() {
      this.$emit("closeBlindBox");
    },
    // 盲盒奖池列表
    prizePoolDetailList() {
      this.$api.prizePoolDetailList().then((res) => {
        console.log("盲盒奖池列表", res);
        if (res.code == 1) {
          this.jackpotList = res.data.rows;
          this.blindType = true;
        }
      });
    },
    //抽奖规则
    lotteryRule() {
      this.$api.lotteryRule().then((res) => {
        console.log("规则", res);
        if (res.code == 1) {
          this.ruleContent = res.data.content;
        }
      });
    },
    winningChange(e) {
      this.winningPage.pageNum = e;
      this.prizeRecord();
    },
    // 中奖记录
    prizeRecord() {
      this.$api.prizeRecord(this.winningPage).then((res) => {
        console.log("中奖记录", res);
        if (res.code == 1) {
          this.winningList = res.data.rows;
          this.winningTotal = res.data.total;
          this.blindType = 3;
        }
      });
    },
  },
  filters: {
    propsTypeNum: (value) => {
      let arrayData = {
        0: "麦位框",
        1: "进场秀",
        2: "聊天气泡",
        3: "勋章",
      };
      return arrayData[value];
    },
    timeFilters: (val) => {
      let array = {
        0: "小时",
        1: "天",
        2: "周",
        3: "月",
      };
      return array[val];
    },
  },
};
</script>

<style scoped lang="scss">
.img_style {
  width: 55px;
  height: 55px;
}
.blind_paging {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.result_box {
  min-width: 326px;
  min-height: 480px;
  max-width: 590px;
  max-height: 470px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: url(../../static/179.png) no-repeat;
  background-size: 100% 100%;
  .result_title {
    color: #b7643d;
    font-size: 18px;
    font-weight: bold;
  }
  .result_quit {
    width: 24px;
    height: 24px;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translate(-50%);
  }
  .result_onebg {
    width: 246px;
    height: 206px;
    margin: 15px 0;
    background: rgba($color: #bbbbbb, $alpha: 0.1);
    border-radius: 8px;
  }
  .result_btn {
    width: 250px;
    height: 40px;
    background: linear-gradient(95deg, #FFDE32 0%, #FFA200 100%);
    border-radius: 48px;
  }
  .one_img {
    width: 140px;
    height: 118px;
    margin: 10px 0;
  }
  // 多次抽奖
  .result_repeatedly {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 50px 20px 60px;
    max-width: 590px;
    max-height: 222px;
    overflow-y: auto;
    .result_item {
      width: 84px;
      height: 106px;
      margin-right: 10px;
      margin-bottom: 10px;
      
background: #F5F5F5;
      border-radius: 8px;
    }
  }
}

.pages {
  z-index: 999;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  color: #fff;
  background: rgba($color: #000000, $alpha: 0.8);
}
.evenMore {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 8px;
  background-color: #252b4a;
}
.blind_box {
  width: 860px;
  height: 460px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.box1 {
  width: 664px;
  height: 448px;
  background: #1d223b;
}
.box2 {
  width: 860px;
  height: 287px;
  background: #fff;
  .rule_text {
    color: #666;
    margin: 20px;
    font-size: 12px;
    line-height: 18px;
  }
}
.box3 {
  width: 562px;
  height: 538px;
  background: #fff;
  color: #333;
  .winning_record {
    margin: 30px 60px;
    .form_head {
      width: 441px;
      height: 40px;
      display: flex;
      color: #333;
background: #F5F5F5;
      border-radius: 4px 4px 0px 0px;
    }
    .form_tr {
      width: 441px;
      height: 40px;
      display: flex;
      color: #333;
      .tr_item {
        height: 40px;
        color: #333;
        font-size: 14px;
        padding-left: 10px;

      }
    }
    .item {
      height: 40px;
      color: #333;
      font-size: 14px;
      padding-left: 10px;
    }
  }
}
// 奖池样式
.blind_text {
  color: #f4ff5d;
  font-size: 15px;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.jackpot_box {
  width: 90%;
  height: 300px;
  margin: 20px 50px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  .jackpot_item {
    width: 84px;
    height: 130px;
    margin: 0 10px 10px 0;
    background: linear-gradient(
      180deg,
      rgba($color: #7c4ff0, $alpha: 0.2) 0%,
      rgba($color: #976eff, $alpha: 0.2) 100%
    );
    border-radius: 8px;
  }
}
//盲盒列表
.gift_box {
  width: 820px;
  height: 80px;
  padding-left: 25px;
  background: linear-gradient(92deg, #FFEDEC 0%, #FFE8CB 100%);
  border-radius: 8px 8px 8px 8px;
  margin: 20px 20px;
  box-sizing: border-box;
  color: #333;
  .gift_item {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 28px;
    > img {
      width: 40px;
      height: 40px;
      margin-bottom: 8px;
    }
  }
}
//规则
.ruleBox {
  font-size: 12px;
  color: rgba(153, 153, 153, 1);
  justify-content: end;
  margin-right: 20px;
}
//购买
.purchase_box {
  position: relative;
  flex-direction: column;
  .purchase_hint {
    color: #333;
    font-size: 10px;
    margin: 35px 0 11px 0;
  }
  .purchase_num {
    color: #999999;
    font-size: 12px;
  }
  .purchase_input {
    width: 106px;
    height: 28px;
  }
  .purchase_input /deep/.el-input__inner {
    height: 28px;
    color: #333;
    
background: #F5F5F5;
    border-radius: 8px;
    border: 1px solid #F5F5F5;
  }
  .purchase_icon {
    position: relative;
    left: -15px;
  }
  .purchase_selectBox {
    width: 66px;
    height: 144px;
    position: absolute;
    top: 40px;
    right: 32%;
    background: #fff;
    
box-shadow: 0px 3px 6px 1px rgba(0,0,0,0.16);
    border-radius: 4px;
    .select_item {
      width: 66px;
      height: 28px;
      color: #333;
      font-size: 12px;
    }
    .select_pitch {
      background: rgba(255, 221, 0, 1);
    }
  }
  .lotteryBtn1 {
    width: 92px;
    padding:0px 14px;
    height: 32px;
    margin-bottom: 9px;
    background: rgba(255, 221, 0, 0.08);
    border-radius: 4px;
    
color: #333333;
  }
  .lotteryBtn2 {
    height: 32px;
    padding:0px 14px;
    margin-bottom: 9px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
    color: #333;
  }
}
.titleIcon2 {
  width: 100%;
  height: 6px;
  border-radius: 4px 4px 0px 0px;
  background: linear-gradient(270deg, #FFDD00 0%, #FFA200 100%);
}
/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: RGBA(255, 221, 0, 1);
  color: #333;
}
</style>
