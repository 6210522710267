<template>
  <div class="cropper-content">
    <div class="cropper-box">
      <div class="cropper">
        <vue-cropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :full="option.full"
          :fixedBox="option.fixedBox"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :centerBox="option.centerBox"
          :height="option.height"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="option.mode"
          @realTime="realTime"
          @imgLoad="imgLoad"
        >
        </vue-cropper>
      </div>
      <!--底部操作工具按钮-->
      <div class="uploa_btn flex-content" @click="uploadImg('blob')">
        确认裁剪
      </div>
    </div>
    <!--预览效果图-->
    <div class="show-preview">
      <div :style="previews.div" class="preview">
        <img :src="previews.url" :style="previews.img" />
      </div>
    </div>
  </div>
</template>
  
  <script>
import { VueCropper } from "vue-cropper";
export default {
  name: "CropperImage",
  components: {
    VueCropper,
  },
  props: ["Name", "img"],
  data() {
    return {
      name: this.Name,
      previews: {},
      option: {
        img: this.img, //裁剪图片的地址
        outputSize: 1, //裁剪生成图片的质量(可选0.1 - 1)
        outputType: "jpeg", //裁剪生成图片的格式（jpeg || png || webp）
        info: true, //图片大小信息
        canScale: true, //图片是否允许滚轮缩放
        autoCrop: true, //是否默认生成截图框
        autoCropWidth: 130, //默认生成截图框宽度
        autoCropHeight: 130, //默认生成截图框高度
        fixed: true, //是否开启截图框宽高固定比例
        fixedNumber: [1, 1], //截图框的宽高比例
        full: false, //false按原比例裁切图片，不失真
        fixedBox: true, //固定截图框大小，不允许改变
        canMove: false, //上传图片是否可以移动
        canMoveBox: true, //截图框能否拖动
        original: false, //上传图片按照原始比例渲染
        centerBox: false, //截图框是否被限制在图片里面
        height: true, //是否按照设备的dpr 输出等比例图片
        infoTrue: false, //true为展示真实输出图片宽高，false展示看到的截图框宽高
        maxImgSize: 3000, //限制图片最大宽度和高度
        enlarge: 1, //图片根据截图框输出比例倍数
        mode: "230px 150px", //图片默认渲染方式
      },
    };
  },
  mounted() {
    // console.log("头像", this.img);
  },
  methods: {
    //初始化函数
    imgLoad(msg) {
      // console.log("工具初始化函数=====" + msg);
    },
    //图片缩放
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    //向左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    //向右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    //实时预览函数
    realTime(data) {
      this.previews = data;
    },
    //选择图片
    selectImg(e) {
      let file = e.target.files[0];
      if (!/.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$message({
          message: "图片类型要求：jpeg、jpg、png",
          type: "error",
        });
        return false;
      }
      //转化为blob
      let reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.option.img = data;
      };
      //转化为base64
      reader.readAsDataURL(file);
    },
    //上传图片
    uploadImg(type) {
      let _this = this;
      if (type === "blob") {
        this.$refs.cropper.stopCrop((data) => {
        });
        // 获取截图的base64 数据
        this.$refs.cropper.getCropData((data) => {
          // do something
          // console.log("getCropData", data);
          var base64String = data; /*base64图片串*/

          //这里对base64串进行操作，去掉url头，并转换为byte
          var bytes = window.atob(base64String.split(",")[1]);
          var ab = new ArrayBuffer(bytes.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i); //这里有点疑惑，ia是怎么改变ab的？注：①
          }
          //Blob对象
          var blob = new Blob([ab], { type: "image/jpeg" }); //type为图片的格式

          //FormData对象
          var fd = new FormData();
          //TDOD Ajax或者其他方式上传FormData对象
          //FormData对象接受三个参数，第三个参数为文件名，通常我们只传前两个参数，第三个参数不传则使用默认文件名，这里使用的Blob对象，所以需要一个文件名，用时间戳代替。
          fd.append("file", blob, Date.now() + ".jpg");
          this.$api.fileUploadAli(fd).then((res) => {
            if (res.code == 1) {
              this.$emit("avatarClose", false);
              this.$api.saveUserInfo({ avatar: res.data.url }).then((res) => {
                if (res.code == 1) {
                  this.$message.info(res.msg);
                } else {
                  this.$message.info(res.msg);
                }
              });
            }
          });
        });

        // 获取截图的blob数据
        this.$refs.cropper.getCropBlob((data) => {
          // do something
        });

        //获取截图的blob数据
        // this.$refs.cropper.getCropBlob(async (data) => {
        //   console.log("data", data);
        //   let formData = new FormData();
        //   formData.append("file", data, "DX.jpg");
        //   console.log("formData", formData);
        //   //调用axios上传
        //   this.$api.fileUploadAli({ file: formData.append }).then((res) => {
        //     console.log("文件上传", res);
        //   });
        // });
      }
    },
  },
};
</script>
  
<style scoped lang="scss">
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .cropper-box {
    flex: 1;
    width: 100%;
    .cropper {
      width: auto;
      height: 300px;
    }
  }

  .show-preview {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    .preview {
      overflow: hidden;
      border: 1px solid #67c23a;
      background: #cccccc;
    }
  }
}
.uploa_btn {
  width: 176px;
  height: 40px;
  color: #333;
  margin-top: 30px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(60deg, #fee338 0%, #ffeb72 100%);
  border-radius: 4px;
}
</style>
  