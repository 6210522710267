<template>
  <div class="userpage">
    <!-- 完善资料 -->
    <div class="popup_data">
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14">完善资料</div>
        <img src="../../static/quit.png" alt="" @click="skipHome()" />
      </div>
      <!-- 头部end -->
      <!-- 用户信息 -->
      <div class="userData" v-if="step == 0">
        <!-- 头像 -->

        <div class="head_box">
          <el-upload
            action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
            :show-file-list="false"
            list-type="picture-card"
            :on-success="headImgSuccess"
          >
            <div class="head_box" v-if="headImg">
              <img :src="headImg" alt="" class="head" />
            </div>
            <img
              src="../../static/104.png"
              alt=""
              class="head"
              v-if="!headImg"
            />
            <img src="../../static/105.png" alt="" class="select_head" />
          </el-upload>
        </div>
        <!-- 头像 -->
        <div class="flex-content" style="margin-top: 26px">
          <div class="headline">性别</div>
          <div class="flex-content" style="padding-right: 40px">
            <img
              src="../../static/106.png"
              alt=""
              class="radio_icon"
              v-if="sex == 1"
              @click="sex = 0"
            />
            <img
              src="../../static/107.png"
              alt=""
              class="radio_icon"
              v-else
              @click="sex = 1"
            />
            <p style="padding: 0 9px">男生</p>
            <img src="../../static/108.png" alt="" />
          </div>
          <div class="flex-content">
            <img
              src="../../static/107.png"
              alt=""
              class="radio_icon"
              v-if="sex == 1"
              @click="sex = 0"
            />
            <img
              src="../../static/106.png"
              alt=""
              class="radio_icon"
              v-else
              @click="sex = 1"
            />
            <p style="padding: 0 9px">女生</p>
            <img src="../../static/109.png" alt="" />
          </div>
        </div>
        <!-- 昵称 -->
        <div class="flex-content" style="margin-top: 25px">
          <div class="headline">昵称</div>
          <input
            type="text"
            maxlength="8"
            placeholder="请输入昵称，最长8个字符"
            class="name_input"
            v-model="userName"
          />
        </div>
        <!-- 生日 -->
        <div class="flex-content" style="margin-top: 25px">
          <div class="headline">生日</div>
          <el-date-picker
            v-model="birthday"
            type="date"
            class="optionData"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>

        <div
          class="flex-content font-14"
          style="color: rgba(65, 119, 221, 1); margin-top: 29px"
          @click="skipHome()"
        >
          暂时跳过
        </div>
        <div class="nextStep flex-content" @click="editData()">下一步</div>
      </div>

      <!-- 个人标签 -->
      <div class="label_box" v-if="step == 1">
        <div class="flex">
          <div class="font-16" style="margin-right: 18px; color: #333">
            请选择标签，将展示于个人主页
          </div>
          <div class="fomt-12" style="color: #8e92b1">最多选择6个</div>
        </div>
        <!-- 标签 -->
        <div class="label flex_wrap">
          <div
            class="label_item flex-content"
            v-for="item in personList"
            :key="item.id"
            @click="changelabel(item.id)"
            :class="{ label_path: pitchOnList.includes(item.id) }"
            :style="
              pitchOnList.includes(item.id)
                ? 'background:rgba(255, 221, 0, 1)'
                : ''
            "
          >
            {{ item.text }}
          </div>
        </div>
        <!-- 标签end -->
        <div
          class="font-14 flex-content suspendStyle"
          style="color: rgba(65, 119, 221, 1)"
          @click="skipHome()"
        >
          暂时跳过
        </div>
        <div class="flex step_box">
          <!-- <div class="flex-content back_box" @click="step--">上一步</div> -->
          <div
            class="nextStep flex-content"
            @click="saveUserLabe('persona')"
          >
            下一步
          </div>
        </div>
      </div>

      <!-- 游戏兴趣标签 -->
      <div class="label_box" v-if="step == 2">
        <div class="flex">
          <div class="font-16" style="margin-right: 18px; color: #333">
            请选择你感兴趣标签，相关内容将优先推荐
          </div>
          <div class="fomt-12" style="color: #8e92b1">最多选择6个</div>
        </div>
        <!-- 标签 -->
        <div class="label flex_wrap">
          <div
            class="label_imgItem flex-dir"
            v-for="(item, key1) in interestList"
            :key="key1"
            @click="changelabel(item.id)"
            :class="{ label_path: pitchOnList.includes(item.id) }"
          >
            <img :src="item.logo" alt="" style="width: 48px; height: 48px" />
            <p>{{ item.name }}</p>
          </div>
        </div>
        <!-- 标签end -->
        <div
          class="font-14 flex-content suspendStyle"
          style="color: rgba(65, 119, 221, 1)"
          @click="skipHome()"
        >
          暂时跳过
        </div>
        <div class="flex step_box">
          <!-- <div class="flex-content back_box" @click="step--">上一步</div> -->
          <div
            class="nextStep flex-content"
            @click="saveUserLabe('interest')"
          >
            下一步
          </div>
        </div>
      </div>

      <!-- 类型兴趣标签 -->
      <div class="label_box" v-if="step == 3">
        <div class="flex">
          <div class="font-16" style="margin-right: 18px; color: #333">
            请选择你感兴趣标签，相关内容将优先推荐
          </div>
          <div class="fomt-12" style="color: #8e92b1">最多选择6个</div>
        </div>
        <!-- 标签 -->
        <div class="label flex_wrap">
          <div
            class="label_item flex-content"
            v-for="item in voiceList"
            :key="item.id"
            @click="changelabel(item.id)"
            :style="
              pitchOnList.includes(item.id)
                ? 'background:rgba(255, 221, 0, 1)'
                : ''
            "
          >
            {{ item.text }}
          </div>
        </div>
        <!-- 标签end -->
        <div
          class="font-14 flex-content suspendStyle"
          style="color: rgba(65, 119, 221, 1)"
          @click="skipHome()"
        >
          暂时跳过
        </div>
        <div class="flex step_box">
          <!-- <div class="flex-content back_box" @click="step--">上一步</div> -->
          <div
            class="nextStep flex-content"
            @click="saveUserLabe('voice')"
          >
            下一步
          </div>
        </div>
      </div>

      <!-- 推荐关注人员 -->
      <div class="label_box" v-if="step == 4" style="width: 400px">
        <div class="flex">
          <div class="font-16" style="margin-right: 18px; color: #333">
            猜你喜欢，您可关注喜欢的达人
          </div>
        </div>
        <!-- 人员列表 -->
        <div class="people_box">
          <div
            class="people_item flex-content"
            v-for="(item, key3) in manitoList"
            :key="key3"
            @click="manitoChange(key3)"
          >
            <div class="flex">
              <img :src="item.avatar" alt="" class="people_head" />
              <div class="flex-between">
                <div class="flex-aling">
                  <p class="font-14" style="color: #333">{{ item.nickname }}</p>
                  <div class="ageSex_box font-10 flex-content">
                    <img
                      src="../../image/message/24.png"
                      alt=""
                      v-if="item.sex == 1"
                    />
                    <img src="../../image/message/25.png" alt="" v-else />
                    {{ item.age }}
                  </div>
                </div>
                <div class="font-12" style="color: #6a6a94">
                  ID：{{ item.userNo }}
                </div>
              </div>
            </div>
            <!-- 选中与未选中 -->
            <img src="../../static/107.png" alt="" v-if="item.pitch" />
            <img style="width:14px;height:14px" src="../../static/106.png" alt="" v-else />
          </div>
        </div>
        <!-- 人员列表end -->
        <div
          class="font-14 flex-content suspendStyle"
          style="color: rgba(65, 119, 221, 1)"
          @click="skipHome()"
        >
          暂时跳过
        </div>
        <div class="flex step_box">
          <!-- <div class="flex-content back_box" @click="step--">上一步</div> -->
          <div class="labe_nextStep flex-content" @click="allfollowUser()" style="width:400px">
            一键关注
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      step: 0,
      headImg: "", //用户头像
      sex: 0, //0男 1女
      userName: "", //用户名称
      birthday: "", //生日
      labelList: ["可爱", "御姐", "霸道", "热情", "敏感", "开朗"],
      personList: [], //个人标签列表
      voiceList: [], //声音标签列表
      interestList: [], //兴趣标签
      pitchOnList: [], //选中
      manitoList: [], //猜大神列表
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }, //myq
    };
  },
  mounted() {
    this.getPersonLabel();
    this.guessLike();
  },
  methods: {
    // 一键关注
    allfollowUser() {
      let ids = [];
      this.manitoList.forEach((item) => {
        if (item.pitch) {
          ids.push(item.id);
        }
      });
      if(ids.length==0){
        this.$router.push("/home");
      }else{
        this.$api.followUser({ followUserIds: ids.toString(),groupId:-1}).then((res) => {
        if (res.code == 1) {
          this.$message.success("关注成功");
          setTimeout(() => {
            this.$router.push("/home");
          }, 800);
        }
      });
      }
      
    },
    // 选择大神
    manitoChange(index) {
      this.manitoList[index].pitch = !this.manitoList[index].pitch;
    },
    // 下一步
    nextStepChange(type) {
      this.step = type;
      this.pitchOnList = [];
    },
    // 编辑个人基础资料
    editData() {
      // console.log("userInfo", this.userInfo);
      if (!this.headImg) return this.$message.info("请上传头像");
      if (!this.userName) return this.$message.info("请输入昵称");
      this.$api
        .saveUserInfo({
          avatar: this.headImg, //头像
          nickname: this.userName, //用户昵称
          birthday: this.birthday, //生日（yyyy-MM-dd）
          sex: this.sex, //	性别（0-男 1-女）
        })
        .then((res) => {
          if (res.code == 1) {
            this.step++;
          } else {
            this.$message.info(res.msg);
          }
        });
    },
    // 头像上传
    headImgSuccess(res) {
      if (res.code == 1) {
        this.headImg = res.data.url;
      }
    },
    // 保存用户信息
    saveUserLabe(type) {
      let persona = "";
      let interest = "";
      let likeVoice = "";
      if (type == "persona") {
        persona = this.pitchOnList.toString();
      } else if (type == "voice") {
        likeVoice = this.pitchOnList.toString();
      } else if (type == "interest") {
        interest = this.pitchOnList.toString();
      }
      this.$api
        .saveUserInfo({
          personalTagIds: persona, //个人标签
          interestLabelIds: interest, //感兴趣标签ids
          likeVoiceIds: likeVoice, //喜欢的声音ids
        })
        .then((res) => {
          // console.log(res);
          if (res.code == 1) {
            this.pitchOnList = [];
            this.step++;
          }
        });
    },
    // 标签选择·
    changelabel(i) {
      if (this.pitchOnList.includes(i)) {
        //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
        //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
        this.pitchOnList = this.pitchOnList.filter(function (ele) {
          return ele != i;
        });
      } else {
        this.pitchOnList.push(i);
      }
      if (this.pitchOnList.length > 6) {
        this.pitchOnList.shift();
      }
    },
    // 猜你喜欢大神列表
    guessLike() {
      this.$api.guessLike({ pageNum: 1, pageSize: 999 }).then((res) => {
        if (res.code == 1) {
          this.manitoList = [];
          res.data.forEach((item) => {
            item.pitch = false;
            this.manitoList.push(item);
          });
        }
      });
    },
    // 获取标签列表
    getPersonLabel() {
      this.$api.getUserPersonalTag().then((res) => {
        // console.log("个人标签列表", res);
        if (res.code == 1) {
          this.personList = res.data;
        }
      });
      this.$api.getUserVoiceTag().then((res) => {
        // console.log("声音标签列表", res);
        if (res.code == 1) {
          this.voiceList = res.data;
        }
      });
      this.$api.getInterestTags().then((res) => {
        // console.log("兴趣标签列表", res);
        if (res.code == 1) {
          this.interestList = res.data;
        }
      });
    },
    // 返回首页
    skipHome() {
      this.$router.push("/home");
    },
  },
};
</script>
<style scoped lang="scss">
.label_path {
  color: #333333;
  background-color: rgba(255, 221, 0, 1);
}
.userpage {
  width: 1920px;
  height: 1080px;
  color: #ffffff;
  background: rgba(0, 0, 0, 1);
  position: absolute;
  top: 0;
  left: 0;
}
.popup_data {
  height: 561px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//个人资料
.userData {
  padding: 20px 60px 30px 60px;
  color: #86909b;
  font-size: 18px;
  margin-top: 53px;
  .head_box {
    width: 70px;
    margin: auto;
    position: relative;
    .head {
      width: 69px;
      height: 69px;
      border-radius: 50%;
    }
    .select_head {
      width: 14px;
      height: 14px;
      position: absolute;
      right: 5px;
      bottom: 10px;
    }
  }
  .headline {
    color: #d3d4dc;
    margin-right: 13px;
  }
  .name_input {
    width: 231px;
    border: 0;
    font-size: 18px;
    padding: 11px 0;
    color: #000;
    background: transparent;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
  }
  .optionData {
    background: rgba(245, 245, 245, 1);
  }
  .optionData /deep/.el-input__inner {
    color: #333;
  }
  .nextStep {
    width: 382px;
    height: 40px;
    margin: auto;
    color: #333;
    margin-top: 47px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
  }
}
.nextStep {
  width: 382px;
  height: 40px;
  margin: auto;
  color: #333;
  margin-top: 47px;
  background: rgba(255, 221, 0, 1);
  border-radius: 4px;
}
//个人标签
.label_box {
  padding: 20px 60px 30px 60px;
  .label {
    width: 410px;
    max-height: 300px;
    margin-top: 20px;
    overflow-y: scroll;
    .label_item {
      width: 88px;
      height: 36px;
      font-size: 14px;
      color: #333;
      border-radius: 4px;
      margin: 0 10px 10px 0;
      background-color: rgba(245, 245, 245, 1);
    }
    .label_imgItem {
      width: 88px;
      height: 90px;
      font-size: 12px;
      color: #8e92b1;
      border-radius: 4px;
      margin: 0 10px 10px 0;
      > img {
        width: 40px;
        height: 40px;
        margin-bottom: 9px;
      }
    }
  }
  .labe_nextStep {
    width: 256px;
    height: 40px;
    background: rgba(255, 221, 0, 1);
    border-radius: 4px;
    color: #000;
  }
  .back_box {
    width: 96px;

    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #ffa700;
    font-size: 14px;
    margin-right: 33px;
    background: rgba(255, 167, 0, 0.1);
  }
  .step_box {
    position: absolute;
    bottom: 30px;
  }
  .suspendStyle {
    position: absolute;
    left: 50%;
    bottom: 120px;
    transform: translateX(-50%);
  }
}
.people_box {
  max-height: 300px;
  margin-top: 16px;
  padding-right: 10px;
  overflow-y: scroll;
  .people_item {
    width: 378px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0 20px 0 15px;
    box-sizing: border-box;
    .people_head {
      width: 48px;
      height: 48px;
      border-radius: 4px;
      margin-right: 10px;
    }
  }
}
.ageSex_box {
  width: 40px;
  height: 18px;
  margin-left: 5px;

  background: #85bcfe;
  border-radius: 15px;
  > img {
    margin: 0 2px 0 0;
  }
}
.radio_icon {
  width: 14px;
  height: 14px;
}
/deep/.el-upload--picture-card {
  width: 0;
  height: 90px;
  background-color: transparent;
  border: 0;
}
/deep/.el-input__inner {
  background-color: rgba(245, 245, 245, 1);
  border: 0;
  color: #000;
}
</style>
