<template>
  <div class="enter_page">
    <!-- 认证大神 -->
    <div class="enter_box" v-if="!auditHintShow">
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14">成为大神</div>
        <img src="../../static/quit.png" alt="" @click="enterClose()" />
      </div>
      <!-- 头部end -->
      <div class="enter_applyFor flex-dir" v-if="enterType == 0">
        <img src="../../static/191.png" alt="" />
        <div class="enter_applyForBtn flex-content" @click="enterType++">
          认证大神
        </div>
      </div>
      <!-- 技能认证 -->
      <div class="manito_formBox" v-if="enterType == 1">
        <!-- 步骤 -->
        <div class="step flex-content">
          <div class="step_item flex-dir">
            <div class="step_icon flex-content">1</div>
            <div style="color: #77d769">实名认证</div>
          </div>
          <img src="../../image/my/9.png" alt="" class="when_icon" />
          <div class="step_item flex-dir">
            <div class="step_icon flex-content">2</div>
            <div style="color: #77d769">技能认证</div>
          </div>
          <img src="../../image/my/10.png" alt="" class="when_icon" />
          <div class="step_item flex-dir">
            <div class="step_icon flex-content" style="background: #363659">
              3
            </div>
            <div style="color: rgba(0, 0, 0, 1)">资料更新</div>
          </div>
          <img src="../../image/my/10.png" alt="" class="when_icon" />
          <div class="step_item flex-dir">
            <div class="step_icon flex-content" style="background: #363659">
              4
            </div>
            <div style="color: rgba(0, 0, 0, 1)">平台审核</div>
          </div>
        </div>
        <!-- 步骤end -->
        <!-- 技能列表 -->
        <div class="flex">
          <div
            class="skill_tab"
            :class="skillType == 1 ? '' : 'skill_tabPitch'"
            @click="skillChange(1)"
          >
            游戏类技能
          </div>
          <div
            class="skill_tab"
            :class="skillType == 2 ? '' : 'skill_tabPitch'"
            @click="skillChange(2)"
          >
            娱乐类技能
          </div>
        </div>
        <div class="skill_box">
          <div
            class="skill_item flex-dir"
            v-for="(item, index) in skillType == 1
              ? gameLabels
              : amusementLabels"
            :key="index"
            :class="twoSkill.id == item.id ? 'skill_pitch' : ''"
            @click="twoSkillChange(item)"
          >
            <img :src="item.logo" alt="" class="label_img" />
            <div class="itemText">{{ item.name }}</div>
          </div>
        </div>
        <!-- 技能列表end -->
        <div class="font-16" style="margin-top: 20px; color: #000">
          技能认证
        </div>
        <div class="attestation_text">
          <div style="color: #d3d4dc">认证要求</div>
          <div>
            技能资格说明
            此图认证要求如下：1、电脑清晰截图、不能手机拍摄；2、游戏昵称与分数清晰；3、如需考核应上此号进行考核
          </div>
        </div>
        <div class="font-16 flex-aling">
          <div>认证资料图</div>
          <div class="font-12 padding_r4" style="color: #6a6a94">
            (禁止上传盗图，一经发现封号处理)
          </div>
          <div class="font-12" style="margin-left: 66px">
            具体截图页面可参考下方示例图片
          </div>
        </div>
        <div class="flex">
          <img
            :src="this.skillsForm.certificationImg"
            alt=""
            class="img_uploading"
            v-if="this.skillsForm.certificationImg"
          />
          <el-upload
            action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
            list-type="picture-card"
            class="upload_certification"
            accept=".png,.jpg.jpeg"
            :limit="1"
            :show-file-list="false"
            :on-success="certificationSuccess"
            v-else
          >
            <img src="../../static/166.png" alt="" class="img_uploading" />
          </el-upload>
          <!-- <img :src="twoSkill.authEgImg" v-if="twoSkill.authEgImg" alt="" class="img_uploading" /> -->
          <el-image
            class="img_uploading"
            :src="twoSkill.authEgImg"
            v-if="twoSkill.authEgImg"
            :preview-src-list="srcList"
          >
          </el-image>
        </div>
        <div class="font-16 flex-aling">
          <div>语音介绍</div>
          <div class="font-12 padding_r4" style="color: #6a6a94">
            录制一段自我介绍，5-15s即可
          </div>
        </div>
        <div class="flex-aling" style="margin-top: 10px">
          <el-upload
            class="upload_soundRecording"
            action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
            list-type="picture-card"
            :show-file-list="false"
            :on-success="soundRecordingSuccess"
            :before-upload="beforeAvatarUpload"
            accept="video/*,audio/*"
          >
            <div class="file_uploading flex-content">
              <img
                src="../../static/167.png"
                alt=""
                style="margin-right: 10px"
              />
              选择文件
            </div>
          </el-upload>
          <div class="font-12" style="color: rgba(255, 42, 62, 1)">
            支持MP3格式
          </div>
        </div>
        <div class="flex-aling" style="margin: 16px 0 20px 0; color: #000">
          <div class="audio_file flex-aling" v-if="skillsForm.voiceName">
            <div class="flex" style="align-content: center;">
              <img style="width:20px;height:20px;margin-top:10px" src="../../static/125.png" alt="" />
              <div>{{ skillsForm.voiceName }}</div>
            </div>
            <div style="color: #8e92b1">
              <!-- 1MB -->
              <img src="../../static/127.png" alt="" @click="delVoiceChange" />
            </div>
          </div>
          <!-- 文件信息end -->
          <!-- <div class="font-12" style="color: #cf8234; margin-left: 12px">
            校验中…
          </div>
          <img
            src="../../static/126.png"
            alt=""
            style="margin-left: 7px"
            class="spin_longin"
          /> -->
        </div>
        <div style="color: #000">接单信息</div>
        <div class="form_box">
          <el-form ref="form" :model="skillsForm" label-width="90px">
            <el-form-item label="技能等级：" style="color: #000">
              <el-select
                v-model="skillsForm.levelId"
                placeholder="请选择技能等级"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in gradeList"
                  :key="index"
                  :label="item.levelText"
                  :value="item.levelId"
                >
                </el-option>
              </el-select>
              <!-- <span style="color:#FF2A3E;margin-left:40px">请填写真实姓名</span> -->
            </el-form-item>
            <el-form-item
              label="接单单价："
              class="color_fff"
              style="position: relative"
            >
              <el-select
                v-model="skillsForm.priceId"
                placeholder="请选择接单单价"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in unitPriceList"
                  :key="index"
                  :label="item.priceText"
                  :value="item.priceId"
                >
                </el-option>
              </el-select>
              <span
                class="flex-aling price_description"
                @click.stop="priceDescriptionChange()"
                >价格说明 <img src="../../static/192.png" alt=""
              /></span>
            </el-form-item>
            <el-form-item label="接单时间：" class="color_fff">
              <el-input
                v-model="skillsForm.ordersTime"
                placeholder="请输入接单时间"
              ></el-input>
            </el-form-item>
            <el-form-item label="接单大区：" class="color_fff">
              <!-- <el-input
                v-model="skillsForm.ordersDistrict"
                placeholder="请输入接单区服"
              ></el-input> -->
              <el-input
                v-model="skillsForm.ordersDistrict"
                placeholder="请输入接单区服"
              ></el-input>
            </el-form-item>

            <el-form-item label="擅长位置：" class="color_fff">
              <el-input
                v-model="skillsForm.goodLocation"
                placeholder="请输入擅长位置"
              ></el-input>
            </el-form-item>
            <el-form-item label="接单说明：" class="color_fff">
              <el-input
                type="textarea"
                v-model="skillsForm.ordersRemark"
                placeholder="请输入您的接单宣言"
                resize="none"
                maxlength="35"
                class="user_textarea"
                :rows="5"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <div class="font-12 flex-aling" style="width: 110%">
                <img
                  src="../../static/107.png"
                  alt=""
                  v-if="whetherRead"
                  style="width: 12px; height: 12px"
                  @click="whetherRead = !whetherRead"
                />
                <img
                  src="../../static/106.png"
                  alt=""
                  v-else
                  style="width: 12px; height: 12px"
                  @click="whetherRead = !whetherRead"
                />
                <span style="margin-left: 4px; color: #77789c"
                  >勾选并提交资料即代表你已阅读并同意</span
                >
                <span style="color: #c2702e" @click.stop="agreementChange()"
                  >《大神认证协议》</span
                >
              </div>
              <div class="form_btn flex-content" @click="userSkills()">
                提交审核
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 资料更新 -->
      <div class="manito_formBox" v-if="enterType == 2">
        <!-- 步骤 -->
        <div class="step flex-content">
          <div class="step_item flex-dir">
            <div class="step_icon flex-content">1</div>
            <div style="color: #77d769">实名认证</div>
          </div>
          <img src="../../image/my/9.png" alt="" class="when_icon" />
          <div class="step_item flex-dir">
            <div class="step_icon flex-content">1</div>
            <div style="color: #77d769">技能认证</div>
          </div>
          <img src="../../image/my/9.png" alt="" class="when_icon" />
          <div class="step_item flex-dir">
            <div class="step_icon flex-content">3</div>
            <div style="color: #77d769">资料更新</div>
          </div>
          <img src="../../image/my/10.png" alt="" class="when_icon" />
          <div class="step_item flex-dir">
            <div class="step_icon flex-content" style="background: #363659">
              4
            </div>
            <div style="color: #333">平台审核</div>
          </div>
        </div>
        <!-- 步骤end -->
        <div class="userData_box">
          <div class="font-16 flex-aling">
            <div>基本资料</div>
            <div class="font-12 padding_r4" style="color: #6a6a94">
              完善您的信息，丰富你的主页
            </div>
          </div>
          <div class="form_box">
            <el-form ref="form" :model="skillsForm" label-width="90px">
              <el-form-item label="头像:" class="color_fff">
                <el-upload
                  class="avatar-uploader"
                  action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  accept=".png,.jpg.jpeg"
                >
                  <img
                    :src="userInfo.avatar"
                    alt=""
                    v-if="userInfo.avatar"
                    class="user_headImg"
                  />
                </el-upload>
              </el-form-item>
              <el-form-item label="昵称:" class="color_fff">
                <el-input v-model="userInfo.nickname" maxlength="12"></el-input>
              </el-form-item>
              <el-form-item label="性别:">
                <el-radio-group v-model="userInfo.sex">
                  <el-radio :label="0">男</el-radio>
                  <el-radio :label="1">女</el-radio>
                </el-radio-group>
                <span style="color: #ff2a3e; margin-left: 40px"
                  >仅允许修改一次</span
                >
              </el-form-item>
              <el-form-item label="生日:">
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="userInfo.birthday"
                  style="width: 100%"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="所在城市:">
                <div class="cascader_box">
                  <div
                    class="selector_cascader"
                    @click="cascaderShow = !cascaderShow"
                  >
                    {{ this.userInfo.city }}
                  </div>
                  <div class="cascaderItemBox flex-aling" v-if="cascaderShow">
                    <ul class="father_box">
                      <li
                        class="father_item"
                        v-for="(father, key0) in cityOptions"
                        :key="key0"
                        :style="{
                          background:
                            fatherkey == key0 ? 'rgba(255, 167, 0, 0.20)' : '',
                        }"
                        @click.stop="fatherChange(father, key0)"
                      >
                        {{ father.provinceName }}
                        <img
                          v-if="fatherkey == key0"
                          src="../../static/62.png"
                          alt=""
                          class="cascader_icon"
                        />
                      </li>
                    </ul>
                    <ul class="father_box" v-if="cityObj">
                      <li
                        class="father_item"
                        v-for="(cityItem, key1) in cityObj"
                        :key="key1"
                        :style="{
                          background:
                            fatherkey == key1 ? 'rgba(255, 167, 0, 0.20)' : '',
                        }"
                        @click.stop="cityChange(cityItem)"
                      >
                        {{ cityItem.cityName }}
                        <img
                          v-if="fatherkey == key1"
                          src="../../static/62.png"
                          alt=""
                          class="cascader_icon"
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="签名:">
                <el-input
                  type="textarea"
                  v-model="userInfo.personalSignature"
                  placeholder="请选择个人签名"
                  resize="none"
                  maxlength="35"
                  class="user_textarea"
                  :rows="2"
                ></el-input>
              </el-form-item>
              <el-form-item label="QQ:" class="color_fff">
                <el-input
                  v-model="userInfo.qqNumber"
                  placeholder="请输入QQ号"
                ></el-input>
              </el-form-item>
              <el-form-item label="标签:" class="color_fff" style="color: #000">
                <div class="flex-aling" style="flex-wrap: wrap">
                  <div
                    v-for="(item, index) in userInfo.tagNames"
                    :key="index"
                    class="label_style"
                  >
                    {{ item }}
                  </div>
                  <img
                    src="../../image/my/24.png"
                    alt=""
                    style="width: 20px; max-height: 20px"
                  />
                </div>
              </el-form-item>
              <el-form-item label="照片墙:" class="color_fff">
                <div class="photowall_box">
                  <div
                    v-for="(item, index) in userInfo.imgs"
                    :key="index"
                    style="position: relative"
                  >
                    <img :src="item.img" alt="" class="photowall_item" />
                    <img
                      src="../../image/my/19.png"
                      alt=""
                      @click="delImg(item)"
                      style="position: absolute; top: 8px; right: 18px"
                    />
                  </div>
                  <el-upload
                    class="avatar-uploader"
                    action="https://ser.t9cp.com/tjyy-app/api/v1/common/fileUploadAli"
                    :show-file-list="false"
                    :on-success="photoWallSuccess"
                    accept=".png,.jpg.jpeg"
                    v-if="userInfo.imgs.length < 6"
                  >
                    <img src="../../image/my/18.png" alt="" />
                  </el-upload>
                </div>
              </el-form-item>
              <el-form-item>
                <div class="form_btn flex-content" @click="amendUser()">
                  提交
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!-- 提示弹窗 -->
    <div class="enter_box" v-if="auditHintShow">
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14">大神认证</div>
        <img src="../../static/quit.png" alt="" @click="enterClose()" />
      </div>
      <!-- 头部end -->
      <div class="flex-dir" style="width: 520px; height: 320px">
        <img src="../../static/128.png" alt="" />
        <div style="color: #77d769; font-size: 14px; margin: 20px 0 11px 0">
          大神认证已提交
        </div>
        <div class="flex-dir" style="color: #8e92b1; font-size: 12px">
          <p>您的大神认证申请已提交，</p>
          <p>
            我们会在1-3个工作日对您提交的资料进行审核，审核通过您即可正常接单
          </p>
        </div>
        <div
          class="enter_applyForBtn flex-content"
          style="width: 176px; height: 40px"
          @click="enterClose()"
        >
          知道了
        </div>
      </div>
    </div>
    <div class="popUp_box" v-if="explainShow">
      <!-- 价格说明弹窗 -->
      <div class="enter_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">价格说明</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="explainShow = false"
          />
        </div>
        <!-- 头部end -->
        <div v-html="explainText" class="textBox"></div>
      </div>
    </div>
    <!-- 大神认证协议弹窗 -->
    <div class="popUp_box" v-if="agreementShow">
      <div class="enter_box">
        <!-- 头部start -->
        <div class="titleIcon"></div>
        <div class="title_content">
          <div class="font-14">大神认证协议</div>
          <img
            src="../../static/quit.png"
            alt=""
            @click="agreementShow = false"
          />
        </div>
        <!-- 头部end -->
        <div v-html="agreementText" class="textBox" style="width: 700px"></div>
      </div>
    </div>
  </div>
</template>
<script>
import cityData from "@/common/areadata/city.json";
export default {
  name: "",
  components: {},
  data() {
    return {
      agreementShow: false, //大神弹窗
      agreementText: "", //大神协议
      whetherRead: false, //协议阅读
      explainShow: false, //价格说明弹窗
      explainText: "",
      auditHintShow: false, //审核提示
      enterType: 0,
      skillsForm: {
        price: "", //陪玩价格
        priceId: "", //陪玩价格Id
        id: "", //id
        userId: "", //用户Id
        labelId: "", //标签id
        levelId: "", //	标签所属游戏段位Id
        levelName: "", //段位名称
        districtId: "", //标签所属游戏区服id
        certificationImg: "", //标签所属游戏技能认证截图
        voiceFile: "", //语音介绍文件
        voiceLen: "", //语音时长（单位：秒）
        ordersTime: "", //接单时间
        ordersDistrict: "", //接单大区
        goodLocation: "", //擅长位置
        ordersRemark: "", //接单说明
      },
      userInfo: {
        avatar: "", //头像
        birthday: "", //头像
        city: "", //头像
        nickname: "", //头像
        personalSignature: "", //头像
        sex: "", //头像
        imgs: [],
      }, //用户信息
      skillType: 1, //技能类型 1游戏 2娱乐
      srcList: [], //预览
      twoSkill: {}, //二级技能
      gameLabels: [], //游戏标签
      amusementLabels: [], //娱乐标签
      gradeList: [], //技能等级列表
      unitPriceList: [], //接单单价
      serverList: [], //区服列表
      audioDuration: "",
      cityOptions: [], //地区列表
      cascaderShow: false, //级联选择器
      cascaderShow: false, //级联选择器
      fatherkey: "", //选中父级
      cityObj: {},
      loadType: true, //照片墙上麦load
    };
  },
  mounted() {
    console.log("this.$route.query.type", this.$route.query.type);
    if (this.$route.query.type) {
      this.enterType = this.$route.query.type;
    }
    this.cityOptions = cityData; //地区
    this.getAllSecondLabel();
    this.getInfo();
  },
  methods: {
    // 获取认证协议
    agreementChange() {
      this.$api.getAgreement({ configName: "bigGodAgreement" }).then((res) => {
        if (res.code == 1) {
          this.agreementText = res.data;
          this.agreementShow = true;
        }
      });
    },
    // 价格说明
    priceDescriptionChange() {
      this.$api.getAgreement({ configName: "skillRule" }).then((res) => {
        if (res.code == 1) {
          this.explainText = res.data;
          this.explainShow = true;
        }
      });
    },
    // 头像上传
    handleAvatarSuccess(res) {
      if (res.code == 1) {
        this.userInfo.avatar = res.data.url;
        let data = {
          avatar: this.userInfo.avatar, //头像
        };
        this.$api.saveUserInfo(data).then((res) => {});
      }
    },
    // 照片墙删除
    delImg(i) {
      this.$api.userImgDelete({ id: i.id }).then((res) => {
        if (res.code == 1) {
          this.getInfo();
        }
      });
    },
    // 照片墙上传
    photoWallSuccess(res) {
      if (!this.loadType) return this.$message.info("上传中");
      if (res.code == 1) {
        this.loadType = false;
        this.$api
          .userImgAdd({
            imgPath: res.data.url,
          })
          .then((res) => {
            if (res.code == 1) {
              this.getInfo();
            } else {
              this.loadType = true;
            }
          });
      }
    },
    // 修改用户资料
    amendUser() {
      let data = {
        avatar: this.userInfo.avatar, //头像
        birthday: this.userInfo.birthday, //生日
        sex: this.userInfo.sex, //性别（0-男 1-女）
        city: this.userInfo.city, //城市
        QQNumber: this.userInfo.qqNumber, //QQ号
        personalSignature: this.userInfo.personalSignature, //个人签名
        personalTagIds: this.userInfo.tagIds, //个人标签ids
        nickname: this.userInfo.nickname, //用户昵称
      };
      this.$api.saveUserInfo(data).then((res) => {
        console.log("修改用户资料", res);
        if (res.code == 1) {
          this.auditHintShow = true;
          console.log(this.auditHintShow);
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
    // 选择器父级选择
    fatherChange(i, index) {
      this.fatherkey = index;
      this.cityObj = i.city;
    },
    //确认选择
    cityChange(i) {
      this.cascaderShow = false;
      this.userInfo.city = i.cityName;
    },
    // 个人信息
    getInfo() {
      this.$api.queryUser().then((res) => {
        if (res.code == 1) {
          this.userInfo = res.data;
          if (this.userInfo.imgs) {
            this.userInfo.imgs = this.userInfo.imgs.split(",");
          } else {
            this.userInfo.imgs = [];
          }
          console.log("个人信息", this.userInfo);
        }
      });
    },
    // 更新用户信息
    saveUserInfo() {
      this.$api.saveUserInfo(this.userInfo).then((res) => {
        console.log("更新用户信息", res);
      });
    },
    // 删除聊天文件
    delVoiceChange() {
      this.skillsForm.voiceFile = "";
      this.skillsForm.voiceName = "";
      this.skillsForm.voiceLen = "";
    },
    // 上传录音文件
    soundRecordingSuccess(response, file, fileList) {
      console.log(response, file, fileList);
      if (response.code == 1) {
        this.skillsForm.voiceFile = response.data.url;
        this.skillsForm.voiceName = file.name;
      }
    },
    // 获取文件时长
    beforeAvatarUpload(file) {
      let url = URL.createObjectURL(file);
      let audioElement = new Audio(url);
      audioElement.addEventListener("loadedmetadata", (_event) => {
        let playTime = audioElement.duration;
        console.log(playTime, "视频时长");
        this.skillsForm.voiceLen = parseInt(playTime);
      });
    },
    //上传资料
    certificationSuccess(res) {
      if (res.code == 1) {
        this.skillsForm.certificationImg = res.data.url;
      }
    },
    // 技能认证
    userSkills() {
      // return console.log(this.twoSkill);
      this.skillsForm.labelId = this.twoSkill.id;
      console.log("this.skillsForm", this.skillsForm);
      if (!this.skillsForm.certificationImg)
        return this.$message.info("请上传认证图片");
      if (!this.skillsForm.voiceFile)
        return this.$message.info("请上传语音认证文件");
      if (!this.whetherRead) return this.$message.info("请阅读认证协议");
      this.$api.userSkills(this.skillsForm).then((res) => {
        console.log("认证", res);
        if (res.code == 1) {
          this.enterType = 2;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    // 接单等级数据
    getOptions() {
      ///myq
      this.skillsForm.price = "";
      this.skillsForm.priceId = "";
      this.skillsForm.levelId = "";
      this.skillsForm.levelName = "";
      this.$api.getOptions({ labelId: this.twoSkill.id }).then((res) => {
        console.log("接单等级数据", res);
        if (res.code == 1) {
          this.gradeList = res.data.levelVoList;
          this.unitPriceList = res.data.priceInfoVoList;
          this.serverList = res.data.districtVoList;
        }
      });
    },

    // 返回
    enterClose() {
      // this.$emit("closeManito");
      this.$router.back();
    },
    // 技能类型切换
    skillChange(type) {
      this.skillType = type;
      if (type == 1) {
        this.twoSkill = this.gameLabels[0];
        this.srcList = [];
        this.srcList.push(this.twoSkill.authEgImg);
      } else {
        this.twoSkill = this.amusementLabels[0];
        this.srcList = [];
        this.srcList.push(this.twoSkill.authEgImg);
      }
      this.getOptions();
    },
    // 二级标签选择
    twoSkillChange(i) {
      this.twoSkill = i;
      this.srcList = [];
      this.srcList.push(this.twoSkill.authEgImg);
      this.getOptions();
    },
    // 热门-游戏-娱乐，三个分类下所有分类
    getAllSecondLabel() {
      this.$api.getAllSecondLabel().then((res) => {
        console.log("获取分类", res);
        if (res.code == 1) {
          this.amusementLabels = res.data.amusementLabels;
          this.gameLabels = res.data.gameLabels;
          this.twoSkill = this.gameLabels[0];
          this.srcList = [];
          this.srcList.push(this.twoSkill.authEgImg);
          this.getOptions();
          // this.getCreateGameRoomOptions(this.gameLabels[0].id)
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.textBox {
  width: 500px;
  height: 400px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.price_description {
  position: absolute;
  color: #5b9e5a;
  top: 0%;
  right: -25%;
}

//选择器
.cascader_box {
  position: relative;

  .selector_cascader {
    height: 40px;
    padding: 0 0 0 10px;
    background-color: rgba(245, 245, 245, 1);
    border-radius: 4px;
    color: #000;
  }

  .cascader_icon {
    width: 6px;
    height: 10px;
    margin: 0 10px;
  }

  .cascaderItemBox {
    position: absolute;
    top: 50px;
    height: 190px;
    background-color: #f5f5f5;
    z-index: 1;
    border-radius: 4px;
    color: #000;

    .father_box {
      height: 190px;
      overflow-y: scroll;

      .father_item {
        height: 25px;
        min-width: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 0;
      }
    }
  }
}

.label_style {
  padding: 0px 20px;
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;

  background: #ffdd00;
  border-radius: 4px;
  margin-top: 12px;
}

.userData_box {
  width: 420px;
  margin: auto;

  .user_headImg {
    width: 100px;
    height: 100px;
    border-radius: 8px;
  }

  .photowall_box {
    width: 350px;
    display: flex;
    flex-wrap: wrap;
  }

  .photowall_item {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    margin: 0 10px 10px 0;
  }
}

// 旋转
.spin_longin {
  animation: spin 2s infinite;
}

@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}

.label_img {
  width: 40px;
  height: 40px;
  margin-bottom: 6px;
  border-radius: 8px;
}

.when_icon {
  width: 16px;
  height: 10px;
  margin: 0 56px;
}

.step {
  height: 122px;

  .step_item {
    font-size: 12px;
  }

  .step_icon {
    width: 16px;
    height: 16px;
    margin-bottom: 8px;
    background: #77d769;
    border-radius: 8px;
  }
}

.skill_tab {
  margin-right: 10px;
  padding: 7px 16px 5px 16px;
  background: rgba(255, 221, 0, 1);
  border-radius: 4px 4px 0px 0px;
  color: #000000;
}

.skill_tabPitch {
  background: rgba($color: #363659, $alpha: 0.4);
}

.skill_box {
  width: 820px;
  height: 110px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  background: rgba(255, 221, 0, 1);
  border-radius: 0px 4px 4px 4px;
  box-sizing: border-box;
  overflow-x: scroll;

  .skill_item {
    width: 90px;
    height: 90px;
    color: #8586a9;
    text-align: center;
  }

  .itemText {
    width: 90px;
  }

  .skill_pitch {
    width: 90px;
    height: 90px;
    color: #fff;
    background: linear-gradient(360deg, #ffa700 0%, #ff6200 100%);
    border-radius: 8px;
  }
}

.skill_box::-webkit-scrollbar {
  width: 0px;
  height: 8px;
}

.attestation_text {
  width: 820px;
  height: 60px;
  padding: 10px;
  color: #6a6a94;
  margin: 13px 0 20px 0;
  background: rgba(245, 245, 245, 1);
  border-radius: 4px;
  box-sizing: border-box;
}

.img_uploading {
  width: 268px;
  height: 151px;
  margin: 10px 18px 20px 0;
}

.file_uploading {
  width: 114px;
  height: 36px;
  color: #000;
  margin-right: 10px;
  background: rgba(255, 221, 0, 1);
  border-radius: 4px;
}

.audio_file {
  width: 193px;
  height: 36px;
  padding: 8px 10px;
  font-size: 14px;
  justify-content: space-between;
  background: #f5f5f5;
  border-radius: 4px;
  box-sizing: border-box;
}

.form_btn {
  width: 320px;
  height: 40px;
  color: #333;
  font-size: 14px;

  background: #ffdd00;
  border-radius: 4px;
}

.enter_page {
  width: 100%;
  height: 1080px;
  color: #fff;
  font-size: 14px;
  background: rgba($color: #000000, $alpha: 0.8);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.enter_box {
  background: #fff;
  border-radius: 4px 4px 4px 4px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

.enter_applyFor {
  padding: 30px 20px;
}

.enter_applyForBtn {
  width: 176px;
  height: 40px;
  margin-top: 22px;
  background: rgba(255, 221, 0, 1);
  border-radius: 8px 8px 8px 8px;
  color: #333 !important;
}

.manito_formBox {
  width: 860px;
  max-height: 800px;
  color: #fff;
  padding: 0 20px;
  margin-top: 20px;
  overflow-y: scroll;
}

.form_box {
  width: 400px;
  margin-top: 20px;
}

.upload_certification /deep/.el-upload--picture-card {
  width: 300px;
  background-color: transparent;
  border: 0;
}

.upload_soundRecording /deep/.el-upload--picture-card {
  width: 150px;
  height: 30px;
  background-color: transparent;
  border: 0;
}

/deep/.el-form-item__label {
  color: #000;
}

/deep/.el-input__inner {
  color: #333;
  background-color: rgba(245, 245, 245, 1) !important;
  border: 0;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
  border-color: RGBA(255, 221, 0, 1);
  background: RGBA(255, 221, 0, 1);
}

/deep/.el-textarea__inner {
  border: 0;
  color: #333;
  background-color: rgba(245, 245, 245, 1);
}

/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #333;
}

/deep/.el-input__inner::placeholder {
  color: #6a6a94 !important;
}
</style>
