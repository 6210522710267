<template>
  <div class="userpage">
    <!-- 身份验证 -->
    <div class="popup_data" v-if="identityType == 0">
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14" >身份验证</div>
        <img src="../../static/quit.png" alt="" @click="skipHome()">
      </div>
      <!-- 头部end -->
      <!-- 身份验证 -->
      <div class="identity flex-dir" v-if="Step == 0">
        <div class="font-20">请使用当前登录的昵称为 <span style="color:#77D769">“叶梓木”</span> 的微信号授权验证身份</div>
        <div class="font-14" style="color:#8E92B1;margin-top:20px">
          ·为了处理您的账号申诉、解除冻结等申请和增加账号安全性，根据相关法律法规的规定和有权机关的要求，
          我们需要收集您微信/QQ授权登录的账号昵称、头像信息。上述信息为您的个人敏感信息，若无法提供上诉信息，可能导致您无法完成验证。该等信息未经您的明示授权，不会作其他目的。
          <br/>
          ·本流程用户信息收集开发者名称：YC。</div>
          <div class="identity_input">
            <el-input placeholder="当前登录的微信账号" v-model="passwordForm"></el-input>
            <el-input placeholder="手机绑定" v-model="passwordForm" style="margin-top:15px"></el-input>
          </div>
          <div class="identity_btnBox flex-aling">
            <div @click="identityType = 1">身份验证遇到问题？</div>
            <div class="identity_btn flex-content" @click="nextStepChange()">我同意，微信授权验证身份</div>
          </div>
      </div>
      <!-- 微信验证 -->
      <div class="identity flex-dir" v-if="Step == 1">
        <div class="font-20">请使用当前登录的昵称为 <span style="color:#77D769">“叶梓木”</span> 的微信号授权验证身份</div>
        <img src="../../static/66.png" alt="" class="identity_img"  @click="nextStepChange()">
        <div class="flex-content">
          <img src="../../static/100.png" alt="" style="margin-right:10px">
          微信扫码登录
        </div>
      </div>
      <!-- 验证不通过 -->
      <div class="identity flex-dir" v-if="Step == 3" >
        <img src="../../static/78.png" alt="">
        <div class="padding_r10">绑定手机号验证不通过</div>
        <div class="font-12 flex-dir" style="color:#8E92B1;margin:10px 0 35px 0 ">
          <p>当前绑定的手机号与原绑定手机号不一致。</p>
          <p>请使用当前登录账号的原绑定手机号 132****1561 验证身份。</p>
        </div>
        <div class="flex">
          <div class="reLogin flex-content" @click="skipLogin()">登录账号不对？重新登录</div>
          <div class="changePhone flex-content" @click="skipPhone()">更换手机号，重新授权</div>
        </div>
      </div>
      <!-- 验证通过 -->
      <div class="identity flex-dir" v-if="Step == 2" >
        <img src="../../static/69.png" alt="">
        <div class="padding_r10">身份验证通过</div>
        <div class="font-12 flex-dir" style="color:#8E92B1;margin:10px 0 35px 0 ">
          <p>已经通过验证，您的账号已经可以在本设备正常的登录</p>
        </div>
        <div class="flex-aling verifyUser_box">
          <div class="flex">
            <img src="../../static/to2.png" alt="" class="verifyUser_head">
            <div class="flex-between">
              <p>蔡菜菜</p>
              <p class="font-12">ID:88888888</p>
            </div>
          </div>
          <div class="flex-dir flex-aling">
            <div><img src="../../static/100.png" alt="" class="verifyUser_icon">微信登录</div>
            <div class="font-10">2022-05-24</div>
          </div>
        </div>
        <div class="identity_input" style="margin-top:32px">
          <el-input placeholder="当前登录的微信账号" v-model="passwordForm"></el-input>
          <el-input placeholder="手机绑定" v-model="passwordForm" style="margin-top:15px"></el-input>
        </div>

        <div class="flex" style="margin-top:31px">
          <div class="reLogin flex-content" @click="skipLogin()">切换登录方式？重新登录</div>
          <div class="changePhone flex-content" @click="skipHome()">立即进入</div>
        </div>
      </div>
    </div>
    <!-- 身份验证常见问题 -->
    <div class="popup_data" v-if="identityType == 1">
      <!-- 头部start -->
      <div class="titleIcon"></div>
      <div class="title_content">
        <div class="font-14" >身份验证常见问题</div>
        <img src="../../static/quit.png" alt="" @click="identityType = 0">
      </div>
      <!-- 头部end -->
      <div class="FAQ">
        <p>微信验证不通过</p>
        <p>当前登录的微信号和授权的微信号不是一个账号。</p>
        <img src="../../static/120.png" alt="" style="margin:25px 0 31px 0">
        <p>微信验证不通过</p>
        <p>当前登录的微信号和授权的微信号不是一个账号。</p>
      </div>
    </div>
  </div>
  </template>
  <script>
  export default {
  name: '',
  components: {},
  data(){
  return {
    identityType:0,
    Step:0,
    labelList:['可爱','御姐','霸道','热情','敏感','开朗']
  }
  },
  methods: {
    nextStepChange(){
      this.Step++
    },
    // 返回首页
    skipHome(){
        this.$router.push('/home')
      },
    // 返回登录页
    skipLogin(){
        this.$router.push('/login')
      },
      // 返回绑定手机号
    skipPhone(){
      this.$router.go(0)
      },
  },
  };
  </script>
  <style scoped lang='scss'>
  .userpage{
    width: 1920px;
    height: 1080px;
    color: #FFFFFF;
    background: #000000;
    position: absolute;
    top: 0;
    left: 0;
  }
  .popup_data{
    background: #1D223B;
    border-radius: 4px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .identity{
    font-size: 14px;
    margin: 30px 60px;
  }
  .identity_input{
    width: 360px;
    margin: auto;
    margin-top: 64px;
  }
  .identity_btnBox{
    width: 360px;
    margin: auto;
    margin-top: 53px;
    font-size: 14px;
    .identity_btn{
      width: 200px;
      height: 40px;
      margin-left: 27px;
      background: #00CB3C;
      border-radius: 20px;
    }
  }
  .identity_img{
    width: 240px;
    height: 240px;
    margin: 60px 0 46px 0;
  }
  .reLogin{
    width: 176px;
    height: 40px;
    margin-right: 20px;
    background: #363659;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
  .changePhone{
    width: 176px;
    height: 40px;
    background: linear-gradient(135deg, #7C4FF0 0%, #976EFF 100%);
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
  }
  .verifyUser_box{
    width: 360px;
    height: 80px;
    font-size: 14px;
    padding: 16px;
    justify-content: space-between;
    background: #363659;
    border-radius: 4px;
    box-sizing: border-box;
    .verifyUser_head{
      width: 48px;
      height: 48px;
      margin-right: 10px;
    }
    .verifyUser_icon{
      width: 16px;
      height: 16px;
      margin-right: 4px;
      border-radius: 5px;
    }
  }
  .FAQ{
    font-size: 16px;
    line-height: 30px;
    margin: 40px 114px 62px 114px;
    box-sizing: border-box;
  }
  /deep/.el-form-item__label{
    color: #D3D4DC;
  }
  /deep/.el-input__inner{
    border: 0;
    background-color: #363659 !important;
  }
  /deep/.el-radio__input.is-checked .el-radio__inner{
      border-color: #7e53f0;
      background: #7e53f0;
  }
  /deep/.el-textarea__inner{
    border:0;
    background-color: #363659;
  }
  /deep/.el-radio__input.is-checked+.el-radio__label{
    color: #fff;
  }
  /deep/.el-input__inner::placeholder {
    color:  #6A6A94 !important;
  }
  </style>